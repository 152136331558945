import { useCallback, useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { useRouter } from 'next/router'
import {
  CampaignType,
  ValidateCoupon,
  ValidateCouponResponse
} from '../../../types/campaign.types'
import { config, getClientHeaders } from '../../../config'
import { getGlobalCampaingData, getIndividualCampaingData } from './utils'

interface UseFetchCampaignProps {
  isGlobalCampaign: boolean
  activeCampaign: CampaignType | null
  onValidateCoupon?(data: ValidateCoupon): Promise<ValidateCouponResponse>
  onClearCoupon: () => void
  couponCode: string | undefined
  planNames?: string[]
  loaded: boolean
}

interface UseFetchCampaignParams {
  userToken: null | string
}

export const useFetchCampaign = ({
  userToken
}: UseFetchCampaignParams): UseFetchCampaignProps => {
  const { query } = useRouter()
  const clientHeaders = getClientHeaders()
  const [loaded, setLoaded] = useState(false)
  const [activeCampaign, setActiveCampaign] = useState<CampaignType | null>(
    null
  )
  const [isGlobalCampaign, setIsGlobalCampaign] = useState(false)
  const [couponCode, setCouponCode] = useState<string | undefined>(undefined)

  const apiUrl = config.graphql.endpoint

  useEffectOnce(() => {
    setCouponCode(query.coupon as string | undefined)
  })

  const onVerifyActiveGlobalCampaign = useCallback(
    async (_userToken: string) => {
      if (_userToken) {
        const coupon = (couponCode || query.coupon) as string
        if (coupon) {
          const result = await getIndividualCampaingData(
            apiUrl,
            _userToken,
            coupon,
            clientHeaders
          )

          setIsGlobalCampaign(false)
          setCouponCode(coupon)
          if (result?.campaign) {
            setActiveCampaign(result?.campaign)
          }
        } else {
          const result = await getGlobalCampaingData(
            apiUrl,
            _userToken,
            clientHeaders
          )
          if (result?.campaign && result?.flags?.offerModeOnWeb) {
            setIsGlobalCampaign(true)
            setActiveCampaign(result?.campaign)
            setCouponCode(result?.campaign?.couponCode)
          }
        }
      }
    },
    [couponCode, query.coupon, apiUrl, clientHeaders]
  )

  const onValidateCoupon = useCallback(
    async ({
      coupon,
      cycle,
      plan,
      _userToken
    }: ValidateCoupon): Promise<ValidateCouponResponse> => {
      if (!_userToken || !coupon) {
        return {
          error: 'INVALID_COUPON'
        }
      }

      const result = await getIndividualCampaingData(
        apiUrl,
        userToken,
        coupon,
        clientHeaders
      )

      if (!result?.campaign) {
        return {
          error: 'INVALID_COUPON'
        }
      }

      if (
        !result?.campaign?.eligiblePlans?.includes(cycle) ||
        !result?.campaign?.planNames?.includes(plan)
      ) {
        return {
          error: 'INVALID_COUPON_PLAN'
        }
      }

      if (result?.campaign) {
        setCouponCode(result?.campaign?.couponCode)
        setActiveCampaign(result?.campaign)
      }

      return {
        campaign: result?.campaign
      }
    },
    [apiUrl, userToken, clientHeaders]
  )

  const onClearCoupon = useCallback(() => {
    setActiveCampaign(null)
    setCouponCode(undefined)
  }, [])

  useEffect(() => {
    if (loaded || !userToken) {
      return
    }

    setLoaded(true)
    onVerifyActiveGlobalCampaign(userToken)
  }, [loaded, onVerifyActiveGlobalCampaign, userToken])

  return {
    activeCampaign,
    isGlobalCampaign,
    onValidateCoupon,
    onClearCoupon,
    couponCode,
    planNames: activeCampaign?.planNames,
    loaded
  }
}
