import React from 'react'
import { IconProps } from './icons.types'

export const NoEye: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ic-no-eye">
      <path
        id="Stroke"
        d="M11.7546 8.21886C11.3033 7.77412 10.6837 7.49967 9.99998 7.49967C8.61927 7.49967 7.49998 8.61896 7.49998 9.99967C7.49998 10.6025 7.71331 11.1554 8.06859 11.5872M14.8447 4.97224C13.5097 4.0295 11.8855 3.33301 9.99998 3.33301C4.16665 3.33301 0.833313 9.99967 0.833313 9.99967C0.833313 9.99967 2.29551 12.9241 4.97779 14.8991M7.80579 16.3435C8.4916 16.5483 9.22365 16.6663 9.99998 16.6663C15.8333 16.6663 19.1666 9.99967 19.1666 9.99967C19.1666 9.99967 18.4818 8.62998 17.1897 7.13227M16.6666 3.33301L3.33331 16.6663"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)
