import React from 'react'
import { IconProps } from './icons.types'

export const InstrumentDrums: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.75 12.8236C13.75 12.4094 13.4142 12.0736 13 12.0736C12.5858 12.0736 12.25 12.4094 12.25 12.8236H13.75ZM12.25 12.8236V21.7648H13.75V12.8236H12.25Z"
      fill="currentColor"
    />
    <path
      d="M21.2795 11.8824C21.2795 11.4682 20.9437 11.1324 20.5295 11.1324C20.1153 11.1324 19.7795 11.4682 19.7795 11.8824H21.2795ZM19.7795 11.8824V20.8236H21.2795V11.8824H19.7795Z"
      fill="currentColor"
    />
    <path
      d="M6.22055 11.8824C6.22055 11.4682 5.88476 11.1324 5.47055 11.1324C5.05634 11.1324 4.72055 11.4682 4.72055 11.8824H6.22055ZM4.72055 11.8824V20.8236H6.22055V11.8824H4.72055Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5884 11.7836C24.0448 11.3748 24.2943 10.9324 24.2943 10.4706C24.2943 10.2294 24.2262 9.99339 24.0961 9.76474H24.2943C24.6841 9.76474 25.0002 10.0808 25.0002 10.4706V18.0001C25.0002 18.8289 24.4956 19.5061 23.8437 20.0199C23.1837 20.54 22.2725 20.9775 21.2095 21.3318C19.0761 22.0429 16.1737 22.4707 13.0001 22.4707C9.82656 22.4707 6.92415 22.0429 4.79071 21.3318C3.72768 20.9775 2.8165 20.54 2.15657 20.0199C1.50461 19.5061 1.00006 18.8289 1.00006 18.0001V10.4706C1.00006 10.0808 1.3161 9.76474 1.70595 9.76474H1.90415C1.77403 9.99339 1.70595 10.2294 1.70595 10.4706C1.70595 10.9324 1.9554 11.3748 2.41183 11.7836V18.0001C2.41183 18.2109 2.53936 18.5241 3.03042 18.9111C3.51353 19.2918 4.25634 19.6655 5.23715 19.9925C7.1914 20.6439 9.93607 21.0589 13.0001 21.0589C16.0642 21.0589 18.8088 20.6439 20.7631 19.9925C21.7439 19.6655 22.4867 19.2918 22.9698 18.9111C23.4609 18.5241 23.5884 18.2109 23.5884 18.0001V11.7836Z"
      fill="currentColor"
    />
    <ellipse
      cx="13.0001"
      cy="10.4706"
      rx="11.2942"
      ry="3.76472"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M24.2941 2L9.70584 10.4706"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
