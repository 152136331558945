import React from 'react'
import { IconProps } from './icons.types'

export const AddLyrics: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 3.20703H19C20.6569 3.20703 22 4.55018 22 6.20703V16.207C22 17.8639 20.6569 19.207 19 19.207H8.48529C6.89399 19.207 5.36787 19.8392 4.24265 20.9644L3.70712 21.4999C3.07715 22.1299 2.00001 21.6837 2.00001 20.7928V11.207M1 5.20703H4M4 5.20703H7M4 5.20703V8.20703M4 5.20703L4 2.20703M8.5166 13.4726C10.7116 12.874 11.0667 10.2554 10.0667 9.25544C9.06668 8.25544 7.88806 9.9159 8.96554 10.4547C9.57387 10.7588 10.0667 10.2052 9.88832 9.86402M13.4301 13.4726C15.6251 12.874 15.9802 10.2554 14.9802 9.25544C13.9802 8.25544 12.8016 9.9159 13.8791 10.4547C14.4874 10.7588 14.9802 10.2052 14.8018 9.86402"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
