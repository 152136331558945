import { useCallback, useMemo } from 'react'
import { useFetchCampaign } from './use-fetch-campaign'
import { UseCampaignHook } from '../../../types/campaign.types'
import { getLocale } from '../../../localization'

type UseCampaignParams = {
  userToken: null | string
}

interface HasLanguage {
  lang: string
}

const findByLanguage = (
  options: HasLanguage[],
  language: string
): HasLanguage => {
  const result = options?.find(
    ({ lang }) => lang?.replace('-', '_') === language
  )
  return result || options?.[0]
}

export const useCampaign = ({
  userToken
}: UseCampaignParams): UseCampaignHook => {
  const {
    activeCampaign,
    onValidateCoupon,
    isGlobalCampaign,
    couponCode,
    onClearCoupon,
    planNames,
    loaded
  } = useFetchCampaign({ userToken })

  const endData = useMemo(() => {
    return activeCampaign?.type === 'DAYONEDISCOUNT'
      ? activeCampaign?.endAt
      : activeCampaign?.metadata?.endDate
  }, [activeCampaign])

  const promoEndAtFormated = useMemo(() => {
    if (!endData) {
      return null
    }

    const date = new Date(endData)
    return `${date.toLocaleString('default', {
      month: 'long'
    })} ${date.getDate()}, ${date.getFullYear()}`
  }, [endData])

  const locale = useMemo(
    () => getLocale(global?.window?.navigator?.language),
    []
  )

  const dataCampaign = useMemo(() => {
    if (!activeCampaign || !activeCampaign?.metadata) {
      return undefined
    }

    const {
      web,
      mainBannerWeb,
      profileBannerWeb,
      floatingBannerWeb,
      mainBannerImages,
      countdownBannerImage,
      paywallsText
    } = activeCampaign?.metadata

    return {
      id: activeCampaign?.id,
      endDate: endData,
      pricingBanner: {
        monthly: activeCampaign?.eligiblePlans?.includes('monthly')
          ? findByLanguage(web?.monthly, locale)
          : null,
        yearly: activeCampaign?.eligiblePlans?.includes('yearly')
          ? findByLanguage(web?.yearly, locale)
          : null
      },
      discountPercentage: activeCampaign?.discountPercentage,
      mainBannerWeb: findByLanguage(mainBannerWeb, locale),
      floatingBannerWeb: findByLanguage(floatingBannerWeb, locale),
      profileBannerWeb: findByLanguage(profileBannerWeb, locale),
      paywallsText: findByLanguage(paywallsText, locale),
      mainBannerImages,
      countdownBannerImage,
      name: activeCampaign?.name,
      type: activeCampaign?.type,
      hasCampaignMonthly: !!activeCampaign?.eligiblePlans?.includes('monthly'),
      hasCampaignYearly: !!activeCampaign?.eligiblePlans?.includes('yearly')
    }
  }, [activeCampaign, endData, locale])

  const hasCampaignForPlanCycle = useCallback(
    (plan: 'premium' | 'pro', cycle: 'monthly' | 'yearly') => {
      return (
        !!activeCampaign?.eligiblePlans?.includes(cycle) &&
        !!activeCampaign?.planNames?.includes(plan)
      )
    },
    [activeCampaign]
  )

  const hasCampaign = useCallback(
    (plan: 'premium' | 'pro', cycle: 'monthly' | 'yearly') => {
      return !!(isGlobalCampaign && hasCampaignForPlanCycle?.(plan, cycle))
    },
    [hasCampaignForPlanCycle, isGlobalCampaign]
  )

  const hasValidCouponForPlanCycle = useCallback(
    (plan: 'premium' | 'pro', cycle: 'monthly' | 'yearly') => {
      return (
        activeCampaign?.eligiblePlans?.includes(cycle) &&
        activeCampaign?.planNames?.includes(plan)
      )
    },
    [activeCampaign]
  )

  return {
    ...dataCampaign,
    onValidateCoupon,
    hasCampaignForPlanCycle,
    hasCampaign,
    couponCode,
    onClearCoupon,
    hasValidCouponForPlanCycle,
    isGlobalCampaign,
    promoEndAtFormated,
    eligiblePlans: activeCampaign?.eligiblePlans,
    metadata: activeCampaign?.metadata,
    discountPercentage: activeCampaign?.discountPercentage,
    planNames,
    loaded
  }
}
