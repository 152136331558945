import { Config } from './types'

export const production: Config = {
  env: 'production',
  sentry: {
    dsn: 'https://1862a03d409441369cb8b4316648b63e@o418792.ingest.sentry.io/5995756'
  },
  getAudioMix: {
    endpoint: 'https://service-mixer-6fvc7ord4a-wl.a.run.app/v1'
  },
  urlDownload: {
    endpoint: 'https://url-download.moises.ai'
  },
  graphql: {
    endpoint: 'https://api.moises.ai/graphql'
  },
  api: {
    endpoint: 'https://api.moises.ai',
    balance: {
      d1: 'https://d1.moises.ai',
      d2: 'https://d2.moises.ai',
      d3: 'https://d3.moises.ai'
    }
  },
  serviceHighRes: {
    endpoint: 'https://high-res.moises.ai'
  },
  transcode: {
    endpoint: 'https://service-transcode-6fvc7ord4a-wl.a.run.app'
  },
  waves: {
    endpoint: 'https://waves-generator.moises.ai/waves'
  },
  serviceBillingApi: {
    endpoint: 'https://billing.moises.ai'
  },
  firebase: {
    recaptchaToken: '6LcYezglAAAAACOiXOf8mCU2uYxvi6fQtz6M4X5U',
    vapidKey:
      'BAYj-jIHz9wCfj964atRUu0sc-pZmsVpPk-tmpSFxYn9LHgpnsl86x8oVW0mjA8Hi80vSPXoH9NZzBYdfxUqcTM',
    auth: {
      apiKey: 'AIzaSyDWcFRZcUnN5EPNNA7jrcuS3HlIvMqtuCs',
      authDomain: 'app.moises.ai',
      databaseURL: 'https://spleeter.firebaseio.com',
      projectId: 'spleeter',
      storageBucket: 'spleeter.appspot.com',
      messagingSenderId: '731360694588',
      appId: '1:731360694588:web:4da0f700a5193a834ace39',
      measurementId: 'G-VCCHPVZC99'
    }
  },
  stripe: {
    apiKey: 'pk_live_V8LD1ZGLLGFjoryvB60ftb5X00Dkg8tkQj'
  },
  paypal: {
    clientId:
      'AUChJ6ogk_GxSl5advDUTX0OqAVQ6IcMeCJo3qcfmXODok1Pi-peOqCcejZwwP-d3aOlDzoU9diB1eV4'
  },
  composer: {
    endpoint: 'https://composer.moises.ai'
  }
}
