import { gql } from 'graphql-request'

export const getUserQuery = gql`
  query User {
    user {
      featureFlags
      id
      name
      email
      name
      email
      lastSignInAt
      createdAt
      emailVerified
      shouldIdentifyOnCIO
      claims
      profilePictureUrl
      groups {
        id
        plan
        isOwner
        isPending
        isExpired
        owner {
          name
          email
          avatar
        }
        members {
          name
          email
          avatar
          isPending
          inviteLink
        }
        currentSeats
        nextCycleSeats
        occupiedSeats
        isFull
        inviteLink
        inviteId
      }
      subscription {
        isPro
        isPremium
        plan
        availableCredits
        proTrialExpiresAt
        subscriptionType
        activeStripeCurrency
        currentMonthlyUsage
        mustCancelRevenuecatPremium
        hasUsedStripeTrial
        groupPlanFreeTrial
        eligibleGroupPlanTrial
        details {
          pending
          autoRenew
          providerName
          planCycle
          expireDate
          providerGateway
        }
      }
      subscriptions {
        isPro
        isPremium
        plan
        best
        type
        subscriptionType
        availableCredits
        proTrialExpiresAt
        activeStripeCurrency
        currentMonthlyUsage
        mustCancelRevenuecatPremium
        hasUsedStripeTrial
        details {
          pending
          autoRenew
          providerName
          planCycle
          expireDate
          providerGateway
        }
      }
      preferences {
        communication {
          activity {
            push
            email
          }
          updates {
            push
            email
          }
          collaboration {
            push
            email
          }
        }
        defaultSeparation {
          type
          stems
        }
        chordSkillLevel
        demoPlaylist {
          optOut
          hideFromLibrary
        }
      }
      goals
      instruments {
        skillLevel
        instrumentId
      }
      actionNeeded {
        hasTermsToAccept
      }
    }
  }
`
