import React from 'react'
import { IconProps } from './icons.types'

export const SkipForward: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9416 10.5819V5.64993C10.9416 5.18805 11.4416 4.89937 11.8416 5.13031L21.2889 10.5847V5.73877C21.2889 5.4074 21.5575 5.13877 21.8889 5.13877H22.8C23.1314 5.13877 23.4 5.4074 23.4 5.73877V18.2613C23.4 18.5927 23.1314 18.8613 22.8 18.8613H21.8889C21.5575 18.8613 21.2889 18.5927 21.2889 18.2613V13.4148L11.8416 18.8692C11.4416 19.1001 10.9416 18.8115 10.9416 18.3496V13.4176L1.49924 18.8692C1.09924 19.1001 0.599243 18.8115 0.599243 18.3496L0.599244 5.64992C0.599244 5.18804 1.09924 4.89937 1.49924 5.13031L10.9416 10.5819Z"
      fill="currentColor"
    />
  </svg>
)
