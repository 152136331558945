import React from 'react'
import { IconProps } from './icons.types'

export const MusicList: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.45825 2.70898H9.37492M1.45825 6.87565H9.37492M1.45825 11.0423H6.04159M12.7824 13.4641V16.6C12.7824 17.3565 12.2729 18.0119 11.5415 18.2052L9.7507 18.6784C8.69349 18.9578 7.6582 18.1606 7.6582 17.0671V16.1017C7.6582 15.3453 8.16767 14.6837 8.89904 14.4904L12.7824 13.4641ZM12.7824 13.4641C12.7824 9.3297 12.7824 7.01169 12.7824 2.87726C12.7824 5.83394 17.4999 5.83393 17.4999 10.8339"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)
