import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import classnames from 'classnames'
import Image from 'next/image'
import React, { ReactElement } from 'react'
import styles from './empty.module.scss'

interface EmptyProps {
  className?: string
  onCreatePlaylist(): void
}

export const Empty: React.FC<EmptyProps> = ({
  className,
  onCreatePlaylist
}): ReactElement => {
  const { i18n } = useLingui()

  return (
    <button
      type="button"
      onClick={onCreatePlaylist}
      className={classnames(className, styles.container)}
    >
      <div className={styles.image}>
        <Image
          alt=""
          layout="fill"
          src="//storage.googleapis.com/scala-web/assets/images/playlist.svg"
        />
      </div>
      <span>{i18n._(t`library.playlists.description`)}</span>
    </button>
  )
}
