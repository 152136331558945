import React from 'react'
import { IconProps } from './icons.types'

export const KeyBackspace: React.FC<IconProps> = ({
  width = 16,
  height = 12
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 4L9 6M9 6L11 8M9 6L11 4M9 6L7 8M4.62143 1H15V11H4.62143L1 6L4.62143 1Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
