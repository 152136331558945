import React from 'react'
import { IconProps } from './icons.types'

export const SocialGoogle: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2184 10.4276V14.2005H18.4568C18.2035 15.82 16.5702 18.9441 12.2184 18.9441C8.4633 18.9441 5.39968 15.8338 5.39968 12.0004C5.39968 8.16743 8.4633 5.05709 12.2184 5.05709C14.3539 5.05709 15.785 5.96855 16.6019 6.75375L19.5884 3.8789C17.6721 2.0836 15.1886 1 12.22 1C6.13981 1 1.21997 5.91984 1.21997 12C1.21997 18.0802 6.13981 23 12.22 23C18.5669 23 22.7815 18.5357 22.7815 12.253C22.7815 11.5311 22.7044 10.9814 22.6094 10.4313L12.22 10.4268L12.2184 10.4276V10.4276Z"
      fill="white"
    />
  </svg>
)
