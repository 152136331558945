import React from 'react'
import { IconProps } from './icons.types'

export const PlayFill: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7586 11.5824C20.0805 11.768 20.0805 12.232 19.7586 12.4176L6.72414 19.9347C6.4023 20.1203 6 19.8883 6 19.5171L6 4.48294C6 4.11173 6.4023 3.87972 6.72414 4.06532L19.7586 11.5824Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
)
