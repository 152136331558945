import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import * as Sentry from '@sentry/nextjs'
import { useCallback, useContext, useState } from 'react'
import axios from 'axios'
import { ContextShell } from '../../../modules/context'
import { config } from '../../../config'

type UseGroupPlanTrial = {
  loading: boolean
  onAcceptTrial(): Promise<boolean>
}

export const useGroupPlanTrial = (): UseGroupPlanTrial => {
  const { i18n } = useLingui()
  const {
    user,
    userToken,
    silentRetry,
    toast: { add: addToast }
  } = useContext(ContextShell)
  const [loading, setLoading] = useState(false)

  const onAcceptTrial = useCallback(async () => {
    setLoading(true)
    try {
      await axios.post(
        `${config.serviceBillingApi.endpoint}/v1/subscription/opt-in-group-plan-trial`,
        {},
        {
          headers: {
            Authorization: userToken
          }
        }
      )

      silentRetry()
      setTimeout(() => {
        window.location.href = '/group/manage/'
      }, 300)
    } catch (error) {
      Sentry.captureMessage('Group Plan Trial error', {
        level: 'error',
        extra: {
          userId: user?.id,
          error
        }
      })
      addToast({
        icon: null,
        type: 'error',
        closable: true,
        description: i18n._(t`message_error_improve`)
      })
      setLoading(false)
      return false
    }
    return true
  }, [i18n, userToken, user?.id, addToast, silentRetry])

  return {
    loading,
    onAcceptTrial
  }
}
