import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { ContextShell } from '../../../modules/context'
import { usePersistentDismissible } from '../../misc/use-persistent-dismissable'
import { Icon } from '../../../components/icon'

const ALERT_REMAINING_DAYS = 7
const ALERT_LAST_MESSAGE_REMAINING_DAYS = 1

export const useGroupPlanAlerts = (): void => {
  const { i18n } = useLingui()
  const { pathname } = useRouter()
  const {
    user,
    event: { sendEvent },
    toast: { add: addToast }
  } = useContext(ContextShell)
  const [showed, setShowed] = useState(false)

  const enabled = useMemo(
    () =>
      !!(
        user?.subscription?.groupPlanFreeTrial &&
        user.subscription.subscriptionType === 'group-owner'
      ),
    [user]
  )

  const { onDismiss, getStorage } = usePersistentDismissible({
    id: `group-plan-alerts-${user?.id}`,
    enabled
  })

  const onDismissAlert = useCallback(
    (_: string, triggerIsConfirm: boolean) => {
      onDismiss()
      if (!triggerIsConfirm) {
        sendEvent({
          name: 'alert_banner_clicked',
          category: 'engagement',
          customAttributes: {
            action: 'dismissed',
            click_source: pathname
          }
        })
      }
    },
    [onDismiss, sendEvent, pathname]
  )

  const onGoManageLicenses = useCallback(() => {
    onDismiss()
    sendEvent({
      name: 'alert_banner_clicked',
      category: 'engagement',
      customAttributes: {
        action: 'clicked',
        click_source: pathname
      }
    })
    setTimeout(() => {
      window.location.href = '/group/licenses/'
    }, 300)
  }, [sendEvent, onDismiss, pathname])

  const onAddToast = useCallback(
    (title: string, description: string) => {
      addToast({
        title,
        description,
        fixed: true,
        closable: true,
        type: 'default',
        icon: <Icon name="notification" />,
        buttonText: i18n._(t`group_plan_protip_cta`),
        onConfirm: onGoManageLicenses,
        onDismiss: onDismissAlert
      })
    },
    [i18n, addToast, onGoManageLicenses, onDismissAlert]
  )

  const onVerifyRemainingDays = useCallback(
    (remainingDays: number) => {
      const storage = getStorage()

      /** if showed alert before, shows new alert only last day */
      if (storage && remainingDays !== ALERT_LAST_MESSAGE_REMAINING_DAYS) {
        return
      }

      /** show alert */
      setShowed(true)
      let title
      let description

      if (remainingDays <= ALERT_LAST_MESSAGE_REMAINING_DAYS) {
        title = i18n._(t`group_second_alert_title`)
        description = i18n._(t`group_second_alert_description`)
      } else {
        title = i18n._(t`group_plan_first_alert_title`)
        description = i18n
          ._(t`group_first_alert_description_plural`)
          .replaceAll('**7**', `${remainingDays}`)
      }

      onAddToast(title, description)
    },
    [i18n, onAddToast, getStorage]
  )

  useEffect(() => {
    if (!enabled || showed) {
      return
    }

    const today = new Date().getTime()
    const expireDate = new Date(
      user?.subscription?.details.expireDate || ''
    ).getTime()

    /** if expired, dismiss alert */
    if (today > expireDate) {
      return
    }

    const diff = Math.abs(today - expireDate)
    const remainingDays = Math.ceil(diff / (1000 * 60 * 60 * 24))

    if (remainingDays <= ALERT_REMAINING_DAYS) {
      onVerifyRemainingDays(remainingDays)
    }
  }, [enabled, showed, user?.subscription, onDismiss, onVerifyRemainingDays])
}
