import classNames from 'classnames'
import { Icon } from '../../../components/icon'
import styles from './list-item.module.scss'

export const ListItem: React.FC<{
  title: string
  description: string
  active?: boolean
}> = ({ title, description, active }) => (
  <li className={styles.item}>
    <span
      className={classNames(styles.round, {
        [styles.active]: active
      })}
    >
      <p className={styles.title}>{title}</p>
      {active && <Icon name="check" className={styles.icon} />}
    </span>

    <p className={styles.description}>{description}</p>
  </li>
)
