import { gql } from 'graphql-request'

interface UserCli {
  acceptTerms(): Promise<any>
  deleteUser(): Promise<any>
  updateUserDefaultSeparation(data: {
    key?: string
    stems?: string[]
  }): Promise<boolean>
  updateUserGoals(data: { goals: string[] }): Promise<boolean>
  updateUserSkills(data: { instruments: any[] }): Promise<boolean>
  updateUserComunication(data: {
    updates: { email: boolean; push: boolean }
    activity: { email: boolean; push: boolean }
    collaboration: { email: boolean; push: boolean }
  }): Promise<boolean>
  updateDemoPlaylistPreferences(data: {
    optOut?: string[]
    hideFromLibrary?: boolean
  }): Promise<boolean>
  experimentConversion(data: {
    metricName: string
    featureFlag?: string
  }): Promise<any>
  getFlags(data: { flags: string[] }): Promise<any>
  markMessageAs(data: { messageIds: string[]; read: boolean }): Promise<any>
  signOutTempComposerJwt(): Promise<any>
}

const User = (graphQL: any): UserCli => {
  const deleteUser = async (): Promise<any> => {
    const query = gql`
      mutation DeleteUser($reason: String!, $description: String) {
        deleteUser(reason: $reason, description: $description)
      }
    `

    const result = await graphQL({
      query,
      variables: { reason: 'requested from web', description: '' }
    })

    return result.deleteUser
  }

  const acceptTerms = async (): Promise<any> => {
    const query = gql`
      mutation AcceptTerms {
        acceptTerms
      }
    `

    const result = await graphQL({ query })

    return result.acceptTerms
  }

  const updateUserDefaultSeparation = async ({
    key,
    stems
  }: {
    key?: string
    stems?: string[]
  }): Promise<boolean> => {
    const variables = {
      defaultSeparation: { type: key, stems }
    }

    const query = gql`
      mutation UpdateUser($defaultSeparation: UserPrefDefaultSeparationInput) {
        updateUser(
          userProperties: {
            preferences: { defaultSeparation: $defaultSeparation }
          }
        ) {
          preferences {
            defaultSeparation {
              stems
              type
            }
          }
          id
        }
      }
    `

    const result = await graphQL({ query, variables })
    return !!result?.updateUser?.id
  }

  const updateUserGoals = async ({
    goals
  }: {
    goals: string[]
  }): Promise<any> => {
    const variables = {
      userProperties: {
        goals
      }
    }
    const query = gql`
      mutation UpdateUser($userProperties: UpdateUserProperties!) {
        updateUser(userProperties: $userProperties) {
          id
          goals
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const updateUserSkills = async ({
    instruments
  }: {
    instruments: []
  }): Promise<any> => {
    const variables = {
      userProperties: {
        instruments
      }
    }
    const query = gql`
      mutation UpdateUser($userProperties: UpdateUserProperties!) {
        updateUser(userProperties: $userProperties) {
          id
          instruments {
            skillLevel
            instrumentId
          }
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const updateUserComunication: UserCli['updateUserComunication'] = async ({
    updates,
    activity,
    collaboration
  }): Promise<any> => {
    const variables = {
      userProperties: {
        preferences: {
          communication: {
            updates,
            activity,
            collaboration
          }
        }
      }
    }
    const query = gql`
      mutation UpdateUser($userProperties: UpdateUserProperties!) {
        updateUser(userProperties: $userProperties) {
          id
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const experimentConversion = async ({
    metricName,
    featureFlag
  }: {
    metricName: string
    featureFlag?: string
  }): Promise<any> => {
    const variables = {
      data: {
        metricName,
        featureFlag
      }
    }
    const query = gql`
      mutation ExperimentConversion($data: ExperimentConversionInput!) {
        experimentConversion(data: $data)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const getFlags = async ({ flags }: { flags: string[] }): Promise<any> => {
    const variables = {
      flags
    }
    const query = gql`
      query GetFlags($flags: [String]) {
        flags: getFlags(flags: $flags)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const markMessageAs = async ({
    messageIds,
    read
  }: {
    messageIds: string[]
    read: boolean
  }): Promise<any> => {
    const variables = { messageIds, read }
    const query = gql`
      mutation MarkMessageAs($messageIds: [String!]!, $read: Boolean!) {
        markMessageAs(messageIds: $messageIds, read: $read)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const updateDemoPlaylistPreferences = async ({
    optOut,
    hideFromLibrary
  }: {
    optOut?: string[]
    hideFromLibrary?: boolean
  }): Promise<boolean> => {
    const variables = {
      userProperties: {
        preferences: {
          demoPlaylist: {
            optOut,
            hideFromLibrary
          }
        }
      }
    }
    const query = gql`
      mutation UpdateUser($userProperties: UpdateUserProperties!) {
        updateUser(userProperties: $userProperties) {
          id
          instruments {
            skillLevel
            instrumentId
          }
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const signOutTempComposerJwt = async (): Promise<any> => {
    const query = gql`
      mutation DeleteTempComposerJwt {
        deleteTempComposerJwt
      }
    `
    const result = await graphQL({ query, variables: {} })
    return result
  }

  return {
    deleteUser,
    acceptTerms,
    updateUserDefaultSeparation,
    updateUserGoals,
    updateUserSkills,
    updateUserComunication,
    getFlags,
    signOutTempComposerJwt,
    markMessageAs,
    experimentConversion,
    updateDemoPlaylistPreferences
  }
}

export default User
