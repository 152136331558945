import { useRouter } from 'next/router'
import { useCallback } from 'react'

interface UseRedirectToLoginHook {
  redirectToLogin: ({
    redirect,
    email
  }: {
    redirect?: string
    email?: string
  }) => void
}

export const useRedirectToLogin = (): UseRedirectToLoginHook => {
  const router = useRouter()
  const redirectToLogin = useCallback(
    async ({ redirect, email }: any) => {
      router.push({
        pathname: '/logout',
        query: {
          email,
          redirect
        }
      })
    },
    [router]
  )

  return {
    redirectToLogin
  }
}
