import React from 'react'
import { IconProps } from './icons.types'

export const Collections: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 4C2 2.89543 2.89543 2 4 2H16C17.1046 2 18 2.89543 18 4V16C18 17.1046 17.1046 18 16 18H4C2.89543 18 2 17.1046 2 16V4Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M22 6V20C22 21.1046 21.1046 22 20 22H6"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M12.17 5V12.5734C12.17 13.4748 11.567 14.2649 10.6974 14.5026L9.95481 14.7056C8.97056 14.9747 8 14.2338 8 13.2135V13.2135C8 12.5159 8.46681 11.9047 9.13974 11.7211L12.17 10.8944"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)
