import React from 'react'
import { IconProps } from './icons.types'

export const KeyUp: React.FC<IconProps> = ({ width = 8, height = 10 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.18223 4.2L3.98223 1L0.782227 4.2"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.98242 1L3.98242 9"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
