function on(eventType: string, listener: (data?: any) => void): void {
  document.addEventListener(eventType, listener)
}

function off(eventType: string, listener: (data?: any) => void): void {
  document.removeEventListener(eventType, listener)
}

function trigger(eventType: string, data?: any): void {
  const event = new CustomEvent(eventType, { detail: data })
  document.dispatchEvent(event)
}

export { on, off, trigger }
