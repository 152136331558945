import ar from '../languages/ar/common.json'
import de from '../languages/de/common.json'
import en from '../languages/en/common.json'
import es from '../languages/es/common.json'
import fr from '../languages/fr/common.json'
import hiIN from '../languages/hi_IN/common.json'
import id from '../languages/id/common.json'
import it from '../languages/it/common.json'
import ja from '../languages/ja/common.json'
import ko from '../languages/ko/common.json'
import ms from '../languages/ms/common.json'
import nl from '../languages/nl/common.json'
import pl from '../languages/pl/common.json'
import ptBR from '../languages/pt_BR/common.json'
import ru from '../languages/ru/common.json'
import sv from '../languages/sv/common.json'
import th from '../languages/th/common.json'
import tr from '../languages/tr/common.json'
import vi from '../languages/vi/common.json'
import zhCN from '../languages/zh_CN/common.json'
import zhTW from '../languages/zh_TW/common.json'
import ca from '../languages/ca/common.json'
import fi from '../languages/fi/common.json'
import hu from '../languages/hu/common.json'
import cs from '../languages/cs/common.json'
import hr from '../languages/hr/common.json'
import da from '../languages/da/common.json'
import el from '../languages/el/common.json'
import he from '../languages/he/common.json'
import no from '../languages/no/common.json'
import ro from '../languages/ro/common.json'
import sk from '../languages/sk/common.json'
import uk from '../languages/uk/common.json'

export const catalogs: {
  [locale: string]: {
    [key: string]: string
  }
} = {
  ar,
  en,
  fr,
  id,
  ja,
  ms,
  pl,
  ru,
  th,
  vi,
  de,
  es,
  it,
  ko,
  nl,
  sv,
  tr,
  ca,
  fi,
  hu,
  cs,
  hr,
  da,
  el,
  he,
  no,
  ro,
  sk,
  uk,
  hi_IN: hiIN,
  pt_BR: ptBR,
  zh_TW: zhTW,
  zh_CN: zhCN
}
