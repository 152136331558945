import React from 'react'
import { IconProps } from './icons.types'

export const SocialApple: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.8554 8.30349C20.745 8.37154 18.3167 9.78531 18.3442 12.7265C18.3748 16.2445 21.4282 17.416 21.4633 17.4293C21.4359 17.513 20.9751 19.0978 19.8537 20.7345C18.8847 22.1525 17.8808 23.5624 16.2964 23.5918C14.7398 23.6203 14.2381 22.6682 12.4598 22.6682C10.6805 22.6682 10.1235 23.5624 8.65149 23.6203C7.12219 23.6782 5.95787 22.0891 4.98164 20.679C2.98593 17.7931 1.46007 12.5233 3.50895 8.96695C4.52613 7.20074 6.34423 6.08178 8.3169 6.05288C9.81924 6.02449 11.2361 7.06284 12.155 7.06284C13.0608 7.06284 14.6843 5.85241 16.5979 5.99398C17.3479 6.04956 19.477 6.2734 20.8554 8.30349ZM15.4171 4.0889C16.2298 3.10668 16.7767 1.73875 16.6261 0.378174C15.4564 0.424561 14.0417 1.15733 13.2027 2.13893C12.4514 3.00848 11.7915 4.40116 11.9707 5.73397C13.2745 5.83471 14.6051 5.07174 15.4171 4.0889Z"
      fill="white"
    />
  </svg>
)
