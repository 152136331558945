import { gql } from 'graphql-request'

export const getGlobalCampaingQuery = gql`
  query Campaign {
    campaign {
      id
      name
      couponCode
      discountPercentage
      eligiblePlans
      planNames
      endAt
      metadata
      type
    }
    flags: getFlags(flags: ["offerModeOnWeb"])
  }
`
export const getIndividualCampaingQuery = gql`
  query GetIndividualCampaign($couponCode: String!) {
    campaign(couponCode: $couponCode) {
      id
      name
      couponCode
      discountPercentage
      eligiblePlans
      planNames
      endAt
      metadata
      type
    }
  }
`
