import { AppProps } from 'next/app'
import React, { ReactElement } from 'react'
import { Wrapper } from '../containers/wrapper'
import '../styles/styles.scss'

const MyApp = ({ Component, pageProps }: AppProps): ReactElement => {
  return (
    <Wrapper>
      <Component {...pageProps} />
    </Wrapper>
  )
}

export default MyApp
