import React from 'react'
import { IconProps } from './icons.types'

export const Play: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.8138 10.2476C22.1953 11.0074 22.1953 12.9926 20.8138 13.7524L6.96384 21.3699C5.63093 22.103 4 21.1387 4 19.6175L4 4.38254C4 2.86133 5.63093 1.89701 6.96384 2.63011L20.8138 10.2476Z"
      fill="currentColor"
    />
  </svg>
)
