import React, { ReactNode } from 'react'
import { Icon } from '../icon'
import styles from './key.module.scss'

interface KeyProps {
  children: ReactNode
}

interface PredefinedKey {
  // special chars
  Backspace: React.FC
  Del: React.FC
  Down: React.FC
  Enter: React.FC
  Left: React.FC
  QuestionMark: React.FC
  Right: React.FC
  Shift: React.FC
  Slash: React.FC
  Spacebar: React.FC
  SquareBracketClose: React.FC
  SquareBracketOpen: React.FC
  Up: React.FC

  // numbers
  Zero: React.FC
  One: React.FC
  Two: React.FC
  Nine: React.FC

  // letters
  A: React.FC
  C: React.FC
  E: React.FC
  F: React.FC
  I: React.FC
  K: React.FC
  L: React.FC
  M: React.FC
  N: React.FC
  P: React.FC
  Q: React.FC
  R: React.FC
  S: React.FC
  T: React.FC
  U: React.FC
}

const Key: React.FC<KeyProps> & PredefinedKey = ({ children }) => (
  <div className={styles.key}>{children}</div>
)

// special chars
const Backspace = (): any => (
  <Key>
    <Icon name="key-backspace" width={16} height={12} />
  </Key>
)
Key.Backspace = Backspace

const Del = (): any => <Key>DEL</Key>
Key.Del = Del

const Down = (): any => (
  <Key>
    <Icon name="key-down" width={8} height={10} />
  </Key>
)
Key.Down = Down

const Enter = (): any => (
  <Key>
    <Icon name="key-enter" width={16} height={10} />
  </Key>
)
Key.Enter = Enter

const Left = (): any => (
  <Key>
    <Icon name="key-left" width={10} height={8} />
  </Key>
)
Key.Left = Left

const QuestionMark = (): any => <Key>?</Key>
Key.QuestionMark = QuestionMark

const Right = (): any => (
  <Key>
    <Icon name="key-right" width={10} height={8} />
  </Key>
)
Key.Right = Right

const Shift = (): any => (
  <Key>
    <Icon name="key-shift" width={16} height={12} />
  </Key>
)
Key.Shift = Shift

const Slash = (): any => <Key>/</Key>
Key.Slash = Slash

const Spacebar = (): any => (
  <Key>
    <Icon name="key-spacebar" width={28} height={7} />
  </Key>
)
Key.Spacebar = Spacebar

const SquareBracketClose = (): any => <Key>]</Key>
Key.SquareBracketClose = SquareBracketClose

const SquareBracketOpen = (): any => <Key>[</Key>
Key.SquareBracketOpen = SquareBracketOpen

const Up = (): any => (
  <Key>
    <Icon name="key-up" width={8} height={10} />
  </Key>
)
Key.Up = Up

// numbers
const Zero = (): any => <Key>0</Key>
Key.Zero = Zero

const One = (): any => <Key>1</Key>
Key.One = One

const Two = (): any => <Key>2</Key>
Key.Two = Two

const Nine = (): any => <Key>2</Key>
Key.Nine = Nine

// letters
const A = (): any => <Key>A</Key>
Key.A = A

const C = (): any => <Key>C</Key>
Key.C = C

const E = (): any => <Key>E</Key>
Key.E = E

const F = (): any => <Key>F</Key>
Key.F = F

const I = (): any => <Key>I</Key>
Key.I = I

const K = (): any => <Key>K</Key>
Key.K = K

const L = (): any => <Key>L</Key>
Key.L = L

const M = (): any => <Key>M</Key>
Key.M = M

const N = (): any => <Key>N</Key>
Key.N = N

const P = (): any => <Key>P</Key>
Key.P = P

const Q = (): any => <Key>Q</Key>
Key.Q = Q

const R = (): any => <Key>R</Key>
Key.R = R

const S = (): any => <Key>S</Key>
Key.S = S

const T = (): any => <Key>T</Key>
Key.T = T

const U = (): any => <Key>U</Key>
Key.U = U

export default Key
