import * as Sentry from '@sentry/nextjs'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useRedirectToLoginOnAuthenticationTimeout } from '../use-redirect-to-login'
import { ContextShell } from '../../../modules/context'
import { useSetupAnalytics } from '../../analytics/use-setup-analytics'
import { UserContext } from '../../../types'
import { useFcmToken } from '../use-fcm-token'

interface UseUserLogged {
  isUserLoogedIn: boolean
}

function onSetupSentry(user: UserContext): void {
  Sentry.configureScope((scope) => {
    scope.setUser({ id: user.id })
  })
}

export const useUserLogged = (): UseUserLogged => {
  const { user, userToken } = useContext(ContextShell)
  const [defined, setDefined] = useState(false)

  const { onUserLoaded } = useSetupAnalytics()
  const { onSetFcmToken } = useFcmToken()
  useRedirectToLoginOnAuthenticationTimeout()

  const handleUserLoaded = useCallback(
    (_user: UserContext) => {
      onUserLoaded(_user)
      onSetupSentry(_user)
      onSetFcmToken(!!_user?.featureFlags?.webNotification)
    },
    [onUserLoaded, onSetFcmToken]
  )

  useEffect(() => {
    if (user && !defined) {
      setDefined(true)
      handleUserLoaded(user)
    }
  }, [defined, user, handleUserLoaded])

  return {
    isUserLoogedIn: !!userToken
  }
}
