import { useCallback } from 'react'
import { key } from '.'

interface UseGetUtmStorage {
  getUTM(): any
}

export const useGetUtmStorage = (): UseGetUtmStorage => {
  const getUTM = useCallback(
    () =>
      JSON.parse(
        sessionStorage.getItem(key) || localStorage.getItem(key) || '{}'
      ),
    []
  )

  return {
    getUTM
  }
}
