import React from 'react'
import styles from './skeleton.module.scss'

const SKELETON_ITEMS = 3

export const Skeleton: React.FC = () => {
  return (
    <div>
      <span className={styles.title} />
      <ul className={styles.list}>
        {Array.from(Array(SKELETON_ITEMS).keys()).map((item) => (
          <li key={`skeleton-${item}`}>
            <div className={styles.item}>
              <span className={styles.url} />
              <span className={styles.copy} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
