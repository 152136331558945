import React from 'react'
import { IconProps } from './icons.types'

export const InstrumentStrings: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0893 6.2613C10.3473 8.10482 14.0007 8.6 15.1 6C16.1 3.73234 14.2 0.499999 10.6059 1.06318C7.31453 1.53919 5.82386 5.25575 7 8.00004C7.75 9.75002 8.82925 11.0784 10.0562 13.5273C11.0447 15.5 11.0447 18.5498 11.0447 18.5498L11.7656 18.723C13.7249 19.1937 15.2101 20.7945 15.533 22.7835L15.5682 23H21.5C21 17.5 19.5 14.5 13 9.75002C11.612 8.73571 10.4356 7.50938 10.1701 6.62498C10.1314 6.49593 10.1049 6.37466 10.0893 6.2613ZM10.0893 6.2613C9.87405 4.70155 11.7052 4.59541 11.7052 5.05169M2.5 9.75004C2.5 10.1643 2.83579 10.5 3.25 10.5C3.66421 10.5 4 10.1643 4 9.75004C4 9.33583 3.66421 9.00005 3.25 9.00005C2.83579 9.00005 2.5 9.33583 2.5 9.75004ZM5.5 15.75C5.5 16.1642 5.83579 16.5 6.25 16.5C6.66421 16.5 7 16.1642 7 15.75C7 15.3358 6.66421 15 6.25 15C5.83579 15 5.5 15.3358 5.5 15.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
