import React from 'react'
import { IconProps } from './icons.types'

export const Volume0: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 5H11.75C11.75 4.7117 11.5847 4.44891 11.3249 4.32402C11.065 4.19913 10.7566 4.23425 10.5315 4.41435L11 5ZM6 9V9.75C6.1703 9.75 6.33554 9.69204 6.46852 9.58565L6 9ZM2 9V8.25C1.58579 8.25 1.25 8.58579 1.25 9H2ZM2 15H1.25C1.25 15.4142 1.58579 15.75 2 15.75V15ZM6 15L6.46852 14.4143C6.33554 14.308 6.1703 14.25 6 14.25V15ZM11 19L10.5315 19.5857C10.7566 19.7658 11.065 19.8009 11.3249 19.676C11.5847 19.5511 11.75 19.2883 11.75 19H11ZM23.5303 9.53033C23.8232 9.23744 23.8232 8.76256 23.5303 8.46967C23.2374 8.17678 22.7626 8.17678 22.4697 8.46967L23.5303 9.53033ZM16.4697 14.4697C16.1768 14.7626 16.1768 15.2374 16.4697 15.5303C16.7626 15.8232 17.2374 15.8232 17.5303 15.5303L16.4697 14.4697ZM17.5303 8.46967C17.2374 8.17678 16.7626 8.17678 16.4697 8.46967C16.1768 8.76256 16.1768 9.23744 16.4697 9.53033L17.5303 8.46967ZM22.4697 15.5303C22.7626 15.8232 23.2374 15.8232 23.5303 15.5303C23.8232 15.2374 23.8232 14.7626 23.5303 14.4697L22.4697 15.5303ZM10.5315 4.41435L5.53148 8.41435L6.46852 9.58565L11.4685 5.58565L10.5315 4.41435ZM6 8.25H2V9.75H6V8.25ZM1.25 9V15H2.75V9H1.25ZM2 15.75H6V14.25H2V15.75ZM5.53148 15.5857L10.5315 19.5857L11.4685 18.4143L6.46852 14.4143L5.53148 15.5857ZM11.75 19V5H10.25V19H11.75ZM22.4697 8.46967L16.4697 14.4697L17.5303 15.5303L23.5303 9.53033L22.4697 8.46967ZM16.4697 9.53033L22.4697 15.5303L23.5303 14.4697L17.5303 8.46967L16.4697 9.53033Z"
      fill="currentColor"
    />
  </svg>
)
