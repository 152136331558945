import React from 'react'
import { IconProps } from './icons.types'

export const PlayOnRepeat: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5303 6.53033L9.53033 10.5303C9.23744 10.8232 8.76256 10.8232 8.46967 10.5303C8.17678 10.2374 8.17678 9.76256 8.46967 9.46967L11.1893 6.75H9H7C4.10051 6.75 1.75 9.1005 1.75 12C1.75 14.8995 4.10051 17.25 7 17.25H7.5C7.91421 17.25 8.25 17.5858 8.25 18C8.25 18.4142 7.91421 18.75 7.5 18.75H7C3.27208 18.75 0.25 15.7279 0.25 12C0.25 8.27208 3.27208 5.25 7 5.25H9H11.1893L8.46967 2.53033C8.17678 2.23744 8.17678 1.76256 8.46967 1.46967C8.76256 1.17678 9.23744 1.17678 9.53033 1.46967L13.5303 5.46967C13.8232 5.76256 13.8232 6.23744 13.5303 6.53033ZM15.75 6C15.75 5.58579 16.0858 5.25 16.5 5.25H17C20.7279 5.25 23.75 8.27208 23.75 12C23.75 15.7279 20.7279 18.75 17 18.75H12.8107L15.5303 21.4697C15.8232 21.7626 15.8232 22.2374 15.5303 22.5303C15.2374 22.8232 14.7626 22.8232 14.4697 22.5303L10.4697 18.5303C10.1768 18.2374 10.1768 17.7626 10.4697 17.4697L14.4697 13.4697C14.7626 13.1768 15.2374 13.1768 15.5303 13.4697C15.8232 13.7626 15.8232 14.2374 15.5303 14.5303L12.8107 17.25H17C19.8995 17.25 22.25 14.8995 22.25 12C22.25 9.1005 19.8995 6.75 17 6.75H16.5C16.0858 6.75 15.75 6.41421 15.75 6Z"
      fill="currentColor"
    />
  </svg>
)
