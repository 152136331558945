import React from 'react'
import { IconProps } from './icons.types'

export const Email: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.80182 5.64574C2.95608 5.1302 3.43711 4.75 4.00006 4.75H20.0001C20.563 4.75 21.044 5.1302 21.1983 5.64574L12.1434 11.9842C12.0574 12.0444 11.9428 12.0444 11.8567 11.9842L2.80182 5.64574ZM1.25009 5.98758C1.24998 5.99478 1.24997 6.00199 1.25006 6.00919V18C1.25006 19.5142 2.48585 20.75 4.00006 20.75H20.0001C21.5143 20.75 22.7501 19.5142 22.7501 18V6.00917M21.2501 7.44049V18C21.2501 18.6858 20.6859 19.25 20.0001 19.25H4.00006C3.31428 19.25 2.75006 18.6858 2.75006 18V7.44049L10.9965 13.213C11.5991 13.6348 12.4011 13.6348 13.0036 13.213L21.2501 7.44049ZM22.75 5.98759C22.7433 4.47904 21.5101 3.25 20.0001 3.25H4.00006C2.48999 3.25 1.25683 4.47903 1.25009 5.98758"
      fill="currentColor"
    />
  </svg>
)
