/* eslint-disable camelcase */
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import React, { useEffect } from 'react'
import { getLocale } from '../../localization'

import ar from '../../localization/data/localization/languages/ar/common.json'
import az from '../../localization/data/localization/languages/az/common.json'
import bg from '../../localization/data/localization/languages/bg/common.json'
import bs_BA from '../../localization/data/localization/languages/bs_BA/common.json'
import ca from '../../localization/data/localization/languages/ca/common.json'
import cs from '../../localization/data/localization/languages/cs/common.json'
import da from '../../localization/data/localization/languages/da/common.json'
import de from '../../localization/data/localization/languages/de/common.json'
import el from '../../localization/data/localization/languages/el/common.json'
import en from '../../localization/data/localization/languages/en/common.json'
import es from '../../localization/data/localization/languages/es/common.json'
import fa from '../../localization/data/localization/languages/fa/common.json'
import fi from '../../localization/data/localization/languages/fi/common.json'
import fr from '../../localization/data/localization/languages/fr/common.json'
import he from '../../localization/data/localization/languages/he/common.json'
import hi_IN from '../../localization/data/localization/languages/hi_IN/common.json'
import hr from '../../localization/data/localization/languages/hr/common.json'
import hu from '../../localization/data/localization/languages/hu/common.json'
import id from '../../localization/data/localization/languages/id/common.json'
import it from '../../localization/data/localization/languages/it/common.json'
import ja from '../../localization/data/localization/languages/ja/common.json'
import km_KH from '../../localization/data/localization/languages/km_KH/common.json'
import ko from '../../localization/data/localization/languages/ko/common.json'
import ms from '../../localization/data/localization/languages/ms/common.json'
import nl from '../../localization/data/localization/languages/nl/common.json'
import no from '../../localization/data/localization/languages/no/common.json'
import pl from '../../localization/data/localization/languages/pl/common.json'
import pt_BR from '../../localization/data/localization/languages/pt_BR/common.json'
import ro from '../../localization/data/localization/languages/ro/common.json'
import ru from '../../localization/data/localization/languages/ru/common.json'
import sk from '../../localization/data/localization/languages/sk/common.json'
import sr from '../../localization/data/localization/languages/sr/common.json'
import sv from '../../localization/data/localization/languages/sv/common.json'
import th from '../../localization/data/localization/languages/th/common.json'
import tr from '../../localization/data/localization/languages/tr/common.json'
import uk from '../../localization/data/localization/languages/uk/common.json'
import vi from '../../localization/data/localization/languages/vi/common.json'
import zh_CN from '../../localization/data/localization/languages/zh_CN/common.json'
import zh_TW from '../../localization/data/localization/languages/zh_TW/common.json'

i18n.load({
  ar,
  az,
  bg,
  bs_BA,
  ca,
  cs,
  da,
  de,
  el,
  en,
  es,
  fa,
  fi,
  fr,
  he,
  hi_IN,
  hr,
  hu,
  id,
  it,
  ja,
  km_KH,
  ko,
  ms,
  nl,
  no,
  pl,
  pt_BR,
  ro,
  ru,
  sk,
  sr,
  sv,
  th,
  tr,
  uk,
  vi,
  zh_CN,
  zh_TW
})

i18n.activate('en')

interface ContainerI18n {
  children: React.ReactNode
}

export const I18nContainer: React.FC<ContainerI18n> = ({ children }) => {
  useEffect(() => {
    const locale = getLocale(global.window.navigator.language)
    i18n.activate(locale)
  }, [])

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>
}
