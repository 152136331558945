import classnames from 'classnames'
import React, { ReactElement } from 'react'
import styles from './skeleton.module.scss'

interface SkeletonProps {
  className?: string
}

export const Skeleton: React.FC<SkeletonProps> = ({
  className
}): ReactElement => (
  <div className={classnames(className, styles.container)}>
    <span />
    <span />
    <span />
  </div>
)
