import { useCallback, useContext, useEffect } from 'react'
import { useAsyncFn } from 'react-use'
import { config } from '../../../config'
import { Moises } from '../../../lib/cli'
import { off, on } from '../../../lib/events'
import { ContextShell } from '../../../modules/context'
import { Playlist } from '../../../types/task.types'
import { TrackDisplaySet, TrackOrderSort } from '../../../types/playlist.types'

interface UseListPlaylist {
  error: boolean
  loading: boolean
  playlists: Playlist[] | null
  mutate(): void
  fetchPlaylists(): Promise<void>
}

const MoisesCLI = Moises({ apiEndpoint: config.api.endpoint })

export const useFetchPlaylists = ({
  sort = 'DESC',
  display
}: {
  sort?: TrackOrderSort
  display: TrackDisplaySet
}): UseListPlaylist => {
  const { userToken } = useContext(ContextShell)

  const [state, fetchPlaylists] = useAsyncFn(async () => {
    if (!userToken) {
      return null
    }

    MoisesCLI.auth(userToken)

    try {
      const list = await MoisesCLI.fetchPlaylists({ sort, display })

      return list || null
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error('Error fetching playlists')
      throw new Error(e)
    }
  }, [userToken, sort, display])

  const mutate = useCallback(() => fetchPlaylists(), [fetchPlaylists])

  useEffect(() => {
    on('playlist:create-playlist-done', mutate)
    on('playlist:update-playlist', mutate)

    return () => {
      off('playlist:create-playlist-done', mutate)
      off('playlist:update-playlist', mutate)
    }
  }, [mutate])

  return {
    error: !!state.error,
    loading: state.loading,
    playlists: state.value || null,
    mutate,
    fetchPlaylists
  }
}
