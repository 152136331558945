import React from 'react'
import { IconProps } from './icons.types'

export const Link: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4922_37916)">
      <path
        d="M8.33349 10.8332C8.69136 11.3117 9.14795 11.7075 9.67228 11.994C10.1966 12.2805 10.7764 12.4508 11.3724 12.4935C11.9683 12.5362 12.5665 12.4502 13.1263 12.2414C13.6861 12.0326 14.1944 11.7058 14.6168 11.2832L17.1168 8.78322C17.8758 7.99738 18.2958 6.94487 18.2863 5.85238C18.2768 4.7599 17.8386 3.71485 17.0661 2.94231C16.2935 2.16978 15.2485 1.73157 14.156 1.72208C13.0635 1.71259 12.011 2.13256 11.2252 2.89156L9.79182 4.31656M11.6668 9.16656C11.309 8.68812 10.8524 8.29224 10.328 8.00577C9.80371 7.71931 9.22391 7.54896 8.62796 7.50628C8.03201 7.4636 7.43384 7.54958 6.87405 7.75841C6.31425 7.96723 5.8059 8.294 5.3835 8.71656L2.8835 11.2166C2.12451 12.0024 1.70453 13.0549 1.71402 14.1474C1.72352 15.2399 2.16172 16.2849 2.93426 17.0575C3.70679 17.83 4.75184 18.2682 5.84433 18.2777C6.93681 18.2872 7.98932 17.8672 8.77517 17.1082L10.2002 15.6832"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4922_37916">
        <rect width={width} height={height} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
)
