import React from 'react'
import { IconProps } from './icons.types'

export const DownloadApp: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="5"
      y="1"
      width="14"
      height="22"
      rx="2"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 8C12.75 7.58579 12.4142 7.25 12 7.25C11.5858 7.25 11.25 7.58579 11.25 8V14.1893L9.53033 12.4697C9.23744 12.1768 8.76256 12.1768 8.46967 12.4697C8.17678 12.7626 8.17678 13.2374 8.46967 13.5303L11.4697 16.5303C11.7626 16.8232 12.2374 16.8232 12.5303 16.5303L15.5303 13.5303C15.8232 13.2374 15.8232 12.7626 15.5303 12.4697C15.2374 12.1768 14.7626 12.1768 14.4697 12.4697L12.75 14.1893V8Z"
      fill="currentColor"
    />
    <line
      x1="9.75"
      y1="2.25"
      x2="14.25"
      y2="2.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
