import React from 'react'
import { IconProps } from './icons.types'

export const Repeat: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3333 5.77591C14.9703 3.89701 12.757 2.67508 10.2583 2.67508C6.11617 2.67508 2.7583 6.03294 2.7583 10.1751C2.7583 14.3172 6.11617 17.6751 10.2583 17.6751C13.4328 17.6751 16.1467 15.7028 17.2414 12.9167M16.5916 2.32507V6.15841H12.7583"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
