import React from 'react'
import { IconProps } from './icons.types'

export const User: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.75 7C8.75 5.20507 10.2051 3.75 12 3.75C13.7949 3.75 15.25 5.20507 15.25 7C15.25 8.79493 13.7949 10.25 12 10.25C10.2051 10.25 8.75 8.79493 8.75 7ZM12 2.25C9.37665 2.25 7.25 4.37665 7.25 7C7.25 9.62335 9.37665 11.75 12 11.75C14.6234 11.75 16.75 9.62335 16.75 7C16.75 4.37665 14.6234 2.25 12 2.25ZM8 14.25C6.74022 14.25 5.53204 14.7504 4.64124 15.6412C3.75044 16.532 3.25 17.7402 3.25 19V21C3.25 21.4142 3.58579 21.75 4 21.75C4.41421 21.75 4.75 21.4142 4.75 21V19C4.75 18.138 5.09241 17.3114 5.7019 16.7019C6.3114 16.0924 7.13805 15.75 8 15.75H16C16.862 15.75 17.6886 16.0924 18.2981 16.7019C18.9076 17.3114 19.25 18.138 19.25 19V21C19.25 21.4142 19.5858 21.75 20 21.75C20.4142 21.75 20.75 21.4142 20.75 21V19C20.75 17.7402 20.2496 16.532 19.3588 15.6412C18.468 14.7504 17.2598 14.25 16 14.25H8Z"
      fill="currentColor"
    />
  </svg>
)
