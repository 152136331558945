import React from 'react'
import { IconProps } from './icons.types'

export const Spacebar: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 29 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 1V5C1.5 5.55228 1.94772 6 2.5 6H26.5C27.0523 6 27.5 5.55228 27.5 5V1"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
