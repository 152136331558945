import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { getEnv } from '../../../config/index'
import { FirebaseAuth } from '../../../lib/firebase'
import { eraseCookie, setCookie } from '../../../lib/cookies'
import ReactGA from '../../../lib/react-ga'
import { rudderstackIdentify } from '../../../lib/rudderstack'
import { UserContext } from '../../../types/user-context.types'

interface UseSetupAnalytics {
  onUserLoaded(user: UserContext): void
}

export const useSetupAnalytics = (): UseSetupAnalytics => {
  const { pathname } = useRouter()
  const [configured, setConfigured] = useState(false)

  useEffect(() => {
    const testMode = getEnv() !== 'production'

    ReactGA.initialize('G-VCCHPVZC99', {
      testMode
    })
  }, [])

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: pathname
    })
    global?.window?.rudderanalytics?.page()
    global?.document.body.setAttribute('data-page', pathname.split('/')[1])
  }, [pathname])

  const onUserLoaded = useCallback(
    (user: UserContext) => {
      if (!user.id || configured) return

      ReactGA.set({ dimension1: user.id })
      ReactGA.set({ userId: user.id })

      rudderstackIdentify(user.id)

      try {
        if (user.shouldIdentifyOnCIO) {
          // @ts-ignore
          _cio.identify({
            id: user.id,
            email: user.email
          })
        }
      } catch (error) {
        // @ts-ignore
        console.log('CIO error', error) // eslint-disable-line no-console
      }

      // hotjar
      global?.window?.hj('identify', user.id, {
        createdAt: user?.createdAt,
        isPremium: user.subscription?.isPremium || false,
        isPro: user.subscription?.isPro || false,
        locale:
          navigator.languages && navigator.languages.length
            ? navigator.languages[0]
            : // @ts-ignore
              navigator.userLanguage ||
              navigator.language ||
              // @ts-ignore
              navigator.browserLanguage ||
              'unset'
      })

      // facebook pixel
      global.window.fbq('init', '828020674704420', { external_id: user.id })

      setConfigured(true)
      //  disable toast verify email
      // setUserHasEmailVerified(!!user.emailVerified)
    },
    [configured]
  )

  useEffect(() => {
    const unsubscribe = FirebaseAuth.onAuthStateChanged((user) => {
      if (!user) {
        setConfigured(false)
        eraseCookie('moises:user:auth')
      } else {
        setCookie({
          days: 365,
          value: 'true',
          name: 'moises:user:auth'
        })
      }
    })

    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [])

  return {
    onUserLoaded
  }
}
