import React from 'react'
import { IconProps } from './icons.types'

export const GiftBox: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_12178_1440)">
      <path
        d="M16.6667 10V18.3334H3.33341V10M10.0001 18.3334V5.83335M10.0001 5.83335H6.25008C5.69755 5.83335 5.16764 5.61386 4.77694 5.22316C4.38624 4.83246 4.16675 4.30255 4.16675 3.75002C4.16675 3.19749 4.38624 2.66758 4.77694 2.27688C5.16764 1.88618 5.69755 1.66669 6.25008 1.66669C9.16675 1.66669 10.0001 5.83335 10.0001 5.83335ZM10.0001 5.83335H13.7501C14.3026 5.83335 14.8325 5.61386 15.2232 5.22316C15.6139 4.83246 15.8334 4.30255 15.8334 3.75002C15.8334 3.19749 15.6139 2.66758 15.2232 2.27688C14.8325 1.88618 14.3026 1.66669 13.7501 1.66669C10.8334 1.66669 10.0001 5.83335 10.0001 5.83335ZM1.66675 5.83335H18.3334V10H1.66675V5.83335Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_12178_1440">
        <rect width="20" height="20" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
)
