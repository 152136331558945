import React from 'react'
import { IconProps } from './icons.types'

export const InstrumentPiano: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.25 16V22H3.75V16H2.25ZM4 23.75H22V22.25H4V23.75ZM23.75 22V16H22.25V22H23.75ZM22 23.75C22.9665 23.75 23.75 22.9665 23.75 22H22.25C22.25 22.1381 22.1381 22.25 22 22.25V23.75ZM2.25 22C2.25 22.9665 3.0335 23.75 4 23.75V22.25C3.86193 22.25 3.75 22.1381 3.75 22H2.25Z"
      fill="currentColor"
    />
    <path
      d="M7.25 20C7.25 20.4142 7.58579 20.75 8 20.75C8.41421 20.75 8.75 20.4142 8.75 20H7.25ZM7.25 16V20H8.75V16H7.25Z"
      fill="currentColor"
    />
    <path
      d="M12.25 20C12.25 20.4142 12.5858 20.75 13 20.75C13.4142 20.75 13.75 20.4142 13.75 20H12.25ZM12.25 16V20H13.75V16H12.25Z"
      fill="currentColor"
    />
    <path
      d="M17.25 20C17.25 20.4142 17.5858 20.75 18 20.75C18.4142 20.75 18.75 20.4142 18.75 20H17.25ZM17.25 16V20H18.75V16H17.25Z"
      fill="currentColor"
    />
    <path
      d="M1 6C1 3.23858 3.23858 1 6 1H10.2083C12.5044 1 14.5752 2.38057 15.4583 4.5V4.5C16.0891 6.01388 17.5683 7 19.2083 7H21C23.2091 7 25 8.79086 25 11V16H1V6Z"
      stroke="currentColor"
      strokeWidth="1.375"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
