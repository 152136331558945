import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React, { useCallback, useState } from 'react'
import { trigger } from '../../../lib/events'
import { ButtonIcon } from '../../../components/button-icon'
import { Icon } from '../../../components/icon'
import { ModalConfirmation } from '../../../components/modals/modal-confirmation'
import { useDeletePlaylist } from '../../../hooks/playlist/use-delete-playlist'
import styles from './delete-playlist.module.scss'

interface DeletePlaylistProps {
  playlistId?: string
  events?: {
    [key: string]: string
  }
}

export const DeletePlaylist: React.FC<DeletePlaylistProps> = ({
  playlistId,
  events
}) => {
  const { i18n } = useLingui()
  const { deletePlaylist, loading } = useDeletePlaylist()

  const [openedModal, setOpenedModal] = useState(false)
  const onOpenModal = useCallback(() => setOpenedModal(true), [])
  const onDismissModal = useCallback(() => setOpenedModal(false), [])

  const onConfirmDelete = useCallback(async () => {
    if (playlistId) {
      await deletePlaylist({ playlistId, events })
      trigger('playlist:update-playlist')
    }
    onDismissModal()
  }, [playlistId, events, deletePlaylist, onDismissModal])

  return (
    <div className={styles.container}>
      <ButtonIcon
        light
        onClick={onOpenModal}
        icon={<Icon name="trash" />}
        title={i18n._(t`playlist.options.delete`)}
      />

      <ModalConfirmation
        danger
        loading={loading}
        isOpen={openedModal}
        onDismiss={onDismissModal}
        onConfirm={onConfirmDelete}
        title={i18n._(t`delete.playlist.title`)}
        info={i18n._(t`delete.playlist.description`)}
        confirmIcon="trash"
        confirmLabel={i18n._(t`playlist.options.delete`)}
        cancelLabel={i18n._(t`cancel`)}
        loadingLabel={i18n._(t`label.loading`)}
      />
    </div>
  )
}
