import { useCallback, useEffect, useState } from 'react'
import createPersistedState from 'use-persisted-state'

interface DataStorage {
  show: boolean
  date: Date
}

interface UsePersistentDismissible {
  show: boolean
  onDismiss: () => void
  getStorage: () => DataStorage | null
}

export const usePersistentDismissible = ({
  id,
  enabled = true,
  type = 'local',
  defaultValue = true
}: {
  id?: string
  enabled: boolean
  type?: 'local' | 'session'
  defaultValue?: boolean
}): UsePersistentDismissible => {
  const persistedState = createPersistedState<{
    [key: string]: DataStorage
  }>(
    'moises:dismissible',
    type === 'session' ? global.sessionStorage : undefined
  )
  const [persistedShow, setPersistedShow] = persistedState({})
  const [show, setShow] = useState(
    id && persistedShow?.[id] ? persistedShow?.[id]?.show : defaultValue
  )

  const onDismiss = useCallback(() => {
    if (!enabled) return

    setShow(false)
    setPersistedShow((map) => {
      if (id) {
        Object.assign(map, { [id]: { show: false, date: new Date() } })
      }
      return map
    })
  }, [enabled, id, setPersistedShow])

  const getStorage = useCallback(() => {
    if (!id || !persistedShow) return null
    return Object.prototype.hasOwnProperty.call(persistedShow, id)
      ? persistedShow?.[id]
      : null
  }, [id, persistedShow])

  useEffect(() => {
    if (enabled && id && persistedShow?.[id] !== undefined) {
      setShow(persistedShow?.[id]?.show)
    }
  }, [enabled, id, persistedShow, setPersistedShow])

  return {
    show,
    onDismiss,
    getStorage
  }
}
