import React from 'react'
import { IconProps } from './icons.types'

export const HiHat: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9835 1V6.0625M11.9835 23V18.625M4.55722 8.99113C3.91491 9.58623 3.54999 10.2669 3.54999 10.9911C3.54999 12.1563 4.49446 13.2086 6.01896 13.9734C7.54346 14.7382 9.65311 15.2105 11.9835 15.2105C14.3139 15.2105 16.4235 14.7382 17.948 13.9734C19.4725 13.2086 20.417 12.1563 20.417 10.9911C20.417 10.2669 20.0521 9.58623 19.4098 8.99113M4.55722 8.99113C4.94824 9.35341 5.44207 9.68399 6.01896 9.9734C7.54346 10.7382 9.65311 11.2105 11.9835 11.2105C14.3139 11.2105 16.4235 10.7382 17.948 9.9734C18.5249 9.68399 19.0187 9.35341 19.4098 8.99113M4.55722 8.99113C3.91491 8.39604 3.54999 7.7154 3.54999 6.99113C3.54999 5.82594 4.49446 4.77368 6.01896 4.00887C7.54346 3.24405 9.65311 2.77182 11.9835 2.77182C14.3139 2.77182 16.4235 3.24405 17.948 4.00887C19.4725 4.77368 20.417 5.82594 20.417 6.99113C20.417 7.7154 20.0521 8.39604 19.4098 8.99113"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
