import { useRouter } from 'next/router'
import { ReactElement, useContext } from 'react'
import { ModalKeyboardShortcuts } from '../../modules/modals/modal-keyboard-shortcuts'
import { AuthenticatedContainerType } from './types'
import { DownloadQueueContainer } from '../download-queue-container'
import { ContextShell } from '../../modules/context'
import { Loading } from '../../components/loading'
import styles from './styles.module.scss'
import { Sidebar } from '../../modules/sidebar/sidebar'
import { ModalGetApp } from '../../modules/modals/modal-get-app'
import { useUserLogged } from '../../hooks/auth/use-user-logged'
import { ModalReferralLink } from '../../modules/modals/modal-referral-link'
import { ModalGroupPlanTrial } from '../../modules/modals/modal-group-plan-promotion'

export const routesToNotShowAppFrame = [
  '/',
  '/login',
  '/logout',
  '/reset-password'
]

const getRoutes = (isUserLoogedIn: boolean): string[] =>
  routesToNotShowAppFrame.filter(() => {
    if (!isUserLoogedIn) return true

    return true
  })

export const AuthenticatedContainer = ({
  children
}: AuthenticatedContainerType): ReactElement => {
  const { pathname } = useRouter()
  const { user } = useContext(ContextShell)
  const { isUserLoogedIn } = useUserLogged()

  const routes = getRoutes(isUserLoogedIn)

  if (routes.includes(pathname)) {
    return children
  }

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <Sidebar loading={!user} />
      </div>

      <div className={styles.content}>
        {!user ? (
          <Loading className={styles.loading} />
        ) : (
          <>
            <ModalGetApp />
            <ModalReferralLink />
            <ModalKeyboardShortcuts />
            <ModalGroupPlanTrial />
            <DownloadQueueContainer />
            {children}
          </>
        )}
      </div>
    </div>
  )
}
