import React, { useContext } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Alert } from '../../components/alert'
import { ContextShell } from '../../modules/context'
import styles from './toast-container.module.scss'

export const ToastContainer: React.FC = () => {
  const { toast } = useContext(ContextShell)

  return (
    <ul className={styles.list}>
      <TransitionGroup>
        {toast.list.map((alert: any) => (
          <CSSTransition key={alert.id} timeout={500} classNames="alert-toast">
            <li className={styles.item}>
              <Alert {...alert} />
            </li>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </ul>
  )
}
