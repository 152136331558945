import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useContext, useMemo } from 'react'
import { ContextShell } from '../../../modules/context'

export interface UsePlanName {
  plan: string
}

export const usePlanName = (): UsePlanName => {
  const { i18n } = useLingui()
  const { user } = useContext(ContextShell)

  const isPro = useMemo(() => !!user?.subscription?.isPro, [user])
  const isPremium = useMemo(() => !!user?.subscription?.isPremium, [user])

  const plan = useMemo(() => {
    if (isPro) {
      return i18n._(t`producer_plan_title`)
    }

    if (isPremium) {
      return i18n._(t`pricing_plan_musician`)
    }

    return i18n._(t`pricing_starter_title`)
  }, [i18n, isPro, isPremium])

  return {
    plan
  }
}
