import { useState, useCallback } from 'react'

export type UseSidebar = {
  isOpenedSidebar: boolean
  onCollapsedSidebar: () => void
  onOpenSidebar: () => void
}

export const useSidebar = (): UseSidebar => {
  const [isOpenedSidebar, setIsOpenedSidebar] = useState(true)

  const onCollapsedSidebar = useCallback(() => {
    setIsOpenedSidebar(false)
  }, [])

  const onOpenSidebar = useCallback(() => {
    setIsOpenedSidebar(true)
  }, [])

  return {
    isOpenedSidebar,
    onCollapsedSidebar,
    onOpenSidebar
  }
}
