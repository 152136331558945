import React from 'react'
import { IconProps } from './icons.types'

export const KeyLeft: React.FC<IconProps> = ({ width = 10, height = 8 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.2 0.800012L1 4.00001L4.2 7.20001"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 4.00006L9 4.00006"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
