import React from 'react'
import { IconProps } from './icons.types'

export const AudioFiles: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4853 7L21.4142 9.92895C21.7893 10.304 22 10.8127 22 11.3432V21C22 22.1046 21.1046 23 20 23H9"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M15.4853 4L18.4142 6.92895C18.7893 7.30403 19 7.81273 19 8.34317V18C19 19.1046 18.1046 20 17 20H6"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M13.071 1.58579C12.696 1.21071 12.1873 1 11.6568 1H5C3.89543 1 3 1.89543 3 3V15C3 16.1046 3.89543 17 5 17H14C15.1046 17 16 16.1046 16 15V5.34317C16 4.81273 15.7893 4.30403 15.4142 3.92895L13.071 1.58579Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M10.5 6C10.9142 6 11.25 6.33579 11.25 6.75V9.47734C11.2503 9.4887 11.2503 9.50004 11.25 9.51134V10.42C11.25 11.1993 10.7347 11.8847 9.986 12.1011L8.96383 12.3966C8.35631 12.5722 7.75 12.1164 7.75 11.484V10.2225C7.75 9.79947 8.02975 9.42739 8.43617 9.3099L9.75 8.93009V6.75C9.75 6.33579 10.0858 6 10.5 6Z"
      fill="currentColor"
    />
  </svg>
)
