import React from 'react'
import { IconProps } from './icons.types'

export const Clock: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.75 12.8906C2.75 7.78199 6.89137 3.64062 12 3.64062C17.1086 3.64062 21.25 7.78199 21.25 12.8906C21.25 17.9993 17.1086 22.1406 12 22.1406C6.89137 22.1406 2.75 17.9993 2.75 12.8906ZM12 2.14062C6.06294 2.14062 1.25 6.95356 1.25 12.8906C1.25 18.8277 6.06294 23.6406 12 23.6406C17.9371 23.6406 22.75 18.8277 22.75 12.8906C22.75 6.95356 17.9371 2.14062 12 2.14062ZM12.75 6.89062C12.75 6.47641 12.4142 6.14062 12 6.14062C11.5858 6.14062 11.25 6.47641 11.25 6.89062V12.8906C11.25 13.1747 11.4105 13.4344 11.6646 13.5614L15.6646 15.5614C16.0351 15.7467 16.4856 15.5965 16.6708 15.226C16.8561 14.8556 16.7059 14.405 16.3354 14.2198L12.75 12.4271V6.89062Z"
      fill="currentColor"
    />
  </svg>
)
