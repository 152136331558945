import React from 'react'
import { IconProps } from './icons.types'

export const Widget: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 15C3 14.4477 3.44772 14 4 14H9C9.55228 14 10 14.4477 10 15V20C10 20.5523 9.55228 21 9 21H4C3.44772 21 3 20.5523 3 20V15Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M14 15C14 14.4477 14.4477 14 15 14H20C20.5523 14 21 14.4477 21 15V20C21 20.5523 20.5523 21 20 21H15C14.4477 21 14 20.5523 14 20V15Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M3 4C3 3.44772 3.44772 3 4 3H9C9.55228 3 10 3.44772 10 4V9C10 9.55228 9.55228 10 9 10H4C3.44772 10 3 9.55228 3 9V4Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M14 4C14 3.44772 14.4477 3 15 3H20C20.5523 3 21 3.44772 21 4V9C21 9.55228 20.5523 10 20 10H15C14.4477 10 14 9.55228 14 9V4Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
)
