import acceptLanguage from 'accept-language'
import { locales } from './locales'

acceptLanguage.languages(
  locales.reduce(
    (acc: string[], curVal) => acc.concat(curVal.allCompatibleLanguageISO),
    []
  )
)

export const getLocaleLang = (language: string): string => {
  const foundLocale = locales.find(({ allCompatibleLanguageISO }) =>
    allCompatibleLanguageISO.find((langISO) => langISO === language)
  )

  return foundLocale ? foundLocale.id : 'en'
}

export const getLocale = (language: string): string =>
  getLocaleLang(acceptLanguage.get(language)?.toLowerCase() || 'en')
