import React from 'react'
import { IconProps } from './icons.types'

export const UserVoice: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 22V20.1111C17 19.1092 16.6049 18.1483 15.9016 17.4398C15.1984 16.7313 14.2446 16.3333 13.25 16.3333H5.75C4.75544 16.3333 3.80161 16.7313 3.09835 17.4398C2.39509 18.1483 2 19.1092 2 20.1111V22M19.7331 2C21.1846 3.59147 22 5.74967 22 8C22 10.2503 21.1846 12.4085 19.7331 14M17 4.99576C17.7257 5.79149 18.1334 6.87059 18.1334 7.99576C18.1334 9.12092 17.7257 10.2 17 10.9958M13.25 8.77778C13.25 10.8642 11.5711 12.5556 9.5 12.5556C7.42893 12.5556 5.75 10.8642 5.75 8.77778C5.75 6.69137 7.42893 5 9.5 5C11.5711 5 13.25 6.69137 13.25 8.77778Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
