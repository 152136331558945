import React from 'react'
import { IconProps } from './icons.types'

export const Pitch: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 14.25C14.5858 14.25 14.25 14.5858 14.25 15C14.25 15.4142 14.5858 15.75 15 15.75V14.25ZM22 15.75C22.4142 15.75 22.75 15.4142 22.75 15C22.75 14.5858 22.4142 14.25 22 14.25V15.75ZM2 14.25C1.58579 14.25 1.25 14.5858 1.25 15C1.25 15.4142 1.58579 15.75 2 15.75V14.25ZM7.42551 15.75C7.83972 15.75 8.17551 15.4142 8.17551 15C8.17551 14.5858 7.83972 14.25 7.42551 14.25V15.75ZM2 21.25C1.58579 21.25 1.25 21.5858 1.25 22C1.25 22.4142 1.58579 22.75 2 22.75V21.25ZM22 22.75C22.4142 22.75 22.75 22.4142 22.75 22C22.75 21.5858 22.4142 21.25 22 21.25V22.75ZM2 7.25C1.58579 7.25 1.25 7.58579 1.25 8C1.25 8.41421 1.58579 8.75 2 8.75V7.25ZM22 8.75C22.4142 8.75 22.75 8.41421 22.75 8C22.75 7.58579 22.4142 7.25 22 7.25V8.75ZM9.93651 18.1431L10.1281 18.8682L9.93651 18.1431ZM13.0865 17.3107L12.8949 16.5855L13.0865 17.3107ZM15.3255 2C15.3255 1.58579 14.9897 1.25 14.5755 1.25C14.1613 1.25 13.8255 1.58579 13.8255 2H15.3255ZM8.91451 13.4181L9.10613 14.1432L8.91451 13.4181ZM15 15.75H22V14.25H15V15.75ZM2 15.75H7.42551V14.25H2V15.75ZM2 22.75H22V21.25H2V22.75ZM2 8.75H22V7.25H2V8.75ZM6.67551 15.3517V16.2095H8.17551V15.3517H6.67551ZM10.1281 18.8682L13.2781 18.0358L12.8949 16.5855L9.74488 17.418L10.1281 18.8682ZM15.3255 15.377V11.9221H13.8255V15.377H15.3255ZM15.3255 11.9221V2H13.8255V11.9221H15.3255ZM14.3839 11.197L8.72288 12.693L9.10613 14.1432L14.7671 12.6472L14.3839 11.197ZM13.2781 18.0358C14.4849 17.7169 15.3255 16.6252 15.3255 15.377H13.8255C13.8255 15.9444 13.4434 16.4406 12.8949 16.5855L13.2781 18.0358ZM6.67551 16.2095C6.67551 18.0138 8.38373 19.3292 10.1281 18.8682L9.74488 17.418C8.95197 17.6275 8.17551 17.0296 8.17551 16.2095H6.67551ZM8.17551 15.3517C8.17551 14.7844 8.55761 14.2882 9.10613 14.1432L8.72288 12.693C7.51613 13.0119 6.67551 14.1036 6.67551 15.3517H8.17551Z"
      fill="currentColor"
    />
  </svg>
)
