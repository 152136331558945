import { request } from 'graphql-request'
import {
  getGlobalCampaingQuery,
  getIndividualCampaingQuery
} from './get-campaing-query'

export const getGlobalCampaingData = async (
  apiUrl: any,
  auth: any,
  clientHeaders: any
): Promise<any> => {
  if (!auth) {
    return null
  }

  const { campaign, flags } = await request(
    apiUrl,
    getGlobalCampaingQuery,
    null,
    {
      authorization: auth,
      ...clientHeaders
    }
  )

  return { campaign, flags }
}
export const getIndividualCampaingData = async (
  apiUrl: any,
  auth: any,
  couponCode: any,
  clientHeaders: any
): Promise<any> => {
  if (!auth) {
    return null
  }

  const { campaign } = await request(
    apiUrl,
    getIndividualCampaingQuery,
    { couponCode },
    {
      authorization: auth,
      ...clientHeaders
    }
  )

  return { campaign }
}
