import React from 'react'
import { IconProps } from './icons.types'

export const Shuffle: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.3125H15.75C15.8263 1.3125 15.899 1.32768 15.9653 1.35518C16.0317 1.38263 16.0938 1.42332 16.1477 1.47725C16.2017 1.53118 16.2424 1.59334 16.2698 1.65968C16.2973 1.72601 16.3125 1.79873 16.3125 1.875V5.625C16.3125 5.93566 16.0607 6.1875 15.75 6.1875C15.4393 6.1875 15.1875 5.93566 15.1875 5.625V3.233L3.39775 15.0227C3.17808 15.2424 2.82192 15.2424 2.60225 15.0227C2.38258 14.8031 2.38258 14.4469 2.60225 14.2273L14.392 2.4375H12C11.6893 2.4375 11.4375 2.18566 11.4375 1.875C11.4375 1.56434 11.6893 1.3125 12 1.3125ZM16.3125 15.375V11.625C16.3125 11.3143 16.0607 11.0625 15.75 11.0625C15.4393 11.0625 15.1875 11.3143 15.1875 11.625V14.017L11.6477 10.4773C11.4281 10.2576 11.0719 10.2576 10.8523 10.4773C10.6326 10.6969 10.6326 11.0531 10.8523 11.2727L14.392 14.8125H12C11.6893 14.8125 11.4375 15.0643 11.4375 15.375C11.4375 15.6857 11.6893 15.9375 12 15.9375H15.75C15.8263 15.9375 15.899 15.9223 15.9653 15.8948C16.0286 15.8686 16.0882 15.8304 16.1403 15.78C16.1453 15.7752 16.1502 15.7703 16.155 15.7653C16.2054 15.7132 16.2436 15.6536 16.2698 15.5903C16.2973 15.524 16.3125 15.4513 16.3125 15.375ZM3.39775 2.22725C3.17808 2.00758 2.82192 2.00758 2.60225 2.22725C2.38258 2.44692 2.38258 2.80308 2.60225 3.02275L6.35225 6.77275C6.57192 6.99242 6.92808 6.99242 7.14775 6.77275C7.36742 6.55308 7.36742 6.19692 7.14775 5.97725L3.39775 2.22725Z"
      fill="currentColor"
    />
  </svg>
)
