import classnames from 'classnames'
import React, { useState, useCallback, useRef, useEffect } from 'react'
import { Icon } from '../../icon'
import styles from './form-input.module.scss'

export interface InputProps {
  className?: string
  attributes?: any
  id?: string
  type?: string
  error?: boolean
  disabled?: boolean
  success?: boolean
  placeholder: string
  maxLength?: number
  value: string
  icon?: React.ReactNode
  enableFocus?: boolean
  onChange(event: React.ChangeEvent<HTMLInputElement>): void
  onClear?(): void
  onFocus?(event?: React.ChangeEvent<HTMLInputElement>): void
  onBlur?(): void
  children?: React.ReactNode
}

export const FormInput: React.FC<InputProps> = ({
  id,
  className,
  type = 'text',
  disabled = false,
  attributes = {},
  error,
  success,
  value,
  onFocus,
  onBlur,
  onChange,
  onClear,
  maxLength,
  placeholder,
  icon,
  enableFocus,
  children
}) => {
  const [typeInput, setTypeInput] = useState(type)
  const inputRef = useRef<HTMLInputElement>(null)

  const onToggleTypePassword = useCallback(() => {
    if (typeInput === 'password') {
      setTypeInput('text')
    } else {
      setTypeInput('password')
    }
  }, [typeInput])

  useEffect(() => {
    if (enableFocus) {
      inputRef.current?.focus()
    }
  }, [enableFocus])

  return (
    <div className={classnames(className, styles.container)}>
      <div className={styles.inputContent}>
        <input
          {...attributes}
          id={id}
          type={typeInput}
          value={value}
          maxLength={maxLength}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          className={classnames(styles.input, {
            [styles.error]: error,
            [styles.success]: success,
            [styles.hasIcon]: icon
          })}
          ref={inputRef}
        />

        {icon ? <div className={styles.icon}>{icon}</div> : null}

        {value && !disabled && onClear ? (
          <button
            id={`${id}_clear`}
            type="button"
            className={styles.button}
            onClick={onClear}
            aria-label="Close"
          >
            <Icon name="close" className={styles.iconClose} />
          </button>
        ) : null}

        {value && !disabled && type === 'password' ? (
          <button
            type="button"
            aria-label="Show password"
            className={styles.button}
            onClick={onToggleTypePassword}
          >
            <Icon name="eye" className={styles.iconEye} />
          </button>
        ) : null}
      </div>

      {children ? <div className={styles.content}>{children}</div> : null}
    </div>
  )
}
