export const getBrowserLocale = (): string =>
  global?.window
    ? (
        window?.navigator?.language ||
        window?.navigator?.languages[0] ||
        // @ts-ignore (deprecated IE api)
        window?.navigator?.userLanguage ||
        // @ts-ignore (deprecated IE api)
        window?.navigator?.browserLanguage ||
        // @ts-ignore (deprecated IE api)
        window?.navigator?.systemLanguage ||
        ''
      ).replace('_', '-')
    : ''
