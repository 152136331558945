import { useContext, useMemo } from 'react'
import { useAsyncFn } from 'react-use'
import * as Sentry from '@sentry/nextjs'
import { config } from '../../../config'
import { Moises } from '../../../lib/cli'
import { usePlanName } from '../../plans/use-plan-name'
import { ContextShell } from '../../../modules/context'
import { ReferralLink } from '../../../types/referral-links.types'

interface UseFetchReferralLinks {
  error: boolean
  loading: boolean
  fetchReferralLinks(): Promise<ReferralLink[]>
}

const MoisesCLI = Moises({ apiEndpoint: config.api.endpoint })

export const useFetchReferralLinks = (): UseFetchReferralLinks => {
  const { plan } = usePlanName()
  const { user, userToken } = useContext(ContextShell)

  const userName = useMemo(() => user?.name || user?.email || '', [user])

  const domain =
    config.env === 'stage' ? 'studio-stage.moises.ai' : 'studio.moises.ai'

  const [state, fetchReferralLinks] = useAsyncFn(async (): Promise<
    ReferralLink[]
  > => {
    if (!userToken || !user?.featureFlags) {
      return []
    }

    MoisesCLI.auth(userToken)
    let result = []

    try {
      const { referralLinks } = await MoisesCLI.fetchReferralLinks()
      result = referralLinks.map((item) => ({
        ...item,
        url: `https://${domain}/login?signup&redirect=claim-referral-link/${
          item.id
        }&user=${encodeURIComponent(userName)}&plan=${plan}`
      }))
    } catch (e: any) {
      Sentry.captureMessage('Error fetching referral links', {
        level: 'error',
        extra: {
          isAuthFailed: e?.response?.errors?.some(
            (i: any) => i?.extensions?.code === 'UNAUTHENTICATED'
          ),
          codes: e?.response?.errors
            ?.map((i: any) => i?.extensions?.code)
            ?.join(', '),
          error: e
        }
      })
      throw new Error(e)
    }

    return result
  }, [userToken])

  return {
    error: !!state.error,
    loading: state.loading,
    fetchReferralLinks
  }
}
