import React from 'react'
import { IconProps } from './icons.types'

export const NoChordsDetection: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ic-no-chords-detection">
      <path
        id="Stroke"
        d="M3 22V22.75H4.5V22H3ZM4.5 9.12497C4.5 8.71076 4.16421 8.37497 3.75 8.37497C3.33579 8.37497 3 8.71076 3 9.12497H4.5ZM10 1.99997C10 1.58576 9.66422 1.24997 9.25 1.24997C8.83579 1.24997 8.5 1.58576 8.5 1.99997H10ZM15.5 1.99997C15.5 1.58576 15.1642 1.24997 14.75 1.24997C14.3358 1.24997 14 1.58576 14 1.99997H15.5ZM14 22V22.75H15.5V22H14ZM15.5 20.125C15.5 19.7108 15.1642 19.375 14.75 19.375C14.3358 19.375 14 19.7108 14 20.125H15.5ZM2.45406 1.39338C2.16117 1.10048 1.68629 1.10048 1.3934 1.39338C1.10051 1.68627 1.10051 2.16114 1.3934 2.45404L2.45406 1.39338ZM21.5459 22.6066C21.8388 22.8995 22.3137 22.8995 22.6066 22.6066C22.8995 22.3137 22.8995 21.8388 22.6066 21.5459L21.5459 22.6066ZM5.75 2.74997H18.25V1.24997H5.75V2.74997ZM19.5 3.99997V20.25H21V3.99997H19.5ZM18.25 2.74997C18.9404 2.74997 19.5 3.30961 19.5 3.99997H21C21 2.48119 19.7688 1.24997 18.25 1.24997V2.74997ZM4.5 22V9.12497H3V22H4.5ZM10 9.24998V1.99997H8.5V9.24998H10ZM10 20.7969V14.75H8.5V20.7969H10ZM15.5 14.75V1.99997H14V14.75H15.5ZM15.5 22V20.125H14V22H15.5ZM9 13.75H3.75V15.25H9V13.75ZM10 14.75C10 14.1977 9.55229 13.75 9 13.75V15.25C8.72386 15.25 8.5 15.0261 8.5 14.75H10ZM14.5 15.25H20.25V13.75H14.5V15.25ZM1.3934 2.45404L3.23347 4.29411L4.29413 3.23345L2.45406 1.39338L1.3934 2.45404ZM3.23347 4.29411L21.5459 22.6066L22.6066 21.5459L4.29413 3.23345L3.23347 4.29411ZM5.75 1.24997C4.34056 1.24997 3.17971 2.30964 3.01894 3.67614L4.50866 3.85141C4.58161 3.23141 5.11012 2.74997 5.75 2.74997V1.24997Z"
        fill="currentColor"
      />
      <g id="Fill">
        <path
          d="M11.25 20.75C11.25 21.8546 10.3546 22.75 9.25 22.75C8.14543 22.75 7.25 21.8546 7.25 20.75C7.25 19.6454 8.14543 18.75 9.25 18.75C10.3546 18.75 11.25 19.6454 11.25 20.75Z"
          fill="currentColor"
        />
        <path
          d="M16.75 8.25C16.75 9.35457 15.8546 10.25 14.75 10.25C13.6454 10.25 12.75 9.35457 12.75 8.25C12.75 7.14543 13.6454 6.25 14.75 6.25C15.8546 6.25 16.75 7.14543 16.75 8.25Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
)
