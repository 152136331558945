import React from 'react'
import { IconProps } from './icons.types'

export const PriceTag: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2955_1132)">
      <path
        d="M7.75 9.25H7.76M21.34 15.66L14.17 22.83C13.9843 23.016 13.7637 23.1635 13.5209 23.2641C13.2781 23.3648 13.0178 23.4166 12.755 23.4166C12.4922 23.4166 12.2319 23.3648 11.9891 23.2641C11.7463 23.1635 11.5257 23.016 11.34 22.83L2.75 14.25V4.25H12.75L21.34 12.84C21.7125 13.2147 21.9216 13.7216 21.9216 14.25C21.9216 14.7784 21.7125 15.2853 21.34 15.66Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2955_1132">
        <rect width={width} height={height} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
)
