import React from 'react'
import { IconProps } from './icons.types'

export const Info: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18.3333C14.6024 18.3333 18.3334 14.6024 18.3334 9.99999C18.3334 5.39762 14.6024 1.66666 10 1.66666C5.39765 1.66666 1.66669 5.39762 1.66669 9.99999C1.66669 14.6024 5.39765 18.3333 10 18.3333ZM10 9.37499C10.3452 9.37499 10.625 9.65481 10.625 9.99999V13.3333C10.625 13.6785 10.3452 13.9583 10 13.9583C9.65484 13.9583 9.37502 13.6785 9.37502 13.3333V9.99999C9.37502 9.65481 9.65484 9.37499 10 9.37499ZM9.9945 6.66666C9.53731 6.66666 9.16669 7.03975 9.16669 7.49999C9.16669 7.96023 9.53731 8.33332 9.9945 8.33332H10.0055C10.4627 8.33332 10.8334 7.96023 10.8334 7.49999C10.8334 7.03975 10.4627 6.66666 10.0055 6.66666H9.9945Z"
      fill="currentColor"
    />
  </svg>
)
