import React, { ReactElement } from 'react'
import { useToast } from '../../hooks/toast'
import { useFirebaseToken } from '../../hooks/auth/use-firebase-token'
import { useGetUserData } from '../../hooks/user/use-get-user-data/use-get-user-data'
import { useEvent } from '../../hooks/analytics/use-event/use-event'
import { ShellContext } from '../../types'
import { useSidebar } from '../../hooks/sidebar'
import { useCampaign } from '../../hooks/campaign/use-campaing/use-campaign'

export const ContextShell = React.createContext<ShellContext>({
  user: null,
  userToken: null,
  loadingUserData: false,
  silentRetry: () => {},
  event: {
    sendEvent: () => {},
    eventTrack: async () => {}
  },
  toast: {
    list: [],
    add: () => {},
    onClearFixedToast: () => {}
  },
  sidebar: {
    isOpenedSidebar: true,
    onCollapsedSidebar: () => {},
    onOpenSidebar: () => {}
  },
  campaign: {
    id: null,
    endDate: null,
    profileBannerWeb: null,
    countdownBannerImage: null,
    name: null,
    type: null,

    onValidateCoupon: async () => ({
      error: 'INVALID_COUPON',
      campaign: undefined
    }),
    hasCampaignForPlanCycle: () => false,
    hasCampaign: () => false,
    onClearCoupon: () => {},
    hasValidCouponForPlanCycle: () => false,
    eligiblePlans: [],
    metadata: null,
    loaded: false
  }
})

export const ContextProvider = ({
  children
}: {
  children: React.ReactNode
}): ReactElement => {
  const toast = useToast()
  const userToken = useFirebaseToken()
  const sidebar = useSidebar()
  const { user, loadingUserData, silentRetry } = useGetUserData({ userToken })
  const event = useEvent({ user, userToken })
  const campaign = useCampaign({ userToken })

  return (
    <ContextShell.Provider
      value={{
        user,
        userToken,
        loadingUserData,
        silentRetry,
        event,
        toast,
        sidebar,
        campaign
      }}
    >
      {children}
    </ContextShell.Provider>
  )
}
