import React from 'react'
import { IconProps } from './icons.types'

export const KeyShift: React.FC<IconProps> = ({ width = 13, height = 13 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.29129 11V7.99999H10.9981C11.8712 7.99999 12.325 6.95939 11.7309 6.31954L7.52408 1.78915C7.12845 1.36309 6.45412 1.36309 6.05849 1.78915L1.8517 6.31954C1.25756 6.95939 1.71133 7.99999 2.5845 7.99999H4.29129V11C4.29129 11.5523 4.739 12 5.29129 12H8.29129C8.84357 12 9.29129 11.5523 9.29129 11Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
