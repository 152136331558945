import React from 'react'
import { IconProps } from './icons.types'

export const Forward: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1623 10.6833V5.97089C11.1623 5.50901 11.6623 5.22034 12.0623 5.45128L22.5003 11.4777C22.9003 11.7086 22.9003 12.286 22.5003 12.5169L12.0623 18.5434C11.6623 18.7743 11.1623 18.4856 11.1623 18.0237V13.3114L2.1002 18.5434C1.7002 18.7743 1.2002 18.4856 1.2002 18.0237V5.97089C1.2002 5.50901 1.7002 5.22033 2.1002 5.45127L11.1623 10.6833Z"
      fill="currentColor"
    />
  </svg>
)
