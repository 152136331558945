import React from 'react'
import { IconProps } from './icons.types'

export const Edit: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0303 3.03031C19.2875 2.77314 19.6363 2.62866 20 2.62866C20.3637 2.62866 20.7125 2.77314 20.9697 3.03031C21.2268 3.28749 21.3713 3.63629 21.3713 3.99998C21.3713 4.36368 21.2268 4.71248 20.9697 4.96965L11.6166 14.3228L9.03078 14.9692L9.67722 12.3834L19.0303 3.03031ZM20 1.12866C19.2385 1.12866 18.5081 1.43118 17.9697 1.96965L8.46967 11.4697C8.37355 11.5658 8.30536 11.6862 8.27239 11.8181L7.27239 15.8181C7.2085 16.0737 7.28338 16.344 7.46967 16.5303C7.65596 16.7166 7.92632 16.7915 8.1819 16.7276L12.1819 15.7276C12.3138 15.6946 12.4342 15.6264 12.5303 15.5303L22.0303 6.03031C22.5688 5.49184 22.8713 4.7615 22.8713 3.99998C22.8713 3.23846 22.5688 2.50813 22.0303 1.96965C21.4919 1.43118 20.7615 1.12866 20 1.12866ZM4 3.24998C3.27065 3.24998 2.57118 3.53972 2.05546 4.05544C1.53973 4.57117 1.25 5.27064 1.25 5.99998V20C1.25 20.7293 1.53973 21.4288 2.05546 21.9445C2.57118 22.4603 3.27065 22.75 4 22.75H18C18.7293 22.75 19.4288 22.4603 19.9445 21.9445C20.4603 21.4288 20.75 20.7293 20.75 20V13C20.75 12.5858 20.4142 12.25 20 12.25C19.5858 12.25 19.25 12.5858 19.25 13V20C19.25 20.3315 19.1183 20.6494 18.8839 20.8839C18.6495 21.1183 18.3315 21.25 18 21.25H4C3.66848 21.25 3.35054 21.1183 3.11612 20.8839C2.8817 20.6494 2.75 20.3315 2.75 20V5.99998C2.75 5.66846 2.8817 5.35052 3.11612 5.1161C3.35054 4.88168 3.66848 4.74998 4 4.74998H11C11.4142 4.74998 11.75 4.4142 11.75 3.99998C11.75 3.58577 11.4142 3.24998 11 3.24998H4Z"
      fill="currentColor"
    />
  </svg>
)
