import React from 'react'
import { IconProps } from './icons.types'

export const Cloud: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.67246 2.25837C9.99419 2.20968 11.3098 2.46125 12.5203 2.99417C13.7308 3.52709 14.8047 4.3275 15.6613 5.33521C16.3912 6.19378 16.9471 7.18379 17.3004 8.24998H18H18.0005C19.278 8.25083 20.5188 8.67707 21.5271 9.46145C22.5355 10.2458 23.2539 11.3437 23.569 12.5817C23.8841 13.8197 23.778 15.1274 23.2673 16.2984C22.7567 17.4694 21.8706 18.437 20.749 19.0485C20.3853 19.2468 19.9297 19.1127 19.7315 18.749C19.5332 18.3853 19.6673 17.9298 20.031 17.7315C20.86 17.2795 21.5149 16.5643 21.8924 15.6988C22.2698 14.8333 22.3482 13.8667 22.1153 12.9517C21.8824 12.0366 21.3514 11.2252 20.6061 10.6454C19.8608 10.0657 18.9437 9.75061 17.9995 9.74998H16.74C16.3981 9.74998 16.0994 9.51874 16.0138 9.18771C15.7395 8.12671 15.2283 7.14171 14.5185 6.30674C13.8087 5.47177 12.9189 4.80858 11.9159 4.36701C10.9129 3.92545 9.82283 3.71701 8.72769 3.75736C7.63255 3.79771 6.5608 4.0858 5.59303 4.59997C4.62525 5.11415 3.78663 5.84103 3.14021 6.72596C2.49379 7.61089 2.0564 8.63086 1.86091 9.70917C1.66542 10.7875 1.71693 11.8961 2.01156 12.9516C2.30619 14.0071 2.83627 14.9822 3.56196 15.8033C3.83625 16.1137 3.80699 16.5877 3.49661 16.862C3.18622 17.1363 2.71225 17.107 2.43796 16.7966C1.56213 15.8055 0.922374 14.6288 0.566787 13.3549C0.2112 12.081 0.149036 10.743 0.384969 9.44159C0.620902 8.14018 1.14879 6.90919 1.92895 5.84117C2.70911 4.77315 3.72124 3.89588 4.88925 3.27533C6.05725 2.65477 7.35074 2.30707 8.67246 2.25837ZM11.4692 21.5298L7.46967 17.5303C7.17678 17.2374 7.17678 16.7626 7.46967 16.4697C7.76256 16.1768 8.23744 16.1768 8.53033 16.4697L11.25 19.1893L11.25 12C11.25 11.5858 11.5858 11.25 12 11.25C12.4142 11.25 12.75 11.5858 12.75 12L12.75 19.1893L15.4697 16.4697C15.7626 16.1768 16.2374 16.1768 16.5303 16.4697C16.8232 16.7626 16.8232 17.2374 16.5303 17.5303L12.5303 21.5303C12.3093 21.7513 11.9848 21.8056 11.7129 21.6931C11.8013 21.7298 11.8983 21.75 12 21.75M11.4697 21.5303C11.4713 21.532 11.4729 21.5336 11.4746 21.5352L11.4697 21.5303Z"
      fill="currentColor"
    />
  </svg>
)
