import React from 'react'
import { IconProps } from './icons.types'

export const Music: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.2199 11.6875L16.5499 12.9452C15.6767 13.1803 15.0699 13.9721 15.0699 14.8764V16.0279C15.0699 17.3401 16.3122 18.2968 17.5809 17.9615L19.7309 17.3935C20.6085 17.1615 21.2199 16.3676 21.2199 15.4598V2.30328C21.2199 1.64574 20.5962 1.16715 19.9611 1.33736L9.08008 4.25338C8.64292 4.37054 8.33894 4.7667 8.33894 5.2193V18.9119C8.33894 19.8196 7.72758 20.6136 6.84994 20.8455L4.70094 21.4134C3.43228 21.7487 2.18994 20.792 2.18994 19.4798V18.3214C2.18994 17.4136 2.80131 16.6197 3.67894 16.3878L8.33894 15.1562"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)
