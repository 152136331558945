import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React, {
  useCallback,
  useState,
  useContext,
  useRef,
  RefObject,
  useMemo
} from 'react'
import classnames from 'classnames'
import { useRouter } from 'next/router'
import { useOnClickOutside } from '../../../hooks/misc/use-on-click-outside'
import { UserAvatar } from '../../../components/sidebar/user-avatar'
import { trigger } from '../../../lib/events'
import { BannerCountdown } from '../../../components/sidebar/banner-countdown/banner-countdown'
import { UseCampaignCountdown } from '../../../hooks/campaign/use-campaing-countdown/use-campaign-countdown'
import { AnnouncementBanner } from '../../../components/sidebar/announcement-banner'
import { UseAnnouncement } from '../../../hooks/announcements/use-announcement'
import { ContextShell } from '../../context'
import { DropMenu } from '../../../components/drop-menu'
import { ButtonIcon } from '../../../components/button-icon'
import { Icon } from '../../../components/icon'
import { useDataManageGroup } from '../../../hooks/group/use-data-manage-group'
import { useStoreModals } from '../../../hooks/modals/use-store-modals'
import styles from './nav-user.module.scss'

interface NavUserProps {
  className?: string
  small?: boolean
  bannerCountdown?: UseCampaignCountdown
  announcement?: UseAnnouncement
  showDaysInCountdown?: boolean
}

interface UserPlanProps {
  isPro?: boolean
  isPremium?: boolean
  isGroupSubscription?: boolean
}

const UserPlan: React.FC<UserPlanProps> = ({
  isPro,
  isPremium,
  isGroupSubscription
}) => {
  const { i18n } = useLingui()
  return (
    <p
      id="account_status"
      className={classnames(styles.plan, {
        [styles.isStarter]: !isPro && !isPremium,
        [styles.isPremium]: isPremium && !isPro,
        [styles.isPro]: isPro,
        [styles.isGroup]: isGroupSubscription
      })}
    >
      {isPro
        ? i18n._(t`producer_plan_title`)
        : isPremium
        ? i18n._(t`pricing_plan_musician`)
        : i18n._(t`pricing_starter_title`)}
      {isGroupSubscription && ` ${i18n._(t`Group`)}`}
    </p>
  )
}

export const NavUser: React.FC<NavUserProps> = ({
  className,
  small,
  bannerCountdown,
  announcement,
  showDaysInCountdown = true
}) => {
  const ref: RefObject<any> = useRef()
  const { i18n } = useLingui()
  const { push, pathname } = useRouter()
  const { user } = useContext(ContextShell)
  const {
    modalReferralLink: { onOpen: onOpenReferralLinkModal },
    modalShortcutsMenu: { onOpen: onOpenModalShortcutsMenu },
    modalGetApp: { onOpen: onOpenModalGetApp }
  } = useStoreModals()
  const { group, isEnabledGroupPlan, isEnabledManagement } =
    useDataManageGroup()

  const [showNav, setShowNav] = useState(false)
  useOnClickOutside(ref, () => setShowNav(false))

  const active = useMemo(
    () =>
      showNav ||
      ['/settings', '/notifications/preferences', '/notifications'].includes(
        pathname
      ),
    [pathname, showNav]
  )

  const onOpenNav = useCallback(() => setShowNav(!showNav), [showNav])

  const onGoSettings = useCallback(() => {
    setShowNav(false)
    push('/settings')
  }, [push])

  const onGoNotifications = useCallback(() => {
    setShowNav(false)
    push('/notifications/preferences/')
  }, [push])

  const onGoPricing = useCallback(() => {
    setShowNav(false)
    push('/billing/pricing/?source=profile-settingsCTA')
  }, [push])

  const onGoSupport = useCallback(() => {
    setShowNav(false)
    window.open('/support', '_blank', 'noopener')
  }, [])

  const onGoWhatsNew = useCallback(() => {
    setShowNav(false)
    window.open(
      'https://moises.ai/blog/moises-news/improvements-latest-releases/',
      '_blank',
      'noopener'
    )
  }, [])

  const onGoGetApp = useCallback(() => {
    setShowNav(false)
    onOpenModalGetApp()
  }, [onOpenModalGetApp])

  const onGoGetDesktopApp = useCallback(() => {
    setShowNav(false)
    window.open('https://desktop.moises.ai/', '_blank', 'noopener')
  }, [])

  const onGoLogOut = useCallback(() => {
    setShowNav(false)
    trigger('purge:tasks')
    localStorage.removeItem('moises:userSortingPreference')
    localStorage.removeItem('moises:userFilterPreference')
    window.location.href = '/logout'
  }, [])

  const onGoKeyboardShortcuts = useCallback(() => {
    setShowNav(false)
    onOpenModalShortcutsMenu()
  }, [onOpenModalShortcutsMenu])

  const onGoRerralLink = useCallback(() => {
    setShowNav(false)
    onOpenReferralLinkModal()
  }, [onOpenReferralLinkModal])

  const onGoManageGroup = useCallback(() => {
    setShowNav(false)
    push('/group/manage')
  }, [push])

  const isPayingUser = useMemo(
    () => user?.subscription?.isPro || user?.subscription?.isPremium,
    [user]
  )

  const allowReferAFriend = useMemo(
    () =>
      isPayingUser &&
      user?.subscription?.details?.providerName !== 'trial' &&
      user?.subscription?.details?.autoRenew,
    [isPayingUser, user]
  )

  return (
    <div className={classnames(className, styles.container)}>
      <BannerCountdown
        {...(bannerCountdown as UseCampaignCountdown)}
        show={!small && !!bannerCountdown?.show}
        showDaysInCountdown={showDaysInCountdown}
      />

      <AnnouncementBanner
        {...(announcement as UseAnnouncement)}
        show={!small && !!announcement?.show}
      />

      <button
        id="user_info_button"
        type="button"
        onClick={small ? onGoSettings : onOpenNav}
        className={classnames(styles.button, {
          [styles.active]: active,
          [styles.small]: small
        })}
      >
        <UserAvatar
          user={user}
          // bagde={true}
        />

        {!small && (
          <div className={styles.user}>
            <p className={styles.title}>
              {(user && user.name) || (user && user.email)}
            </p>

            <UserPlan
              isPro={user?.subscription?.isPro}
              isPremium={user?.subscription?.isPremium}
              isGroupSubscription={isEnabledGroupPlan && !!group}
            />
          </div>
        )}
      </button>
      <div ref={ref}>
        <DropMenu
          className={classnames(styles.drop, {
            [styles.show]: showNav && !small
          })}
        >
          <ButtonIcon
            id="user_info_get_premium"
            light
            icon={<Icon name="star" />}
            title={i18n._(t`unlock_all_features`)}
            className={styles.buttonPremium}
            onClick={onGoPricing}
          />

          <ButtonIcon
            id="user_info_account_settings"
            light
            icon={<Icon name="settings" />}
            title={i18n._(t`header.nav.user.settings`)}
            onClick={onGoSettings}
          />

          {isEnabledManagement && (
            <ButtonIcon
              light
              icon={<Icon name="user-group-3" />}
              title={i18n._(t`manage_group_title`)}
              onClick={onGoManageGroup}
              id="manage_group_button"
            />
          )}

          {user?.featureFlags?.webNotification && (
            <ButtonIcon
              id="user_info_notification"
              light
              icon={<Icon name="notification" />}
              title={i18n._(t`notification_settings_title`)}
              onClick={onGoNotifications}
            />
          )}

          <ButtonIcon
            id="user_info_get_moises_app"
            light
            icon={<Icon name="smartphone" />}
            title={i18n._(t`actions.getapp`)}
            onClick={onGoGetApp}
          />

          <ButtonIcon
            id="user_info_get_desktop_app"
            light
            icon={<Icon name="computer" />}
            title={i18n._(t`get_desktop_app`)}
            onClick={onGoGetDesktopApp}
          />

          {allowReferAFriend && (
            <ButtonIcon
              id="refer_a_friend"
              light
              icon={<Icon name="invite-user" />}
              title={t`refer_friend_title`}
              onClick={onGoRerralLink}
            />
          )}

          <ButtonIcon
            id="user_info_keyboard_shortcuts"
            light
            icon={<Icon name="keyboard" />}
            title={i18n._(t`keyboard_shortcuts`)}
            onClick={onGoKeyboardShortcuts}
          />

          <ButtonIcon
            id="user_info_support"
            light
            icon={<Icon name="support" />}
            title={i18n._(t`label.support`)}
            onClick={onGoSupport}
          />

          <ButtonIcon
            id="user_info_whatsnew"
            light
            icon={<Icon name="gift-box" />}
            title="What's New?"
            onClick={onGoWhatsNew}
          />

          <ButtonIcon
            id="user_info_sign_out"
            light
            icon={<Icon name="logout" />}
            title={i18n._(t`header.nav.user.logout`)}
            onClick={onGoLogOut}
          />
        </DropMenu>
      </div>
    </div>
  )
}
