import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React, { useCallback, useContext, useState } from 'react'
import { ContextShell } from '../../context'
import { trigger } from '../../../lib/events'
import { ButtonIcon } from '../../../components/button-icon'
import { Icon } from '../../../components/icon'
import { useUpdatePlaylist } from '../../../hooks/playlist/use-update-playlist'
import styles from './edit-playlist.module.scss'
import { ModalEditPlaylist } from './modal-edit-playlist'

interface EditPlaylistProps {
  playlistId?: string
  title: string | null
  description: string | null
  onSilentRetryPlaylist?(): void
  events?: {
    [key: string]: string
  }
}

export const EditPlaylist: React.FC<EditPlaylistProps> = ({
  playlistId,
  title,
  description,
  events = {},
  onSilentRetryPlaylist
}) => {
  const { i18n } = useLingui()
  const {
    user,
    event: { sendEvent }
  } = useContext(ContextShell)
  const { updatePlaylist, loading } = useUpdatePlaylist()

  const [openedModal, setOpenedModal] = useState(false)
  const onOpenModal = useCallback(() => setOpenedModal(true), [])
  const onDismissModal = useCallback(() => setOpenedModal(false), [])

  const onUpdatePlaylist = useCallback(
    async (i: { title: string; description: string | null }) => {
      if (playlistId) {
        await updatePlaylist({
          name: i.title,
          description: i.description,
          playlistId
        })

        const editedAction = []
        if (title !== i.title) editedAction.push('title')
        if (description !== i.description) editedAction.push('description')

        sendEvent({
          userId: user?.id,
          name: 'playlist_edited',
          category: 'retention',
          customAttributes: {
            playlist_id: playlistId,
            timestamp: new Date().toISOString(),
            edited_action: editedAction.join(', '),
            ...events
          }
        })
      }
      onDismissModal()
      if (onSilentRetryPlaylist) {
        onSilentRetryPlaylist()
      }
      trigger('playlist:update-playlist')
    },
    [
      user?.id,
      events,
      title,
      description,
      playlistId,
      sendEvent,
      updatePlaylist,
      onDismissModal,
      onSilentRetryPlaylist
    ]
  )

  return (
    <div className={styles.container}>
      <ButtonIcon
        light
        onClick={onOpenModal}
        icon={<Icon name="edit" />}
        title={i18n._(t`playlist.options.edit`)}
      />

      {openedModal && (
        <ModalEditPlaylist
          loading={loading}
          open={openedModal}
          title={title}
          description={description}
          onDismiss={onDismissModal}
          onSave={onUpdatePlaylist}
        />
      )}
    </div>
  )
}
