import React from 'react'
import { IconProps } from './icons.types'

export const Card: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33325 8.33331H16.6666M2.49992 3.33331H17.4999C18.4204 3.33331 19.1666 4.07951 19.1666 4.99998V15C19.1666 15.9205 18.4204 16.6666 17.4999 16.6666H2.49992C1.57944 16.6666 0.833252 15.9205 0.833252 15V4.99998C0.833252 4.07951 1.57944 3.33331 2.49992 3.33331Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
