import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React, { useCallback, useState } from 'react'
import { Modal } from '../../../components/modals/modal'
import { FormInput } from '../../../components/form/form-input'
import { Button } from '../../../components/button'
import { Icon } from '../../../components/icon'
import { FormTextarea } from '../../../components/form/form-textarea'
import styles from './modal-edit-playlist.module.scss'

interface ModalEditPlaylistProps {
  open: boolean
  loading: boolean
  title: string | null
  description: string | null
  onDismiss(): void
  onSave(i: { title: string; description: string | null }): void
}

export const ModalEditPlaylist: React.FC<ModalEditPlaylistProps> = ({
  open,
  loading,
  title,
  description,
  onDismiss,
  onSave
}) => {
  const { i18n } = useLingui()
  const [newTitle, setNewTitle] = useState(title)
  const [newDescription, setNewDescription] = useState(description)

  const onChangeTitle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setNewTitle(e.target.value),
    []
  )
  const onChangeDescription = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) =>
      setNewDescription(e.target.value),
    []
  )

  const handleSave = useCallback(
    () =>
      onSave({
        title: newTitle || '',
        description: newDescription
      }),
    [newTitle, newDescription, onSave]
  )

  return (
    <Modal
      label="modal-playlist-edit"
      isOpen={open}
      onClose={onDismiss}
      targetId="shell-app"
      shouldCloseOnOverlayClick
      modalClassName={styles.modal}
    >
      <div className={styles.container}>
        <p className={styles.title}>{i18n._(t`playlist.options.edit`)}</p>

        <div className={styles.content}>
          <FormInput
            maxLength={80}
            value={newTitle || ''}
            error={!newTitle?.length}
            className={styles.input}
            onChange={onChangeTitle}
            placeholder={i18n._(t`label.title`)}
          />

          <FormTextarea
            maxLength={140}
            value={newDescription || ''}
            onChange={onChangeDescription}
            placeholder={i18n._(t`edit.playlist.short.description`)}
          />
        </div>

        <div className={styles.actions}>
          <Button
            small
            light
            stroke
            radius={1}
            transparent
            onClick={onDismiss}
            className={styles.button}
            title={i18n._(t`actions.cancel`)}
          />

          <Button
            small
            stroke
            radius={1}
            onClick={handleSave}
            className={styles.button}
            disabled={loading || !newTitle?.length}
            icon={
              loading ? (
                <Icon name="loader" animation="spin" />
              ) : (
                <Icon name="check-simple" />
              )
            }
            title={
              loading
                ? `${i18n._(t`label.loading`)}...`
                : i18n._(t`edit.playlist.save`)
            }
          />
        </div>
      </div>
    </Modal>
  )
}
