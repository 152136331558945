import React from 'react'
import { IconProps } from './icons.types'

export const Kickdrum: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.29373 11.5471C5.4058 10.5872 6.2748 9.89989 7.23468 10.012C8.19457 10.124 8.88185 10.993 8.76978 11.9529C8.65771 12.9128 7.78871 13.6001 6.82883 13.488C5.86894 13.3759 5.18165 12.5069 5.29373 11.5471Z"
      fill="currentColor"
    />
    <path
      d="M7.43674 1.40002C5.9721 1.40002 4.64821 2.58757 3.68938 4.50523C2.73055 6.42289 2.13678 9.07507 2.13678 11.9999C2.13678 13.2259 2.24109 14.4039 2.43288 15.5M7.43674 1.40002C8.90137 1.40002 10.2253 2.58757 11.1841 4.50523M7.43674 1.40002H16.5632C18.0278 1.40002 19.3517 2.58757 20.3106 4.50523M11.1841 4.50523C12.1429 6.42289 12.7367 9.07507 12.7367 11.9999M11.1841 4.50523H20.3106M12.7367 11.9999C12.7367 14.9248 12.1429 17.577 11.1841 19.4947M12.7367 11.9999H21.8632M11.1841 19.4947C10.2253 21.4123 8.90137 22.5999 7.43674 22.5999M11.1841 19.4947H20.3106M7.46754 22.5999H16.5632C18.0279 22.5999 19.3517 21.4123 20.3106 19.4947M20.3106 4.50523C21.2694 6.42289 21.8632 9.07507 21.8632 11.9999M21.8632 11.9999C21.8632 14.9248 21.2694 17.577 20.3106 19.4947M7.03172 11.76V17.4159C7.03172 17.8936 6.8607 18.3556 6.54961 18.7182L3.21924 22.5999H7.89412"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
