import { create } from 'zustand'
import axios from 'axios'
import { v4 } from 'uuid'
import { captureRequestError } from '../../../lib/misc/capture-request-error'
import { config } from '../../../config/index'
import {
  DownloadFormat,
  DownloadQueue,
  UseDownloadQueue
} from '../../../types/download-queue.types'

const MESSAGE_CANCEL_REQUEST = 'request cancel by user'

const delay = (milliseconds: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, milliseconds))

const onDownloadFile = async ({
  url,
  filename,
  format
}: {
  url: string
  filename: string
  format?: DownloadFormat
}): Promise<void> => {
  // Commented because mixer / transcode urls are reaching here
  // TODO The redirect param should work only for gs urls, mixer / transcode
  // urls should be all redirected
  // // Check connection and file availability
  // // await axios.head(url)

  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download =
    format !== 'mix'
      ? `${decodeURIComponent(filename)}.${format}`
      : `${decodeURIComponent(filename)}`
  document.body.append(a)
  a.click()
  await delay(3000)
  a.remove()
}

export const useStoreDownloadQueue = create<UseDownloadQueue>((set, get) => ({
  list: [],
  add: ({ url, title, filename, format, userToken }) => {
    const source = axios.CancelToken.source()
    const cancelToken = source.token
    get().fetchFile({ url, cancelToken, filename, format, userToken })

    set(() => ({
      list: [
        ...get().list,
        {
          url,
          title,
          loading: true,
          source
        }
      ]
    }))
  },
  addAll: ({
    format,
    packageName,
    file,
    name,
    bitrate,
    redirect,
    maxDuration,
    userToken
  }) => {
    const source = axios.CancelToken.source()
    const cancelToken = source.token
    get().fetchAllFiles({
      cancelToken,
      userToken,
      data: { format, packageName, file, name, bitrate, redirect, maxDuration }
    })

    set(() => ({
      list: [
        ...get().list,
        {
          url: config.transcode.endpoint,
          title: packageName,
          loading: true,
          source
        }
      ]
    }))
  },
  addMastering: (data) => {
    const { userToken, title, ...rest } = data
    const source = axios.CancelToken.source()
    const cancelToken = source.token
    const id = v4()
    get().fetchMastering({ data: rest, userToken, title, id, cancelToken })

    set(() => ({
      list: [
        ...get().list,
        {
          url: `${config.serviceHighRes.endpoint}/${id}`,
          title,
          loading: true,
          source
        }
      ]
    }))
  },
  remove: (url: string) => {
    const listModified = get().list
    const index = listModified.findIndex((i) => i && i.url === url)

    if (index >= 0) {
      // cancel download file
      listModified[index]?.source?.cancel(MESSAGE_CANCEL_REQUEST)

      // remove item from list
      listModified.splice(index, 1)
      set(() => ({ list: listModified }))
    }
  },
  clear: () => {
    get()
      .list.slice()
      .reverse()
      .forEach((i: DownloadQueue) => get().remove(i.url))

    set(() => ({ showModal: false }))
  },
  onError: ({ url, message }) => {
    // request cancel by user
    if (message === MESSAGE_CANCEL_REQUEST) {
      return
    }

    // show UI failed to request
    const listModified = get().list
    const index = listModified.findIndex((i) => i && i.url === url)

    if (index >= 0) {
      listModified[index] = {
        ...listModified[index],
        loading: false,
        success: false,
        failed: true
      }
      set(() => ({ list: listModified }))
    }
  },
  onSuccess: async ({ url, response, filename, format }) => {
    try {
      await onDownloadFile({
        url: response,
        filename,
        format
      })
    } catch (error) {
      get().onError({ url, message: '' })
      captureRequestError(
        error,
        'export',
        {
          failed: 'Download exported file failed',
          invalid: 'Download exported file failed due to invalid params'
        },
        {
          url,
          responseUrl: response,
          filename,
          format
        }
      )
      return
    }

    // show UI success to item
    const listModified = get().list
    const index = listModified.findIndex((i) => i && i.url === url)

    if (index >= 0) {
      listModified[index] = {
        ...listModified[index],
        loading: false,
        success: true,
        failed: false
      }
      set(() => ({ list: listModified }))

      // remove item from list
      setTimeout(() => get()?.remove(url), 3000)
    }
  },
  fetchFile: async ({ url, filename, cancelToken, format, userToken }) => {
    try {
      const { data } = await axios.get(url, {
        cancelToken,
        headers: {
          Authorization: userToken
        }
      })
      get().onSuccess({
        url,
        response: data.url,
        filename,
        format
      })
    } catch (error: any) {
      get().onError({ url, message: error.message })
      captureRequestError(
        error,
        'export',
        {
          failed: 'Export failed',
          invalid: 'Export failed due to invalid params'
        },
        {
          url,
          filename,
          format
        }
      )
    }
  },
  fetchAllFiles: async ({ cancelToken, userToken, data }) => {
    try {
      const response = await axios.post(config.transcode.endpoint, data, {
        headers: {
          Authorization: userToken,
          'Content-Type': 'application/json'
        },
        cancelToken
      })

      get().onSuccess({
        url: config.transcode.endpoint,
        response: response.data.url,
        filename: data.packageName,
        format: data.format
      })
    } catch (error: any) {
      get().onError({ url: config.transcode.endpoint, message: error.message })
      captureRequestError(
        error,
        'export',
        {
          failed: 'Export failed',
          invalid: 'Export failed due to invalid params'
        },
        {
          url: config.transcode.endpoint,
          filename: data.packageName,
          format: data.format
        }
      )
    }
  },
  fetchMastering: async ({ data, userToken, title, id, cancelToken }) => {
    try {
      const response = await axios.post(
        `${config.serviceHighRes.endpoint}/master/zip`,
        data,
        {
          headers: {
            Authorization: userToken,
            'Content-Type': 'application/json'
          },
          cancelToken
        }
      )
      get().onSuccess({
        url: `${config.serviceHighRes.endpoint}/${id}`,
        response: response.data.url,
        filename: title
      })
    } catch (error: any) {
      get().onError({ url: config.transcode.endpoint, message: error.message })
      captureRequestError(error, 'export', {
        failed: 'Export failed',
        invalid: 'Export failed due to invalid params'
      })
    }
  },
  showModal: false,
  onOpenModal: () => {
    const hasItemLoading = get().list.filter((i: DownloadQueue) => i.loading)

    if (hasItemLoading.length) {
      set(() => ({ showModal: true }))
    } else {
      get().clear()
    }
  },
  onDismissModal: () => {
    set(() => ({ showModal: false }))
  }
}))
