import React from 'react'
import { IconProps } from './icons.types'

export const Trash: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.11612 3.11612C9.35054 2.8817 9.66848 2.75 10 2.75H14C14.3315 2.75 14.6495 2.8817 14.8839 3.11612C15.1183 3.35054 15.25 3.66848 15.25 4V5.25H8.75V4C8.75 3.66848 8.8817 3.35054 9.11612 3.11612ZM7.25 5.25V4C7.25 3.27065 7.53973 2.57118 8.05546 2.05546C8.57118 1.53973 9.27065 1.25 10 1.25H14C14.7293 1.25 15.4288 1.53973 15.9445 2.05546C16.4603 2.57118 16.75 3.27065 16.75 4V5.25H19H21C21.4142 5.25 21.75 5.58579 21.75 6C21.75 6.41421 21.4142 6.75 21 6.75H19.75V20C19.75 20.7293 19.4603 21.4288 18.9445 21.9445C18.4288 22.4603 17.7293 22.75 17 22.75H7C6.27065 22.75 5.57118 22.4603 5.05546 21.9445C4.53973 21.4288 4.25 20.7293 4.25 20V6.75H3C2.58579 6.75 2.25 6.41421 2.25 6C2.25 5.58579 2.58579 5.25 3 5.25H5H7.25ZM5.75 6.75V20C5.75 20.3315 5.8817 20.6495 6.11612 20.8839C6.35054 21.1183 6.66848 21.25 7 21.25H17C17.3315 21.25 17.6495 21.1183 17.8839 20.8839C18.1183 20.6495 18.25 20.3315 18.25 20V6.75H16H8H5.75Z"
      fill="currentColor"
    />
  </svg>
)
