import React from 'react'
import { IconProps } from './icons.types'

export const NoMusic: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.4848 2.42774C21.653 2.57024 21.75 2.77954 21.75 3.00001V16C21.75 17.634 20.7054 19.022 19.2499 19.5364C18.8594 19.6745 18.4309 19.4698 18.2929 19.0792C18.1548 18.6887 18.3595 18.2602 18.7501 18.1222C19.625 17.8129 20.25 16.9785 20.25 16C20.25 14.7574 19.2426 13.75 18 13.75C17.0215 13.75 16.1871 14.375 15.8778 15.2499C15.7398 15.6405 15.3113 15.8452 14.9208 15.7071C14.5302 15.5691 14.3255 15.1406 14.4636 14.7501C14.978 13.2946 16.3661 12.25 18 12.25C18.8442 12.25 19.6233 12.529 20.25 12.9997V3.88535L9.75 5.63535V9.00001C9.75 9.41422 9.41421 9.75001 9 9.75001C8.58579 9.75001 8.25 9.41422 8.25 9.00001V5.00001C8.25 4.63338 8.51506 4.32048 8.8767 4.26021L20.8767 2.26021C21.0942 2.22397 21.3166 2.28524 21.4848 2.42774ZM9 13.25C9.41421 13.25 9.75 13.5858 9.75 14V18C9.75 20.0711 8.07107 21.75 6 21.75C3.92893 21.75 2.25 20.0711 2.25 18C2.25 15.9289 3.92893 14.25 6 14.25C6.8442 14.25 7.62325 14.529 8.25 14.9997V14C8.25 13.5858 8.58579 13.25 9 13.25ZM6 15.75C7.24264 15.75 8.25 16.7574 8.25 18C8.25 19.2426 7.24264 20.25 6 20.25C4.75736 20.25 3.75 19.2426 3.75 18C3.75 16.7574 4.75736 15.75 6 15.75Z"
      fill="currentColor"
    />
    <path
      d="M1 1L23 23"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
