import classnames from 'classnames'
import React, { useCallback } from 'react'
import styles from './modal-confirmation.module.scss'
import { Modal } from '../modal/modal'
import { Button } from '../../button'
import { Icon } from '../../icon'

export interface ModalConfirmation {
  isOpen?: boolean
  loading?: boolean
  title: string
  info?: string | React.ReactNode
  disclaimer?: string
  image?: string
  showBackground?: boolean
  badge?: React.ReactNode
  danger?: boolean
  confirmIcon?: string
  confirmLabel: string
  cancelLabel?: string
  loadingLabel?: string
  onConfirm(i?: any): void
  onDismiss?(): void
  onClose?(): void
  showCloseButton?: boolean
  shouldCloseOnOverlayClick?: boolean
}

export const ModalConfirmation: React.FC<ModalConfirmation> = ({
  isOpen,
  title,
  info,
  disclaimer,
  image,
  showBackground,
  badge,
  danger,
  loading = false,
  loadingLabel = 'Loading',
  confirmIcon,
  confirmLabel,
  cancelLabel,
  onConfirm,
  onDismiss,
  onClose,
  // showCloseButton = false,
  shouldCloseOnOverlayClick = true
}) => {
  const handleDismiss = useCallback(() => {
    if (!loading) onDismiss?.()
  }, [loading, onDismiss])

  return (
    <Modal
      targetId="shell-app"
      label={title}
      isOpen={isOpen || false}
      onClose={onClose || handleDismiss}
      showCloseButton
      modalClassName={styles.modal}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick && !loading}
    >
      <div
        className={classnames(styles.content, {
          [styles.background]: showBackground
        })}
      >
        {badge && <div className={styles.badge}>{badge}</div>}

        {image && (
          <img
            alt=""
            className={styles.image}
            src={`//storage.googleapis.com/scala-web/assets/images/${image}.svg`}
            width={showBackground ? '80' : '100'}
            height={showBackground ? '110' : '100'}
            style={{ top: showBackground ? '' : '-10px' }}
          />
        )}

        <p className={styles.title}>{title}</p>

        {info && <p className={styles.info}>{info}</p>}

        {disclaimer ? <p className={styles.disclaimer}>{disclaimer}</p> : null}

        <div className={styles.actions}>
          <Button
            small
            danger={danger}
            radius={1}
            disabled={loading}
            onClick={onConfirm}
            className={styles.button}
            id="modal_button_confirm"
            title={loading ? loadingLabel : confirmLabel}
            icon={
              loading ? (
                <Icon name="loader" animation="spin" width={22} height={22} />
              ) : confirmIcon ? (
                <Icon name={confirmIcon} width={22} height={22} />
              ) : undefined
            }
          />
          {cancelLabel && (
            <Button
              small
              light
              stroke
              radius={1}
              transparent
              disabled={loading}
              title={cancelLabel}
              id="modal_button_dismiss"
              className={classnames(styles.cancel, styles.button)}
              onClick={handleDismiss}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}
