import React from 'react'
import { IconProps } from './icons.types'

export const Goal: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 10.75C11.3096 10.75 10.75 11.3096 10.75 12C10.75 12.6904 11.3096 13.25 12 13.25C12.6904 13.25 13.25 12.6904 13.25 12C13.25 11.3096 12.6904 10.75 12 10.75Z"
      stroke="currentColor"
      strokeWidth="2.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12C2.75 10.5014 3.10579 9.08814 3.73683 7.83796C3.92348 7.46818 3.77503 7.01711 3.40525 6.83046C3.03547 6.64381 2.5844 6.79227 2.39775 7.16204C1.66332 8.61704 1.25 10.2613 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25C9.0704 1.25 6.41323 2.42288 4.47497 4.32299L5.52503 5.39414C7.19427 3.75776 9.4787 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.25 12C17.25 14.8995 14.8995 17.25 12 17.25C9.1005 17.25 6.75 14.8995 6.75 12C6.75 11.5456 6.80754 11.1058 6.91535 10.6869C7.0186 10.2858 6.77711 9.87692 6.37597 9.77367C5.97483 9.67043 5.56595 9.91192 5.4627 10.3131C5.32372 10.853 5.25 11.4185 5.25 12C5.25 15.7279 8.27208 18.75 12 18.75C15.7279 18.75 18.75 15.7279 18.75 12C18.75 10.101 17.9648 8.38405 16.7033 7.1584C15.4885 5.97803 13.8285 5.25 12 5.25C10.1184 5.25 8.4154 6.02091 7.19209 7.26223L8.26047 8.31511C9.21346 7.34809 10.5362 6.75 12 6.75C13.4227 6.75 14.7119 7.31488 15.6581 8.23423C16.6409 9.1891 17.25 10.5228 17.25 12Z"
      fill="currentColor"
    />
    <path d="M12 12L3.5 3.5" stroke="currentColor" strokeWidth="1.5" />
    <path
      d="M4 1L4 4L1 4"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
