import React from 'react'
import { IconProps } from './icons.types'

export const Loader: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 2C12.75 1.58579 12.4142 1.25 12 1.25C11.5858 1.25 11.25 1.58579 11.25 2V6C11.25 6.41421 11.5858 6.75 12 6.75C12.4142 6.75 12.75 6.41421 12.75 6V2ZM12.75 18C12.75 17.5858 12.4142 17.25 12 17.25C11.5858 17.25 11.25 17.5858 11.25 18V22C11.25 22.4142 11.5858 22.75 12 22.75C12.4142 22.75 12.75 22.4142 12.75 22V18ZM4.39966 4.39966C4.69256 4.10677 5.16743 4.10677 5.46032 4.39966L8.29032 7.22966C8.58322 7.52256 8.58322 7.99743 8.29032 8.29032C7.99743 8.58322 7.52256 8.58322 7.22966 8.29032L4.39966 5.46032C4.10677 5.16743 4.10677 4.69256 4.39966 4.39966ZM16.7703 15.7097C16.4774 15.4168 16.0026 15.4168 15.7097 15.7097C15.4168 16.0026 15.4168 16.4774 15.7097 16.7703L18.5397 19.6003C18.8326 19.8932 19.3074 19.8932 19.6003 19.6003C19.8932 19.3074 19.8932 18.8326 19.6003 18.5397L16.7703 15.7097ZM1.25 12C1.25 11.5858 1.58579 11.25 2 11.25H6C6.41421 11.25 6.75 11.5858 6.75 12C6.75 12.4142 6.41421 12.75 6 12.75H2C1.58579 12.75 1.25 12.4142 1.25 12ZM18 11.25C17.5858 11.25 17.25 11.5858 17.25 12C17.25 12.4142 17.5858 12.75 18 12.75H22C22.4142 12.75 22.75 12.4142 22.75 12C22.75 11.5858 22.4142 11.25 22 11.25H18ZM8.29032 15.7097C8.58322 16.0026 8.58322 16.4774 8.29032 16.7703L5.46032 19.6003C5.16743 19.8932 4.69256 19.8932 4.39966 19.6003C4.10677 19.3074 4.10677 18.8326 4.39966 18.5397L7.22966 15.7097C7.52256 15.4168 7.99743 15.4168 8.29032 15.7097ZM19.6003 5.46032C19.8932 5.16743 19.8932 4.69256 19.6003 4.39966C19.3074 4.10677 18.8326 4.10677 18.5397 4.39966L15.7097 7.22966C15.4168 7.52256 15.4168 7.99743 15.7097 8.29032C16.0026 8.58322 16.4774 8.58322 16.7703 8.29032L19.6003 5.46032Z"
      fill="currentColor"
    />
  </svg>
)
