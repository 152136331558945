import React from 'react'
import { IconProps } from './icons.types'

export const Soundeffects: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.125 3.25V7.0625M17.0312 5.15625H13.2188M9.75 5L4.75 9H0.75V15H4.75L9.75 19V5ZM23.0875 13.9857C20.475 13.9847 18.3556 11.8695 18.3394 9.25415C18.3232 11.8665 16.2084 13.9799 13.6 13.9857C16.2178 13.9915 18.3383 16.1202 18.3393 18.7456C18.3402 16.1172 20.4656 13.9866 23.0875 13.9857Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
