import React from 'react'
import { IconProps } from './icons.types'

export const Soundtrack: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0312 16H4C2.89543 16 2 15.1046 2 14V3C2 1.89543 2.89543 1 4 1H20C21.1046 1 22 1.89543 22 3V6.375M15.1669 19.0693V21.4005C15.1669 21.964 14.7874 22.4568 14.2426 22.6008L12.9087 22.9533C12.1212 23.1614 11.35 22.5676 11.35 21.753V21.034C11.35 20.4705 11.7295 19.9777 12.2743 19.8337L15.1669 19.0693ZM15.1669 19.0693V12.9011C15.1669 12.6202 15.3556 12.3743 15.6269 12.3015L22.3811 10.4915C22.7753 10.3858 23.1625 10.6829 23.1625 11.091V16.9161M23.1625 16.9161V19.2577C23.1625 19.8212 22.783 20.314 22.2382 20.458L20.9036 20.8106C20.1161 21.0187 19.345 20.4248 19.345 19.6103V18.8956C19.345 18.3342 19.7216 17.8428 20.2636 17.6968L23.1625 16.9161ZM9.42978 5.73982L9.42978 11.26C9.42978 11.3963 9.57749 11.4815 9.69566 11.4133L14.4816 8.65323C14.5998 8.58508 14.5998 8.41471 14.4816 8.34656L9.69566 5.58649C9.57749 5.51834 9.42978 5.60352 9.42978 5.73982Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)
