import React from 'react'
import { IconProps } from './icons.types'

export const SocialX: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.257 2.40895H21.5904L14.3078 10.5351L22.8769 21.5961H16.1664L10.9087 14.8881L4.89632 21.5961H1.56299L9.35355 12.8936L1.12988 2.40002H8.01035L12.7618 8.52991L18.257 2.40895ZM17.0874 19.6461H18.935L7.01071 4.25362H5.0279L17.0874 19.6461Z"
      fill="white"
    />
  </svg>
)
