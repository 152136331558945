import React from 'react'
import { IconProps } from './icons.types'

export const Support: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 11H6C6.55228 11 7 11.4477 7 12V16C7 16.5523 6.55228 17 6 17H4C3.44772 17 3 16.5523 3 16V11Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M17 12C17 11.4477 17.4477 11 18 11H21V16C21 16.5523 20.5523 17 20 17H18C17.4477 17 17 16.5523 17 16V12Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M21 11V10C21 5.02944 16.9706 1 12 1V1C7.02944 1 3 5.02944 3 10V11"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M21.75 15C21.75 14.5858 21.4142 14.25 21 14.25C20.5858 14.25 20.25 14.5858 20.25 15H21.75ZM11 22H10.25C10.25 22.4142 10.5858 22.75 11 22.75V22ZM11 20.5V19.75C10.5858 19.75 10.25 20.0858 10.25 20.5H11ZM14 20.5H14.75C14.75 20.0858 14.4142 19.75 14 19.75V20.5ZM20.25 15V19H21.75V15H20.25ZM11.75 22V20.5H10.25V22H11.75ZM11 21.25H14V19.75H11V21.25ZM18 21.25H14V22.75H18V21.25ZM14 21.25H11V22.75H14V21.25ZM13.25 20.5V22H14.75V20.5H13.25ZM20.25 19C20.25 20.2426 19.2426 21.25 18 21.25V22.75C20.0711 22.75 21.75 21.0711 21.75 19H20.25Z"
      fill="currentColor"
    />
  </svg>
)
