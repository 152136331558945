import React from 'react'
import { IconProps } from './icons.types'

export const ChordsDetection: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ic-chords-detection">
      <path
        id="Stroke"
        d="M19.5 22V22.75H21V22H19.5ZM3 22V22.75H4.5V22H3ZM14 22V22.75H15.5V22H14ZM5.75 2.75H18.25V1.25H5.75V2.75ZM19.5 4V22H21V4H19.5ZM4.5 22V4H3V22H4.5ZM18.25 2.75C18.9404 2.75 19.5 3.30964 19.5 4H21C21 2.48122 19.7688 1.25 18.25 1.25V2.75ZM5.75 1.25C4.23122 1.25 3 2.48122 3 4H4.5C4.5 3.30964 5.05964 2.75 5.75 2.75V1.25ZM10 19.2159L10 2L8.5 2L8.5 19.2159H10ZM15.5 22V2H14V22H15.5ZM3.75 15.25H20.25V13.75H3.75V15.25Z"
        fill="currentColor"
      />
      <g id="Fill">
        <path
          d="M11.25 20.75C11.25 21.8546 10.3546 22.75 9.25 22.75C8.14543 22.75 7.25 21.8546 7.25 20.75C7.25 19.6454 8.14543 18.75 9.25 18.75C10.3546 18.75 11.25 19.6454 11.25 20.75Z"
          fill="currentColor"
        />
        <path
          d="M16.75 8.25C16.75 9.35457 15.8546 10.25 14.75 10.25C13.6454 10.25 12.75 9.35457 12.75 8.25C12.75 7.14543 13.6454 6.25 14.75 6.25C15.8546 6.25 16.75 7.14543 16.75 8.25Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
)
