import React from 'react'
import { IconProps } from './icons.types'

export const KeyDown: React.FC<IconProps> = ({ width = 8, height = 10 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.6 5.8L3.8 9L7 5.8"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.7998 9L3.79981 1"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
