import React from 'react'
import { IconProps } from './icons.types'

export const Alert: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6506 2.50097C11.0625 2.26909 11.5271 2.14728 11.9998 2.14728C12.4724 2.14728 12.9371 2.26909 13.3489 2.50097C13.7608 2.73284 14.1059 3.06695 14.351 3.47105L14.3532 3.47461L14.3532 3.47462L22.8232 17.6146L22.8294 17.6249L22.8293 17.625C23.0694 18.0408 23.1964 18.5123 23.1978 18.9924C23.1991 19.4726 23.0747 19.9448 22.8369 20.3619C22.5991 20.7791 22.2563 21.1268 21.8424 21.3703C21.4286 21.6138 20.9582 21.7447 20.478 21.75L20.4698 21.7501V21.75H3.52978H3.52153L3.52153 21.75C3.04138 21.7447 2.57098 21.6138 2.15713 21.3703C1.74328 21.1268 1.40041 20.7791 1.16262 20.3619C0.924834 19.9448 0.800424 19.4726 0.801769 18.9924C0.803113 18.5123 0.930165 18.0408 1.17028 17.625L1.17629 17.6146L1.17638 17.6146L9.64638 3.47462L10.2898 3.86002L9.64853 3.47105C9.89365 3.06695 10.2388 2.73284 10.6506 2.50097ZM10.932 4.24743L10.931 4.24899L2.46662 18.3797C2.35919 18.5676 2.30237 18.7802 2.30176 18.9966C2.30115 19.2149 2.3577 19.4295 2.46579 19.6191C2.57387 19.8088 2.72972 19.9668 2.91784 20.0775C3.1049 20.1875 3.31737 20.247 3.53435 20.25H20.4652C20.6822 20.247 20.8947 20.1875 21.0817 20.0775C21.2698 19.9668 21.4257 19.8088 21.5338 19.6191C21.6419 19.4295 21.6984 19.2149 21.6978 18.9966C21.6972 18.7802 21.6404 18.5676 21.5329 18.3797L13.0685 4.24899L13.0676 4.24743C12.9562 4.06445 12.7997 3.91314 12.613 3.80805C12.4258 3.70265 12.2146 3.64728 11.9998 3.64728C11.7849 3.64728 11.5737 3.70265 11.3865 3.80805C11.1999 3.91314 11.0433 4.06445 10.932 4.24743ZM11.9998 8.25002C12.414 8.25002 12.7498 8.5858 12.7498 9.00002V13C12.7498 13.4142 12.414 13.75 11.9998 13.75C11.5856 13.75 11.2498 13.4142 11.2498 13V9.00002C11.2498 8.5858 11.5856 8.25002 11.9998 8.25002ZM11.9998 16.25C11.5856 16.25 11.2498 16.5858 11.2498 17C11.2498 17.4142 11.5856 17.75 11.9998 17.75H12.0098C12.424 17.75 12.7598 17.4142 12.7598 17C12.7598 16.5858 12.424 16.25 12.0098 16.25H11.9998Z"
      fill="currentColor"
    />
  </svg>
)
