import classnames from 'classnames'
import React from 'react'
import { Icon } from '../../components/icon'
import styles from './item-queue.module.scss'

interface ItemQueueProps {
  url: string
  title: string
  loading?: boolean
  failed?: boolean
  success?: boolean
  canceled?: boolean
  onRemove(url: string): void
}

export const ItemQueue: React.FC<ItemQueueProps> = ({
  url,
  title,
  loading,
  failed,
  success,
  canceled,
  onRemove
}) => (
  <div
    className={classnames(styles.container, {
      [styles.failed]: failed || canceled,
      [styles.success]: success,
      [styles.loading]: loading
    })}
  >
    <p className={styles.title}>{title}</p>

    {(loading || failed) && (
      <div className={styles.state}>
        {loading && (
          <Icon name="loader" animation="spin" className={styles.iconLoading} />
        )}

        <button
          type="button"
          className={styles.buttonCancel}
          onClick={onRemove.bind(this, url)}
          aria-label="Close"
        >
          <Icon name="close" />
        </button>
      </div>
    )}

    {canceled && (
      <div className={styles.state}>
        <span>Canceled</span>
      </div>
    )}

    {failed && (
      <div className={classnames(styles.state, styles.failed)}>
        <span>Failed</span>
      </div>
    )}

    {success && (
      <div className={styles.state}>
        <span>Finished</span>
        <Icon name="check-simple" className={styles.icon} />
      </div>
    )}
  </div>
)
