import React from 'react'
import { IconProps } from './icons.types'

export const KeyEnter: React.FC<IconProps> = ({ width = 16, height = 10 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.2 2.65005L1 5.85005L4.2 9.05005"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 5.95007L15 5.95007L15 0.950073"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
