import React from 'react'
import { IconProps } from './icons.types'

export const Refresh: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0001 2.25C11.5859 2.25 11.2501 2.58579 11.2501 3C11.2501 3.41421 11.5859 3.75 12.0001 3.75C16.5564 3.75 20.2501 7.44365 20.2501 12C20.2501 16.5563 16.5564 20.25 12.0001 20.25C7.44374 20.25 3.75009 16.5563 3.75009 12C3.75009 9.59105 4.78198 7.42315 6.43008 5.91404V9.00002C6.43008 9.41424 6.76586 9.75002 7.18008 9.75002C7.59429 9.75002 7.93008 9.41424 7.93008 9.00002V4.40002C7.93008 3.98581 7.59429 3.65002 7.18008 3.65002H2.58008C2.16586 3.65002 1.83008 3.98581 1.83008 4.40002C1.83008 4.81424 2.16586 5.15002 2.58008 5.15002H5.06184C3.32407 6.91018 2.25009 9.32969 2.25009 12C2.25009 17.3848 6.61531 21.75 12.0001 21.75C17.3849 21.75 21.7501 17.3848 21.7501 12C21.7501 6.61522 17.3849 2.25 12.0001 2.25Z"
      fill="currentColor"
    />
  </svg>
)
