import React from 'react'
import { IconProps } from './icons.types'

export const InstrumentBass: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.7393 18.1901L12.4462 3.29449C12.4462 3.29449 12.735 2.51961 13.0493 2.15444C13.2538 1.91699 13.5056 1.66853 13.8342 1.47794C14.5214 1.0794 15.7853 0.746635 17.2649 1.26747C18.6493 1.75488 19.3412 3.02352 19.4678 4.01167C19.6089 5.11319 19.2735 5.98735 18.7532 6.56398C18.2732 7.09599 17.7637 7.61178 17.4796 8.22417C17.1753 8.88014 17.3191 9.75019 17.4581 10.3914C17.8232 12.075 18.2098 13.4828 17.2649 15.65C16.6344 17.0961 15.8904 18.874 15.8045 20.7009L15.8044 23H10.6839C10.6839 23 10.6645 22.0829 10.4541 21.68C10.3444 21.4701 10.1503 21.1795 9.62858 20.9994C8.81916 20.7199 7.93218 20.5312 7.67444 19.7252C7.55596 19.3548 7.52994 18.8393 7.7393 18.1901Z"
      stroke="currentColor"
      strokeWidth="1.375"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.052 3.11892C10.274 2.39271 9.86527 1.62402 9.13906 1.402C8.41285 1.17998 7.64416 1.5887 7.42214 2.31491C7.20012 3.04112 7.60884 3.80981 8.33505 4.03184C9.06126 4.25386 9.82996 3.84513 10.052 3.11892ZM7.79922 5.78507C8.52543 6.00709 8.93416 6.77578 8.71213 7.50199C8.49011 8.2282 7.72142 8.63693 6.99521 8.41491C6.269 8.19289 5.86027 7.42419 6.08229 6.69798C6.30432 5.97177 7.07301 5.56305 7.79922 5.78507ZM7.3718 11.885C7.59382 11.1588 7.1851 10.3901 6.45889 10.1681C5.73268 9.94609 4.96398 10.3548 4.74196 11.081C4.51994 11.8072 4.92867 12.5759 5.65488 12.7979C6.38109 13.02 7.14978 12.6112 7.3718 11.885ZM6.03196 16.2681C6.25398 15.5419 5.84525 14.7732 5.11904 14.5512C4.39283 14.3292 3.62414 14.7379 3.40212 15.4641C3.1801 16.1903 3.58882 16.959 4.31503 17.181C5.04124 17.4031 5.80994 16.9943 6.03196 16.2681Z"
      fill="currentColor"
    />
  </svg>
)
