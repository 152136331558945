import React from 'react'
import { IconProps } from './icons.types'

export const Spliter: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5339 2.47323C21.8248 2.76808 21.8216 3.24294 21.5268 3.53386L15.6007 9.38116C14.7118 10.2582 13.5132 10.75 12.2645 10.75L3 10.75C2.58579 10.75 2.25 10.4142 2.25 10C2.25 9.58579 2.58579 9.25 3 9.25L12.2645 9.25C13.1189 9.25 13.9389 8.91353 14.5471 8.31343L20.4732 2.46613C20.7681 2.1752 21.2429 2.17838 21.5339 2.47323Z"
      fill="currentColor"
    />
    <path
      d="M16 2.25C15.5858 2.25 15.25 2.58579 15.25 3C15.25 3.41421 15.5858 3.75 16 3.75V2.25ZM21 3H21.75C21.75 2.58579 21.4142 2.25 21 2.25V3ZM20.25 8C20.25 8.41421 20.5858 8.75 21 8.75C21.4142 8.75 21.75 8.41421 21.75 8H20.25ZM16 3.75H21V2.25H16V3.75ZM20.25 3V8H21.75V3H20.25Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5339 21.5268C21.8248 21.2319 21.8216 20.7571 21.5268 20.4661L15.6007 14.6188C14.7118 13.7418 13.5132 13.25 12.2645 13.25L3 13.25C2.58579 13.25 2.25 13.5858 2.25 14C2.25 14.4142 2.58579 14.75 3 14.75L12.2645 14.75C13.1189 14.75 13.9389 15.0865 14.5471 15.6866L20.4732 21.5339C20.7681 21.8248 21.2429 21.8216 21.5339 21.5268Z"
      fill="currentColor"
    />
    <path
      d="M16 21.75C15.5858 21.75 15.25 21.4142 15.25 21C15.25 20.5858 15.5858 20.25 16 20.25V21.75ZM21 21H21.75C21.75 21.4142 21.4142 21.75 21 21.75V21ZM20.25 16C20.25 15.5858 20.5858 15.25 21 15.25C21.4142 15.25 21.75 15.5858 21.75 16H20.25ZM16 20.25H21V21.75H16V20.25ZM20.25 21V16H21.75V21H20.25Z"
      fill="currentColor"
    />
  </svg>
)
