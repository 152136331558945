import React from 'react'
import { IconProps } from './icons.types'

export const Maximize: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 2.25C14.5858 2.25 14.25 2.58579 14.25 3C14.25 3.41421 14.5858 3.75 15 3.75H19.1893L13.4697 9.46967C13.1768 9.76256 13.1768 10.2374 13.4697 10.5303C13.7626 10.8232 14.2374 10.8232 14.5303 10.5303L20.25 4.81066V9C20.25 9.41421 20.5858 9.75 21 9.75C21.4142 9.75 21.75 9.41421 21.75 9V3C21.75 2.98706 21.7497 2.97419 21.749 2.96141C21.7446 2.87376 21.7251 2.79009 21.6931 2.71291C21.6565 2.62445 21.6022 2.54158 21.5303 2.46967C21.4584 2.39776 21.3755 2.34351 21.2871 2.30691C21.1987 2.27024 21.1017 2.25 21 2.25H15ZM3.75 15C3.75 14.5858 3.41421 14.25 3 14.25C2.58579 14.25 2.25 14.5858 2.25 15V21C2.25 21.1017 2.27024 21.1987 2.30691 21.2871C2.34351 21.3755 2.39776 21.4584 2.46967 21.5303C2.54158 21.6022 2.62445 21.6565 2.71291 21.6931C2.79009 21.7251 2.87376 21.7446 2.96141 21.749C2.97419 21.7497 2.98706 21.75 3 21.75H9C9.41421 21.75 9.75 21.4142 9.75 21C9.75 20.5858 9.41421 20.25 9 20.25H4.81066L10.5303 14.5303C10.8232 14.2374 10.8232 13.7626 10.5303 13.4697C10.2374 13.1768 9.76256 13.1768 9.46967 13.4697L3.75 19.1893V15Z"
      fill="currentColor"
    />
  </svg>
)
