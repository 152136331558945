import React from 'react'
import { IconProps } from './icons.types'

export const InviteUser: React.FC<IconProps> = ({
  width = 20,
  height = 20
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33333 12.2917H5.83333C4.94928 12.2917 4.10143 12.6428 3.47631 13.268C2.85119 13.8931 2.5 14.7409 2.5 15.625V17.2917M15 10.8333V17.5M11.6667 13.9583H18.3333M12.5 5.62499C12.5 7.46594 11.0076 8.95832 9.16667 8.95832C7.32572 8.95832 5.83333 7.46594 5.83333 5.62499C5.83333 3.78404 7.32572 2.29166 9.16667 2.29166C11.0076 2.29166 12.5 3.78404 12.5 5.62499Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
