import React from 'react'
import { IconProps } from './icons.types'

export const Menu: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.75 6.5C2.33579 6.5 2 6.83579 2 7.25C2 7.66421 2.33579 8 2.75 8H21.25C21.6642 8 22 7.66421 22 7.25C22 6.83579 21.6642 6.5 21.25 6.5H2.75ZM2.75 14.5C2.33579 14.5 2 14.8358 2 15.25C2 15.6642 2.33579 16 2.75 16H21.25C21.6642 16 22 15.6642 22 15.25C22 14.8358 21.6642 14.5 21.25 14.5H2.75Z"
      fill="currentColor"
    />
  </svg>
)
