import React from 'react'
import { IconProps } from './icons.types'

export const KeySpacebar: React.FC<IconProps> = ({
  width = 28,
  height = 7
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1V5C1 5.55228 1.44772 6 2 6H26C26.5523 6 27 5.55228 27 5V1"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
