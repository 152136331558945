import React from 'react'
import { IconProps } from './icons.types'

export const More: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1667 6C14.1667 7.10457 13.2712 8 12.1667 8C11.0621 8 10.1667 7.10457 10.1667 6C10.1667 4.89543 11.0621 4 12.1667 4C13.2712 4 14.1667 4.89543 14.1667 6ZM14.1667 12C14.1667 13.1046 13.2712 14 12.1667 14C11.0621 14 10.1667 13.1046 10.1667 12C10.1667 10.8954 11.0621 10 12.1667 10C13.2712 10 14.1667 10.8954 14.1667 12ZM12.1667 20C13.2712 20 14.1667 19.1046 14.1667 18C14.1667 16.8954 13.2712 16 12.1667 16C11.0621 16 10.1667 16.8954 10.1667 18C10.1667 19.1046 11.0621 20 12.1667 20Z"
      fill="currentColor"
    />
  </svg>
)
