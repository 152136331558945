/* eslint-disable consistent-return */
import { useEffect, useState } from 'react'

function getBrowserVisibilityProp(): any {
  if (typeof document?.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange'
  }
  if (typeof document?.msHidden !== 'undefined') {
    return 'msvisibilitychange'
  }
  if (typeof document?.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange'
  }
}

function getBrowserDocumentHiddenProp(): any {
  if (typeof document?.hidden !== 'undefined') {
    return 'hidden'
  }
  if (typeof document?.msHidden !== 'undefined') {
    return 'msHidden'
  }
  if (typeof document?.webkitHidden !== 'undefined') {
    return 'webkitHidden'
  }
}

function getIsDocumentHidden(): any {
  if (!global.document) return true
  return !document[getBrowserDocumentHiddenProp()]
}

export const usePageVisibility = (): boolean => {
  const [isVisible, setIsVisible] = useState(getIsDocumentHidden())
  const onVisibilityChange = (): any => setIsVisible(getIsDocumentHidden())

  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp()

    document.addEventListener(visibilityChange, onVisibilityChange, false)

    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange)
    }
  })

  return isVisible
}
