import classnames from 'classnames'
import React from 'react'
import styles from './tooltip.module.scss'

interface TooltipProps {
  id?: string
  className?: string
  theme?: 'light' | 'dark'
  title?: string | number
  description?: string
  children?: React.ReactNode
  top?: boolean
  left?: boolean
  right?: boolean
  bottom?: boolean
  bottomRight?: boolean
  topRight?: boolean
  nozzle?: boolean
}

export const Tooltip: React.FC<TooltipProps> = ({
  id,
  className,
  theme = 'light',
  title,
  description,
  children,
  top,
  left,
  right,
  bottom,
  bottomRight,
  topRight,
  nozzle = true
}) => (
  <div
    id={id}
    className={classnames(className, styles.tooltip, {
      [styles.dark]: theme === 'dark',
      [styles.light]: theme === 'light'
    })}
  >
    {title && <p className={styles.title}>{title}</p>}
    {description && <p className={styles.description}>{description}</p>}
    {children || null}

    <svg
      className={classnames(styles.tip, {
        [styles.dark]: theme === 'dark',
        [styles.top]:
          top || (!left && !right && !bottom && !bottomRight && !topRight),
        [styles.left]: left,
        [styles.right]: right,
        [styles.bottom]: bottom,
        [styles.bottomRight]: bottomRight,
        [styles.topRight]: topRight
      })}
      xmlns="http://www.w3.org/2000/svg"
      width={nozzle ? '25' : '0'}
      height={nozzle ? '8' : '0'}
      fill="none"
    >
      <g>
        <path
          d="M10.592 6.928l.051.117c.493 1.127 2.092 1.127 2.585 0A11.76 11.76 0 0 1 24.001 0H-.001c4.594 0 8.752 2.719 10.593 6.928z"
          fill={theme === 'dark' ? '#111111' : '#fff'}
        />
      </g>
      <defs>
        <filter
          x="-12.001"
          y="-12"
          width="48.002"
          height="31.891"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="6" />
          <feComposite in2="SourceAlpha" operator="in" />
          <feBlend in="SourceGraphic" />
        </filter>
      </defs>
    </svg>
  </div>
)

export const TooltipWrapper: React.FC<
  TooltipProps & { className?: string; label?: string }
> = ({ className, label, children, ...props }) => {
  return (
    <div className={classnames(styles.wrapper, className)}>
      {children}

      <Tooltip className={classnames(styles.tooltip)} {...props}>
        {label && <span className={styles.label}>{label}</span>}
      </Tooltip>
    </div>
  )
}
