import React from 'react'
import { IconProps } from './icons.types'

export const UserGroup3: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_16736_4602)">
      <path
        d="M15.2083 17.4999V15.9258C15.2083 15.0909 14.8791 14.2902 14.293 13.6998C13.707 13.1094 12.7038 12.7777 12.0833 12.7777H7.91667C7.08786 12.7777 6.29301 13.1094 5.70696 13.6998C5.12091 14.2902 4.79167 15.0909 4.79167 15.9258V17.4999M3.26746 13.5069C2.56664 13.5069 1.89452 13.7873 1.39896 14.2866C0.903401 14.7858 0.625 15.4629 0.625 16.1689V17.4999M4.45444 5.65488C3.40191 6.00562 2.64246 7.00486 2.64246 8.18279C2.64246 9.65299 3.82553 10.8448 5.28493 10.8448C5.5322 10.8448 5.77154 10.8106 5.99853 10.7466M16.7325 13.5068C17.4334 13.5068 18.1055 13.7873 18.601 14.2865C19.0966 14.7857 19.375 15.4628 19.375 16.1688V17.4999M15.5456 5.65483C16.5981 6.00557 17.3575 7.0048 17.3575 8.18273C17.3575 9.65294 16.1745 10.8448 14.7151 10.8448C14.4678 10.8448 14.2285 10.8106 14.0015 10.7466M13.125 6.4814C13.125 8.22007 11.7259 9.62955 10 9.62955C8.27411 9.62955 6.875 8.22007 6.875 6.4814C6.875 4.74273 8.27411 3.33325 10 3.33325C11.7259 3.33325 13.125 4.74273 13.125 6.4814Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_16736_4602">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
