import classnames from 'classnames'
import React from 'react'
import styles from './form-textarea.module.scss'

export interface TextareaProps {
  className?: string
  attributes?: any
  id?: string
  error?: boolean
  disabled?: boolean
  success?: boolean
  placeholder: string
  value: string
  maxLength?: number
  onChange(event: React.ChangeEvent<HTMLTextAreaElement>): void
  children?: React.ReactNode
}

export const FormTextarea: React.FC<TextareaProps> = ({
  className,
  id,
  disabled = false,
  error,
  success,
  value,
  onChange,
  maxLength,
  placeholder,
  attributes = {},
  children
}) => {
  return (
    <div className={classnames(className, styles.container)}>
      <div className={styles.textareaContent}>
        <textarea
          {...attributes}
          id={id}
          value={value}
          disabled={disabled}
          onChange={onChange}
          maxLength={maxLength || 500}
          placeholder={placeholder}
          className={classnames(styles.textarea, {
            [styles.error]: error,
            [styles.success]: success
          })}
        >
          {value}
        </textarea>
      </div>

      {children ? <div className={styles.content}>{children}</div> : null}
    </div>
  )
}
