import React from 'react'
import { IconProps } from './icons.types'

export const MusicAdd: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="6.5"
      y1="11.75"
      x2="6.5"
      y2="3.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="2.25"
      y1="7.5"
      x2="10.75"
      y2="7.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M21 12.9999C21 6.99994 15.3389 6.99994 15.3389 3.45193C15.3389 0.485743 15.3389 15.0597 15.3389 19.9193C15.3389 20.827 14.7276 21.6135 13.8499 21.8454L11.7009 22.4133C10.4323 22.7486 9.18994 21.7919 9.18994 20.4797V19.3213C9.18994 18.4135 9.80131 17.6196 10.6789 17.3877L15.3389 16.1562"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)
