import React from 'react'
import { IconProps } from './icons.types'

export const Chevron: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.45 8.4C4.11863 8.15147 3.64853 8.21863 3.4 8.55C3.15147 8.88137 3.21863 9.35147 3.55 9.6L4.45 8.4ZM12 15L11.55 15.6C11.8167 15.8 12.1833 15.8 12.45 15.6L12 15ZM20.45 9.6C20.7814 9.35147 20.8485 8.88137 20.6 8.55C20.3515 8.21863 19.8814 8.15147 19.55 8.4L20.45 9.6ZM3.55 9.6L11.55 15.6L12.45 14.4L4.45 8.4L3.55 9.6ZM12.45 15.6L20.45 9.6L19.55 8.4L11.55 14.4L12.45 15.6Z"
      fill="currentColor"
    />
  </svg>
)
