import React from 'react'
import { IconProps } from './icons.types'

export const Songwriting: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7661_11839)">
      <path
        d="M4.60308 10.6821V12.4704C4.60308 13.3262 4.02647 14.0746 3.19893 14.2929L2.79953 14.3982C1.80594 14.6603 0.833332 13.911 0.833332 12.8834C0.833332 12.1721 1.31259 11.55 2.00041 11.3686L4.60308 10.6821ZM4.60308 10.6821V3.52034L12.5 1.40808V6.25M15.4924 7.1541C15.6469 6.99956 15.8304 6.87698 16.0323 6.79335C16.2342 6.70971 16.4506 6.66667 16.6691 6.66667C16.8877 6.66667 17.1041 6.70971 17.306 6.79335C17.5079 6.87698 17.6914 6.99956 17.8459 7.1541C18.0004 7.30863 18.123 7.49209 18.2067 7.694C18.2903 7.89591 18.3333 8.11231 18.3333 8.33086C18.3333 8.5494 18.2903 8.76581 18.2067 8.96772C18.123 9.16963 18.0004 9.35309 17.8459 9.50762L9.90276 17.4508L6.66667 18.3333L7.54924 15.0972L15.4924 7.1541Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7661_11839">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
