import React from 'react'
import { IconProps } from './icons.types'

export const UserGroup: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4002 21.4999V20.0124C14.4002 19.2234 14.0868 18.4666 13.5289 17.9087C12.9709 17.3508 12.2142 17.0373 11.4252 17.0373H5.47506C4.68602 17.0373 3.92931 17.3508 3.37137 17.9087C2.81344 18.4666 2.5 19.2234 2.5 20.0124V21.4999M21.5001 15.8879V14.4004C21.5001 13.6113 21.1866 12.8546 20.6287 12.2967C20.0708 11.7387 19.3141 11.4253 18.525 11.4253H14.4853M11.4252 11.0872C11.4252 12.7303 10.0932 14.0623 8.45012 14.0623C6.80704 14.0623 5.47506 12.7303 5.47506 11.0872C5.47506 9.44414 6.80704 8.11216 8.45012 8.11216C10.0932 8.11216 11.4252 9.44414 11.4252 11.0872ZM18.525 5.47518C18.525 7.11826 17.193 8.45024 15.55 8.45024C13.9069 8.45024 12.5749 7.11826 12.5749 5.47518C12.5749 3.8321 13.9069 2.50012 15.55 2.50012C17.193 2.50012 18.525 3.8321 18.525 5.47518Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
