import React from 'react'
import { IconProps } from './icons.types'

export const SpatialAudioV2: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9167 17.5C15.9167 17.9142 16.2525 18.25 16.6667 18.25C17.0809 18.25 17.4167 17.9142 17.4167 17.5H15.9167ZM13.3333 12.5V11.75V12.5ZM6.66668 12.5V11.75V12.5ZM3.33334 15.8333H2.58334H3.33334ZM2.58334 17.5C2.58334 17.9142 2.91913 18.25 3.33334 18.25C3.74756 18.25 4.08334 17.9142 4.08334 17.5H2.58334ZM17.4167 17.5V15.8333H15.9167V17.5H17.4167ZM17.4167 15.8333C17.4167 14.7504 16.9865 13.7118 16.2207 12.946L15.16 14.0066C15.6445 14.4911 15.9167 15.1482 15.9167 15.8333H17.4167ZM16.2207 12.946C15.4549 12.1802 14.4163 11.75 13.3333 11.75V13.25C14.0185 13.25 14.6756 13.5222 15.16 14.0066L16.2207 12.946ZM13.3333 11.75H6.66668V13.25H13.3333V11.75ZM6.66668 11.75C5.58371 11.75 4.5451 12.1802 3.77932 12.946L4.83998 14.0066C5.32445 13.5222 5.98153 13.25 6.66668 13.25V11.75ZM3.77932 12.946C3.01355 13.7118 2.58334 14.7504 2.58334 15.8333H4.08334C4.08334 15.1482 4.35552 14.4911 4.83998 14.0066L3.77932 12.946ZM2.58334 15.8333V17.5H4.08334V15.8333H2.58334ZM12.5833 5.83333C12.5833 7.26007 11.4267 8.41667 10 8.41667V9.91667C12.2552 9.91667 14.0833 8.0885 14.0833 5.83333H12.5833ZM10 8.41667C8.57327 8.41667 7.41668 7.26007 7.41668 5.83333H5.91668C5.91668 8.0885 7.74485 9.91667 10 9.91667V8.41667ZM7.41668 5.83333C7.41668 4.4066 8.57327 3.25 10 3.25V1.75C7.74485 1.75 5.91668 3.57817 5.91668 5.83333H7.41668ZM10 3.25C11.4267 3.25 12.5833 4.4066 12.5833 5.83333H14.0833C14.0833 3.57817 12.2552 1.75 10 1.75V3.25Z"
      fill="currentColor"
    />
    <path
      d="M17.5 8.54166C17.5 8.88683 17.2202 9.16666 16.875 9.16666C16.5298 9.16666 16.25 8.88683 16.25 8.54166C16.25 8.19648 16.5298 7.91666 16.875 7.91666C17.2202 7.91666 17.5 8.19648 17.5 8.54166Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      rx="0.625"
      ry="0.625"
      transform="matrix(-1 0 0 1 3.125 8.54166)"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6667 1.875C16.6667 2.22018 16.3868 2.5 16.0417 2.5C15.6965 2.5 15.4167 2.22018 15.4167 1.875C15.4167 1.52982 15.6965 1.25 16.0417 1.25C16.3868 1.25 16.6667 1.52982 16.6667 1.875Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      rx="0.625"
      ry="0.625"
      transform="matrix(-1 0 0 1 3.95834 1.875)"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
