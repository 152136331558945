import React from 'react'
import { IconProps } from './icons.types'

export const EditSimple: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4729_57743)">
      <path
        d="M9.66699 4.33317L11.667 6.33317M10.7362 3.11215C10.8775 2.97087 11.0452 2.85879 11.2298 2.78232C11.4144 2.70586 11.6123 2.6665 11.8121 2.6665C12.0119 2.6665 12.2098 2.70586 12.3944 2.78232C12.579 2.85879 12.7467 2.97087 12.888 3.11215C13.0293 3.25344 13.1414 3.42118 13.2178 3.60578C13.2943 3.79038 13.3337 3.98824 13.3337 4.18805C13.3337 4.38786 13.2943 4.58572 13.2178 4.77032C13.1414 4.95492 13.0293 5.12266 12.888 5.26395L5.62571 12.5262L2.66699 13.3332L3.47391 10.3745L10.7362 3.11215Z"
        stroke="currentColor"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4729_57743">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
