import React from 'react'
import { IconProps } from './icons.types'

export const MusicNote: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.44912 12.6302V15.7661C9.44912 16.5226 8.93965 17.178 8.20828 17.3713L6.41745 17.8445C5.36024 18.1239 4.32495 17.3267 4.32495 16.2332V15.2678C4.32495 14.5113 4.83442 13.8497 5.56579 13.6565L9.44912 12.6302ZM9.44912 12.6302V2.04333C9.44912 5.00001 14.1667 5.00001 14.1667 9.99999"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)
