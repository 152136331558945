import React from 'react'
import { IconProps } from './icons.types'

export const Lock: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.9948 3.9948C9.79183 3.19777 10.8728 2.75 12 2.75C13.1272 2.75 14.2082 3.19777 15.0052 3.9948C15.8022 4.79183 16.25 5.87283 16.25 7V10.25H7.75V7C7.75 5.87283 8.19777 4.79183 8.9948 3.9948ZM6.25 10.25V7C6.25 5.47501 6.8558 4.01247 7.93414 2.93414C9.01247 1.8558 10.475 1.25 12 1.25C13.525 1.25 14.9875 1.8558 16.0659 2.93414C17.1442 4.01247 17.75 5.47501 17.75 7V10.25H19C20.5188 10.25 21.75 11.4812 21.75 13V20C21.75 21.5188 20.5188 22.75 19 22.75H5C3.48122 22.75 2.25 21.5188 2.25 20V13C2.25 11.4812 3.48122 10.25 5 10.25H6.25ZM17 11.75H7H5C4.30964 11.75 3.75 12.3096 3.75 13V20C3.75 20.6904 4.30964 21.25 5 21.25H19C19.6904 21.25 20.25 20.6904 20.25 20V13C20.25 12.3096 19.6904 11.75 19 11.75H17Z"
      fill="currentColor"
    />
  </svg>
)
