import React from 'react'
import { IconProps } from './icons.types'

export const NoFile: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.9111 3.43799H11.9111C10.8503 3.43799 9.83285 3.85942 9.08271 4.60956C8.33256 5.35971 7.91113 6.37712 7.91113 7.43799M27.9111 3.43799L39.9111 15.438M27.9111 3.43799V15.438H39.9111M32.875 43.438H11.9111C10.8503 43.438 9.83285 43.0166 9.08271 42.2664C8.33256 41.5163 7.91113 40.4989 7.91113 39.438V18.375M39.9111 39.438V15.438M4 4L42 42"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
