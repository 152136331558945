import React from 'react'
import { IconProps } from './icons.types'

export const Toms: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 20.5775V22.625M7.5393 10.0969V13.9775C7.5393 14.1359 7.49796 14.2915 7.41938 14.429L4.04879 20.5775C3.89163 20.8525 3.80896 21.1641 3.80896 21.4808V23.5M16.4606 10.0969L16.4606 13.9775C16.4606 14.1359 16.5019 14.2915 16.5805 14.429L19.9511 20.5775C20.1083 20.8525 20.1909 21.1638 20.1909 21.4806L20.1909 23.5M20.191 2.82022V14.9017C20.191 15.907 16.5237 17.177 12 17.177C7.4762 17.177 3.80896 15.907 3.80896 14.9017V2.82022M20.191 2.82022C20.191 3.82551 16.5237 4.64045 12 4.64045C7.4762 4.64045 3.80896 3.82551 3.80896 2.82022M20.191 2.82022C20.191 1.81494 16.5237 1 12 1C7.4762 1 3.80896 1.81494 3.80896 2.82022"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
