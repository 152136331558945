import React from 'react'
import { IconProps } from './icons.types'

export const Denoiser: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.057 1.93091C19.8942 4.3993 21.0039 9.51106 18.5355 13.3483C18.3114 13.6967 18.4122 14.1608 18.7605 14.3848C19.1089 14.6089 19.573 14.5082 19.7971 14.1598C22.7136 9.62584 21.4025 3.58595 16.8685 0.669374C16.5201 0.445284 16.056 0.546026 15.8319 0.894388C15.6079 1.24275 15.7086 1.70682 16.057 1.93091ZM10.2002 3.12222C13.5245 3.12222 16.2194 5.81712 16.2194 9.14145C16.2194 10.7939 15.5509 12.3389 14.3869 13.466C13.9921 13.8484 13.4973 14.302 12.9003 14.8283C12.0762 15.5547 11.604 16.6004 11.604 17.699L11.604 18.6799C11.604 20.4748 10.1489 21.9299 8.354 21.9299C6.55908 21.9299 5.104 20.4748 5.104 18.6799C5.104 18.2657 4.76822 17.9299 4.354 17.9299C3.93979 17.9299 3.604 18.2657 3.604 18.6799C3.604 21.3033 5.73065 23.4299 8.354 23.4299C10.9774 23.4299 13.104 21.3033 13.104 18.6799L13.104 17.699C13.104 17.031 13.3911 16.3952 13.8922 15.9535C14.5001 15.4176 15.0134 14.9474 15.4304 14.5436C16.883 13.137 17.7194 11.2045 17.7194 9.14145C17.7194 4.9887 14.3529 1.62222 10.2002 1.62222C6.0474 1.62222 2.68093 4.9887 2.68093 9.14145C2.68093 9.55567 3.01671 9.89145 3.43093 9.89145C3.84514 9.89145 4.18093 9.55567 4.18093 9.14145C4.18093 5.81712 6.87583 3.12222 10.2002 3.12222ZM9.96939 11.3722C8.86482 11.3722 7.96939 10.4768 7.96939 9.37222C7.96939 8.26765 8.86482 7.37222 9.96939 7.37222C11.074 7.37222 11.9694 8.26765 11.9694 9.37222C11.9694 10.4768 11.074 11.3722 9.96939 11.3722Z"
      fill="currentColor"
    />
  </svg>
)
