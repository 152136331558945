import React from 'react'
import { IconProps } from './icons.types'

export const Computer: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.75 16C14.75 15.5858 14.4142 15.25 14 15.25C13.5858 15.25 13.25 15.5858 13.25 16H14.75ZM13.25 21C13.25 21.4142 13.5858 21.75 14 21.75C14.4142 21.75 14.75 21.4142 14.75 21H13.25ZM9.25 21C9.25 21.4142 9.58579 21.75 10 21.75C10.4142 21.75 10.75 21.4142 10.75 21H9.25ZM10.75 16C10.75 15.5858 10.4142 15.25 10 15.25C9.58579 15.25 9.25 15.5858 9.25 16H10.75ZM18 21.75C18.4142 21.75 18.75 21.4142 18.75 21C18.75 20.5858 18.4142 20.25 18 20.25V21.75ZM6 20.25C5.58579 20.25 5.25 20.5858 5.25 21C5.25 21.4142 5.58579 21.75 6 21.75V20.25ZM3 3.75H21V2.25H3V3.75ZM21.25 4V15H22.75V4H21.25ZM21 15.25H3V16.75H21V15.25ZM2.75 15V4H1.25V15H2.75ZM3 15.25C2.86193 15.25 2.75 15.1381 2.75 15H1.25C1.25 15.9665 2.0335 16.75 3 16.75V15.25ZM21.25 15C21.25 15.1381 21.1381 15.25 21 15.25V16.75C21.9665 16.75 22.75 15.9665 22.75 15H21.25ZM21 3.75C21.1381 3.75 21.25 3.86193 21.25 4H22.75C22.75 3.0335 21.9665 2.25 21 2.25V3.75ZM3 2.25C2.0335 2.25 1.25 3.0335 1.25 4H2.75C2.75 3.86193 2.86193 3.75 3 3.75V2.25ZM13.25 16V21H14.75V16H13.25ZM10.75 21V16H9.25V21H10.75ZM18 20.25H6V21.75H18V20.25Z"
      fill="currentColor"
    />
  </svg>
)
