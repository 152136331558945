import React from 'react'
import { IconProps } from './icons.types'

export const Maracas: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8065 3.80936C11.9248 2.69104 15.1256 3.69178 17.717 6.28318C20.3084 8.87459 21.3091 12.0754 20.1908 13.1937M8.48832 8.00436C9.25653 5.79428 12.5253 6.81031 14.8575 9.14257C17.1898 11.4748 18.2058 14.7436 15.9958 15.5118M20.1233 13.261C18.5553 14.8291 16.5498 15.6434 14.626 15.671C12.7991 15.6972 10.7288 15.2562 9.45092 16.3938C8.59883 17.1524 7.86446 18.2065 7.2154 19.6571C6.04697 22.2685 3.57056 23.3019 2.18829 21.9196L2.0804 21.8118C0.698121 20.4295 1.73153 17.9531 4.34291 16.7846C5.79353 16.1356 6.84766 15.4012 7.60624 14.5491C8.74387 13.2712 8.30288 11.2009 8.32905 9.37404C8.35661 7.45028 9.17097 5.44476 10.739 3.87674C13.6839 0.931834 18.172 0.645266 20.7634 3.23667C23.3548 5.82808 23.0682 10.3161 20.1233 13.261Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
)
