import React from 'react'
import { IconProps } from './icons.types'

export const UserGroup4: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_17029_8315)">
      <path
        d="M15.7083 17.7004V16.1263C15.7083 15.2913 15.3791 14.4906 14.793 13.9002C14.207 13.3098 13.2038 12.9781 12.5833 12.9781H8.41667C7.58786 12.9781 6.79301 13.3098 6.20696 13.9002C5.62091 14.4906 5.29167 15.2913 5.29167 16.1263V17.7004M3.76746 13.7073C3.06664 13.7073 2.39452 13.9878 1.89896 14.487C1.4034 14.9862 1.125 15.6633 1.125 16.3693V17.7004M4.95444 5.85532C3.90191 6.20606 3.14246 7.2053 3.14246 8.38323C3.14246 9.85343 4.32553 11.0453 5.78493 11.0453C6.0322 11.0453 6.27154 11.0111 6.49853 10.9471M17.2325 13.7072C17.9334 13.7072 18.6055 13.9877 19.101 14.4869C19.5966 14.9862 19.875 15.6633 19.875 16.3693V17.7003M16.0456 5.85527C17.0981 6.20601 17.8575 7.20524 17.8575 8.38317C17.8575 9.85338 16.6745 11.0452 15.2151 11.0452C14.9678 11.0452 14.7285 11.011 14.5015 10.947M13.625 6.68184C13.625 8.42051 12.2259 9.82999 10.5 9.82999C8.77411 9.82999 7.375 8.42051 7.375 6.68184C7.375 4.94317 8.77411 3.53369 10.5 3.53369C12.2259 3.53369 13.625 4.94317 13.625 6.68184Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_17029_8315">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.5 0.200195)"
        />
      </clipPath>
    </defs>
  </svg>
)
