import React from 'react'
import { IconProps } from './icons.types'

export const Smiley: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 97 97"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.3749 37.8908V42.4377M60.6249 37.8908V42.4377M88.9166 48.5002C88.9166 70.8217 70.8214 88.9168 48.4999 88.9168C26.1784 88.9168 8.08325 70.8217 8.08325 48.5002C8.08325 26.1787 26.1784 8.0835 48.4999 8.0835C70.8214 8.0835 88.9166 26.1787 88.9166 48.5002Z"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66 68.7241C62.5136 62.6842 56.0696 58.6305 48.6966 58.6305C41.3236 58.6305 34.8796 62.6842 31.3932 68.7241"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
