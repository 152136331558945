import React from 'react'
import { IconProps } from './icons.types'

export const Notes: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.875 21H6.00003C4.89546 21 4.00003 20.1046 4.00003 19L4 4C4 2.89543 4.89543 2 6 2H18C19.1046 2 20 2.89543 20 4V6.875M8.00002 7H16M8.00002 12H12.625M19.9195 11.05C20.2716 10.6978 20.7492 10.5 21.2473 10.5C21.7453 10.5 22.2229 10.6978 22.575 11.05C22.9272 11.4021 23.125 11.8798 23.125 12.3778C23.125 12.8758 22.9272 13.3534 22.575 13.7055L14.1658 22.1148L10.625 23L11.5102 19.4593L19.9195 11.05Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
