import React from 'react'
import { IconProps } from './icons.types'

export const Keys: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.13 2H10.63M13.38 2H16.88M8.875 14L8.875 21.875M15.125 14V21.875M4.625 22H19.375C20.4796 22 21.375 21.1046 21.375 20V4C21.375 2.89543 20.4796 2 19.375 2H4.625C3.52043 2 2.625 2.89543 2.625 4V20C2.625 21.1046 3.52043 22 4.625 22Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M7.125 2.50391H10.625V14.0156H7.125V2.50391Z"
      fill="currentColor"
    />
    <path d="M13.37 2.50391H16.87V14.0156H13.37V2.50391Z" fill="currentColor" />
  </svg>
)
