import React from 'react'
import { IconProps } from './icons.types'

export const Smartphone: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.75 2.25H9C9 2.66421 9.33579 3 9.75 3V2.25ZM14.25 2.25V3C14.6642 3 15 2.66421 15 2.25H14.25ZM18.25 3V21H19.75V3H18.25ZM17 22.25H7V23.75H17V22.25ZM5.75 21V3H4.25V21H5.75ZM7 22.25C6.30964 22.25 5.75 21.6904 5.75 21H4.25C4.25 22.5188 5.48122 23.75 7 23.75V22.25ZM18.25 21C18.25 21.6904 17.6904 22.25 17 22.25V23.75C18.5188 23.75 19.75 22.5188 19.75 21H18.25ZM17 1.75C17.6904 1.75 18.25 2.30964 18.25 3H19.75C19.75 1.48122 18.5188 0.25 17 0.25V1.75ZM7 0.25C5.48122 0.25 4.25 1.48122 4.25 3H5.75C5.75 2.30964 6.30964 1.75 7 1.75V0.25ZM9.75 3H14.25V1.5H9.75V3ZM14.25 1.75H17V0.25H14.25V1.75ZM15 2.25V1H13.5V2.25H15ZM7 1.75H9.75V0.25H7V1.75ZM9.75 1.75H14.25V0.25H9.75V1.75ZM10.5 2.25V1H9V2.25H10.5Z"
      fill="currentColor"
    />
  </svg>
)
