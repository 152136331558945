import React from 'react'
import { IconProps } from './icons.types'

export const Crown: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.666656 4.39912L2.76189 13.6667H13.3167L15.3333 4.39912L11.1428 6.03031L7.99999 1.66667L4.85713 6.03031L0.666656 4.39912Z"
      stroke="#00DAE8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
