import React from 'react'
import { IconProps } from './icons.types'

export const Drag: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M0 6.75C0 6.33579 0.335786 6 0.75 6H7.25C7.66421 6 8 6.33579 8 6.75C8 7.16421 7.66421 7.5 7.25 7.5H0.75C0.335786 7.5 0 7.16421 0 6.75Z"
        fill="currentColor"
      />
      <path
        d="M0 11.75C0 11.3358 0.335786 11 0.75 11H7.25C7.66421 11 8 11.3358 8 11.75C8 12.1642 7.66421 12.5 7.25 12.5H0.75C0.335786 12.5 0 12.1642 0 11.75Z"
        fill="currentColor"
      />
      <path
        d="M0 16.75C0 16.3358 0.335786 16 0.75 16H7.25C7.66421 16 8 16.3358 8 16.75C8 17.1642 7.66421 17.5 7.25 17.5H0.75C0.335786 17.5 0 17.1642 0 16.75Z"
        fill="currentColor"
      />
    </g>
  </svg>
)
