import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Icon } from '../../../components/icon'
import { Modal } from '../../../components/modals/modal'
import { useStoreModals } from '../../../hooks/modals/use-store-modals'
import styles from './styles.module.scss'
import { ListItem } from './list-item'
import { Button } from '../../../components/button'
import { useGroupPlanTrial } from '../../../hooks/group/use-group-plan-trial'
import { useGroupPlanAlerts } from '../../../hooks/group/use-group-plan-alerts'

export const ModalGroupPlanTrial: React.FC = () => {
  const { i18n } = useLingui()
  const {
    modalGroupPlanTrial: { show, onDismiss }
  } = useStoreModals()
  const { loading, onAcceptTrial } = useGroupPlanTrial()
  useGroupPlanAlerts()

  if (!show) {
    return null
  }

  return (
    <Modal
      label="Group Plan"
      targetId="shell-app"
      isOpen={show}
      onClose={onDismiss}
      shouldCloseOnOverlayClick={!loading}
    >
      <div className={styles.container}>
        <button
          aria-label="Close"
          className={styles.close}
          type="button"
          disabled={loading}
          onClick={onDismiss}
          tabIndex={0}
        >
          <Icon name="close" />
        </button>

        <div className={styles.header}>
          <span className={styles.badge}>{t`new_label_announcement`}</span>
          <p className={styles.title}>{t`group_plan_pricing_title`}</p>
          <p
            className={styles.description}
          >{t`group_plan_pricing_description`}</p>
        </div>

        <div className={styles.content}>
          <ul className={styles.list}>
            <ListItem
              active
              title="1"
              description={i18n._(t`group_plan_step_1`)}
            />
            <ListItem title="2" description={i18n._(t`group_plan_step_2`)} />
            <ListItem title="3" description={i18n._(t`group_plan_step_3`)} />
            <ListItem title="4" description={i18n._(t`group_plan_step_4`)} />
            <ListItem title="5" description={i18n._(t`group_plan_step_5`)} />
          </ul>

          <Button
            small
            radius={1}
            disabled={loading}
            onClick={onAcceptTrial}
            className={styles.button}
            title={i18n._(t`try_free`)}
            id="modal_group_plan_trial_button"
          />

          <p className={styles.disclaimer}>{t`group_plan_pricing_foot`}</p>
        </div>
      </div>
    </Modal>
  )
}
