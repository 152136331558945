import { gql } from 'graphql-request'

export type Language = {
  name: string
  flag: string
  code: string
  availableLyricsTranscriptions: boolean
}

export type LanguagesCli = {
  getLanguages(): Promise<Language[]>
}

const Languages = (graphQL: any): LanguagesCli => {
  const getLanguages = async (): Promise<Language[]> => {
    const query = gql`
      query GetLanguages {
        languages(filters: { availableLyricsTranscription: true }) {
          name
          flag
          code
          availableLyricsTranscription
        }
      }
    `
    const { languages } = await graphQL({ query, variables: {} })
    return languages
  }

  return {
    getLanguages
  }
}

export default Languages
