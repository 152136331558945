import { useCallback, useContext } from 'react'
import { useRouter } from 'next/router'
import { usePersistentDismissible } from '../../misc/use-persistent-dismissable'
import { useCountdown } from '../../misc/countdown'
import { ContextShell } from '../../../modules/context'

export interface UseCampaignCountdown {
  show: boolean
  title: string
  description: string
  image?: string | null
  countdown: ReturnType<typeof useCountdown>
  onClickBannerCountdown: () => void
  onCloseBannerCountdown: (e: any) => void
  buttonText: string | null
  showDaysInCountdown?: boolean
}

export const useCampaignCountdown = (
  interval?: number
): UseCampaignCountdown => {
  const { push } = useRouter()
  const { campaign } = useContext(ContextShell)

  const countdown = useCountdown({
    endDate: campaign?.endDate,
    interval: interval || 1000 * 60,
    disabled: !campaign?.endDate
  })

  const { show, onDismiss } = usePersistentDismissible({
    id: `countdown-${campaign?.id}`,
    type: 'session',
    enabled: !!campaign?.id
  })

  const onCloseBannerCountdown = useCallback(
    (e: any) => {
      e?.stopPropagation()
      onDismiss()
    },
    [onDismiss]
  )

  const onClickBannerCountdown = useCallback(() => {
    push('/billing/pricing/?source=countdown_side_banner')
  }, [push])

  return {
    title: campaign?.profileBannerWeb?.title,
    description: campaign?.profileBannerWeb?.description,
    image: campaign?.countdownBannerImage,
    show: !!campaign?.id && show && !countdown?.ended,
    onCloseBannerCountdown,
    onClickBannerCountdown,
    countdown,
    buttonText: campaign?.profileBannerWeb?.cta
  }
}
