import React from 'react'
import { IconProps } from './icons.types'

export const UserGroupFilled: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ic-user-group-filled">
      <g id="Fill">
        <path
          d="M8.26667 5.12456C8.19349 4.90445 8.30254 4.66438 8.46298 4.49685C8.84957 4.09319 9.09792 3.37727 9.09792 2.84766C9.09792 1.93209 8.35571 1.09766 7.44014 1.09766C6.52456 1.09766 5.78235 1.93209 5.78235 2.84766C5.78235 3.37725 6.03068 4.09315 6.41725 4.49682C6.43355 4.51384 6.44932 4.53161 6.46442 4.55003C6.93704 5.06297 7.21755 5.75063 7.21755 6.44922C7.21755 6.85545 7.12562 7.28597 6.98185 7.67091C6.9042 7.87883 6.80352 8.09463 6.67757 8.30079H10.7557V7.58238C10.7557 7.00941 10.4293 6.48648 9.91455 6.23482L8.73195 5.65664C8.5117 5.54895 8.34403 5.3572 8.26667 5.12456Z"
          fill="currentColor"
        />
        <path
          d="M5.58256 8.09842C5.42212 8.26594 5.31306 8.50601 5.38625 8.72612C5.4636 8.95877 5.63128 9.15052 5.85153 9.2582L7.03412 9.83638C7.54887 10.088 7.87529 10.611 7.87529 11.1839V11.9024H1.24414V11.1839C1.24414 10.611 1.57055 10.088 2.08529 9.83638L3.26783 9.25821C3.48809 9.15052 3.65577 8.95876 3.73313 8.72611C3.80632 8.506 3.69727 8.26591 3.53683 8.09838C3.15026 7.69471 2.90193 6.97881 2.90193 6.44922C2.90193 5.53365 3.64414 4.69922 4.55971 4.69922C5.47528 4.69922 6.2175 5.53365 6.2175 6.44922C6.2175 6.97883 5.96915 7.69475 5.58256 8.09842Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
)
