import React from 'react'
import { IconProps } from './icons.types'

export const InstrumentMic: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 23.25L10 23.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M12 19V24" stroke="currentColor" strokeWidth="1.5" />
    <path
      d="M4 10.5V11C4 15.4183 7.58172 19 12 19V19C16.4183 19 20 15.4183 20 11V10.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <rect
      x="8"
      y="1"
      width="8"
      height="14"
      rx="4"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
)
