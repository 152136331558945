import React, { useEffect, useRef, useState } from 'react'
import { Icon } from '../../icon'
import styles from './styles.module.scss'
import { UseCampaignCountdown } from '../../../hooks/campaign/use-campaing-countdown/use-campaign-countdown'
import { Countdown } from '../../countdown/countdown'
import { Button } from '../../button'

export const BannerCountdown: React.FC<UseCampaignCountdown> = ({
  show,
  title,
  description,
  image,
  countdown,
  onClickBannerCountdown,
  onCloseBannerCountdown,
  buttonText,
  showDaysInCountdown = true
}) => {
  const { ended } = countdown
  const [delayToShow, setDelayToShow] = useState(true)
  const timeoutRef = useRef<any>(null)

  useEffect(() => {
    if (show) {
      timeoutRef.current = setTimeout(() => {
        setDelayToShow(false)
      }, 600)
    } else {
      clearTimeout(timeoutRef.current)
      setDelayToShow(true)
    }
  }, [show])

  if (!show || ended || delayToShow) {
    return null
  }

  return (
    <div
      aria-hidden
      role="button"
      className={styles.container}
      onClick={onClickBannerCountdown}
    >
      <div
        className={styles.image}
        style={{ backgroundImage: `url(${image})` }}
      />
      <button
        type="button"
        aria-label="Close"
        className={styles.closeButton}
        onClick={onCloseBannerCountdown}
      >
        <Icon name="close" width={20} height={20} />
      </button>
      <div className={styles.containerContent}>
        <div className={styles.labelContent}>
          {title && (
            <p className={styles.description}>
              {title?.replace(/\*{2}(.*?)\*{2}/g, '$1')}
            </p>
          )}

          {description && (
            <p className={styles.description}>
              {description?.replace(/\*{2}(.*?)\*{2}/g, '$1')}
            </p>
          )}
        </div>

        <Countdown
          show={show}
          countdown={countdown}
          variant="md"
          showDays={showDaysInCountdown}
        />

        <Button
          title={buttonText}
          long
          primary
          radius={1}
          className={styles.button}
        />
      </div>
    </div>
  )
}
