import React from 'react'
import { IconProps } from './icons.types'

export const Code: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4922_15511)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7548 4.2243C14.862 3.8242 14.6245 3.41295 14.2244 3.30574C13.8243 3.19853 13.4131 3.43597 13.3059 3.83607L9.03537 19.7738C8.92817 20.1739 9.1656 20.5852 9.5657 20.6924C9.9658 20.7996 10.3771 20.5622 10.4843 20.1621L14.7548 4.2243ZM7.48014 5.23554C7.79835 5.50071 7.84134 5.97364 7.57617 6.29184L2.97628 11.8117L7.57617 17.3316C7.84134 17.6498 7.79835 18.1227 7.48014 18.3879C7.16193 18.653 6.68901 18.6101 6.42383 18.2918L1.42383 12.2918C1.19206 12.0137 1.19206 11.6097 1.42383 11.3316L6.42383 5.33157C6.68901 5.01336 7.16193 4.97037 7.48014 5.23554ZM16.5199 5.23554C16.8381 4.97037 17.311 5.01336 17.5762 5.33157L22.5762 11.3316C22.8079 11.6097 22.8079 12.0137 22.5762 12.2918L17.5762 18.2918C17.311 18.6101 16.8381 18.653 16.5199 18.3879C16.2017 18.1227 16.1587 17.6498 16.4238 17.3316L21.0237 11.8117L16.4238 6.29184C16.1587 5.97364 16.2017 5.50071 16.5199 5.23554Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4922_15511">
        <rect width={width} height={height} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
)
