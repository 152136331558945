import React from 'react'
import { IconProps } from './icons.types'

export const Search: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 11C3.75 6.99594 6.99594 3.75 11 3.75C15.0041 3.75 18.25 6.99594 18.25 11C18.25 12.9606 17.4717 14.7395 16.2073 16.0444C16.1767 16.0669 16.1474 16.092 16.1197 16.1197C16.092 16.1474 16.0669 16.1767 16.0444 16.2073C14.7394 17.4718 12.9606 18.25 11 18.25C6.99594 18.25 3.75 15.0041 3.75 11ZM16.6342 17.6949C15.112 18.9773 13.1462 19.75 11 19.75C6.16751 19.75 2.25 15.8325 2.25 11C2.25 6.16751 6.16751 2.25 11 2.25C15.8325 2.25 19.75 6.16751 19.75 11C19.75 13.1462 18.9773 15.112 17.6949 16.6342L21.5303 20.4697C21.8232 20.7626 21.8232 21.2374 21.5303 21.5303C21.2374 21.8232 20.7626 21.8232 20.4697 21.5303L16.6342 17.6949Z"
      fill="currentColor"
    />
  </svg>
)
