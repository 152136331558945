import React from 'react'
import { IconProps } from './icons.types'

export const SpatialAudio: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4050_46967)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.11847 11.4286C9.11847 9.79605 10.4236 8.5 12.0001 8.5C13.5765 8.5 14.8817 9.79605 14.8817 11.4286C14.8817 13.0611 13.5765 14.3571 12.0001 14.3571C10.4236 14.3571 9.11847 13.0611 9.11847 11.4286ZM12.0001 7C9.58019 7 7.61847 8.98274 7.61847 11.4286C7.61847 13.8744 9.58019 15.8571 12.0001 15.8571C14.42 15.8571 16.3817 13.8744 16.3817 11.4286C16.3817 8.98274 14.42 7 12.0001 7ZM9 17.25C6.37665 17.25 4.25 19.3766 4.25 22V23C4.25 23.4142 4.58579 23.75 5 23.75C5.41421 23.75 5.75 23.4142 5.75 23V22C5.75 20.2051 7.20507 18.75 9 18.75H15C16.7949 18.75 18.25 20.2051 18.25 22V23C18.25 23.4142 18.5858 23.75 19 23.75C19.4142 23.75 19.75 23.4142 19.75 23V22C19.75 19.3766 17.6234 17.25 15 17.25H9Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1802 7.33654C21.977 7.75759 21.8488 8.34558 21.8488 9C21.8488 9.65442 21.977 10.2424 22.1802 10.6635C22.386 11.0899 22.6542 11.3193 22.9244 11.3193C23.1947 11.3193 23.4628 11.0899 23.6686 10.6635C23.8718 10.2424 24 9.65442 24 9C24 8.34558 23.8718 7.75759 23.6686 7.33654C23.4628 6.91009 23.1947 6.68072 22.9244 6.68072C22.6542 6.68072 22.386 6.91009 22.1802 7.33654ZM21.8498 7.25572C22.0648 6.81005 22.3781 6.5 22.75 6.5C23.1219 6.5 23.4352 6.81005 23.6502 7.25572C23.8679 7.70679 24 8.32361 24 9C24 9.67639 23.8679 10.2932 23.6502 10.7443C23.4352 11.1899 23.1219 11.5 22.75 11.5C22.3781 11.5 22.0648 11.1899 21.8498 10.7443C21.6321 10.2932 21.5 9.67639 21.5 9C21.5 8.32361 21.6321 7.70679 21.8498 7.25572Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3604 5.67308C19.954 6.51517 19.6977 7.69117 19.6977 9C19.6977 10.3088 19.954 11.4848 20.3604 12.3269C20.772 13.1798 21.3083 13.6386 21.8488 13.6386C22.3894 13.6386 22.9257 13.1798 23.3373 12.3269C23.7436 11.4848 24 10.3088 24 9C24 7.69117 23.7436 6.51517 23.3373 5.67308C22.9257 4.82017 22.3894 4.36145 21.8488 4.36145C21.3083 4.36145 20.772 4.82017 20.3604 5.67308ZM19.6996 5.51143C20.1297 4.62011 20.7562 4 21.5 4C22.2438 4 22.8703 4.62011 23.3004 5.51143C23.7358 6.41357 24 7.64722 24 9C24 10.3528 23.7358 11.5864 23.3004 12.4886C22.8703 13.3799 22.2438 14 21.5 14C20.7562 14 20.1297 13.3799 19.6996 12.4886C19.2642 11.5864 19 10.3528 19 9C19 7.64722 19.2642 6.41357 19.6996 5.51143Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.8198 7.33654C2.02298 7.75759 2.15116 8.34558 2.15116 9C2.15116 9.65442 2.02298 10.2424 1.8198 10.6635C1.61401 11.0899 1.34585 11.3193 1.07558 11.3193C0.805314 11.3193 0.537155 11.0899 0.331366 10.6635C0.128186 10.2424 -7.07805e-08 9.65442 -7.07805e-08 9C-7.07805e-08 8.34558 0.128186 7.75759 0.331366 7.33654C0.537155 6.91009 0.805314 6.68072 1.07558 6.68072C1.34585 6.68072 1.61401 6.91009 1.8198 7.33654ZM2.15022 7.25572C1.93516 6.81005 1.62192 6.5 1.25 6.5C0.878076 6.5 0.56484 6.81005 0.34978 7.25572C0.132113 7.70679 -7.07805e-08 8.32361 -7.07805e-08 9C-7.07805e-08 9.67639 0.132113 10.2932 0.34978 10.7443C0.56484 11.1899 0.878076 11.5 1.25 11.5C1.62192 11.5 1.93516 11.1899 2.15022 10.7443C2.36789 10.2932 2.5 9.67639 2.5 9C2.5 8.32361 2.36789 7.70679 2.15022 7.25572Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.63959 5.67308C4.04595 6.51517 4.30233 7.69117 4.30233 9C4.30233 10.3088 4.04595 11.4848 3.63959 12.3269C3.22802 13.1798 2.6917 13.6386 2.15116 13.6386C1.61063 13.6386 1.07431 13.1798 0.662733 12.3269C0.256373 11.4848 -1.41561e-07 10.3088 -1.41561e-07 9C-1.41561e-07 7.69117 0.256373 6.51517 0.662733 5.67308C1.07431 4.82017 1.61063 4.36145 2.15116 4.36145C2.6917 4.36145 3.22802 4.82017 3.63959 5.67308ZM4.30044 5.51143C3.87032 4.62011 3.24385 4 2.5 4C1.75615 4 1.12968 4.62011 0.699561 5.51143C0.264226 6.41357 -1.41561e-07 7.64722 -1.41561e-07 9C-1.41561e-07 10.3528 0.264226 11.5864 0.699561 12.4886C1.12968 13.3799 1.75615 14 2.5 14C3.24385 14 3.87032 13.3799 4.30044 12.4886C4.73577 11.5864 5 10.3528 5 9C5 7.64722 4.73577 6.41357 4.30044 5.51143Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3365 1.8198C10.7576 2.02298 11.3456 2.15116 12 2.15116C12.6544 2.15116 13.2424 2.02298 13.6635 1.8198C14.0899 1.61401 14.3193 1.34585 14.3193 1.07558C14.3193 0.805313 14.0899 0.537155 13.6635 0.331366C13.2424 0.128186 12.6544 -2.08664e-07 12 -1.80059e-07C11.3456 -1.51454e-07 10.7576 0.128186 10.3365 0.331366C9.91009 0.537155 9.68072 0.805314 9.68072 1.07558C9.68072 1.34585 9.91009 1.61401 10.3365 1.8198ZM10.2557 2.15022C9.81005 1.93516 9.5 1.62192 9.5 1.25C9.5 0.878076 9.81005 0.564839 10.2557 0.34978C10.7068 0.132113 11.3236 -1.50493e-07 12 -1.80059e-07C12.6764 -2.09625e-07 13.2932 0.132113 13.7443 0.34978C14.1899 0.564839 14.5 0.878076 14.5 1.25C14.5 1.62192 14.1899 1.93516 13.7443 2.15022C13.2932 2.36789 12.6764 2.5 12 2.5C11.3236 2.5 10.7068 2.36789 10.2557 2.15022Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.67308 3.63959C9.51517 4.04595 10.6912 4.30233 12 4.30233C13.3088 4.30233 14.4848 4.04595 15.3269 3.63959C16.1798 3.22802 16.6386 2.6917 16.6386 2.15116C16.6386 1.61063 16.1798 1.07431 15.3269 0.662733C14.4848 0.256372 13.3088 -4.17329e-07 12 -3.60118e-07C10.6912 -3.02907e-07 9.51517 0.256373 8.67308 0.662733C7.82017 1.07431 7.36145 1.61063 7.36145 2.15116C7.36145 2.6917 7.82017 3.22802 8.67308 3.63959ZM8.51143 4.30044C7.62011 3.87032 7 3.24385 7 2.5C7 1.75615 7.62011 1.12968 8.51143 0.699561C9.41357 0.264226 10.6472 -3.00986e-07 12 -3.60118e-07C13.3528 -4.1925e-07 14.5864 0.264226 15.4886 0.69956C16.3799 1.12968 17 1.75615 17 2.5C17 3.24385 16.3799 3.87032 15.4886 4.30044C14.5864 4.73577 13.3528 5 12 5C10.6472 5 9.41357 4.73577 8.51143 4.30044Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4050_46967">
        <rect width="24" height="24" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
)
