import { useCallback, useEffect, useState } from 'react'
import { useInterval } from 'react-use'

export interface UseCountdown {
  ended?: boolean
  days: number
  hours: string
  minutes: string
  seconds: string
  loadingCountdown?: boolean
}

export const useCountdown = ({
  endDate,
  disabled = false,
  interval = 1000
}: {
  endDate: string | null | undefined
  disabled?: boolean
  interval?: number
}): UseCountdown => {
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState('00')
  const [minutes, setMinutes] = useState('00')
  const [seconds, setSeconds] = useState('00')
  const [ended, setEnded] = useState(false)
  const [loadingCountdown, setLoadingCountdown] = useState(false)

  const calculateExpiresAt = useCallback(() => {
    if (!endDate) return

    const now = new Date().getTime()
    const finalDate = new Date(endDate).getTime()

    /**
     * in some cases, date 'now' is in the future.
     * prevent to show countdown in this case
     */
    if (now > finalDate) {
      setLoadingCountdown(true)
      return
    }

    const diff = finalDate - now
    const days1 = Math.floor(diff / (1000 * 60 * 60 * 24))
    const hours1 = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes1 = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
    const seconds1 = Math.floor((diff % (1000 * 60)) / 1000)

    if (days1 > 0 || hours1 > 0 || minutes1 > 0 || seconds1 > 0) {
      setEnded(false)
      setLoadingCountdown(false)
      setDays(days1)
      setHours(String(hours1).padStart(2, '0'))
      setMinutes(String(minutes1).padStart(2, '0'))
      setSeconds(String(seconds1).padStart(2, '0'))
    } else if (endDate) {
      setEnded(true)
    }
  }, [endDate])

  useEffect(() => {
    calculateExpiresAt()
  }, [calculateExpiresAt])

  useInterval(
    () => {
      calculateExpiresAt()
    },
    !ended && !disabled && !loadingCountdown ? interval : null
  )

  return {
    ended,
    days,
    hours,
    minutes,
    seconds,
    loadingCountdown
  }
}
