import React from 'react'
import { IconProps } from './icons.types'

export const SocialFacebook: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8333 23H9.47139V12.1555H6.5V8.62476H9.47127V6.11238C9.47127 3.13521 10.7855 1 15.1355 1C16.0556 1 17.5 1.18495 17.5 1.18495V4.46344H15.9829C14.437 4.46344 13.8335 4.93239 13.8335 6.22887V8.62476H17.4421L17.1208 12.1555H13.8334L13.8333 23Z"
      fill="white"
    />
  </svg>
)
