import React from 'react'
import { IconProps } from './icons.types'

export const Cymbals: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22.4952V18.5312M12 6.98657V1.5M9.40695 6.52942C8.62766 6.77381 8.10044 7.20112 8.01283 7.75139C7.92522 8.30166 8.29104 8.87192 8.95505 9.34687C9.61753 9.82336 10.5797 10.2061 11.6803 10.3829C12.7808 10.5596 13.8137 10.4966 14.593 10.2507C15.3723 10.0063 15.8995 9.57897 15.9872 9.0287V9.03023C16.0748 8.47996 15.709 7.90971 15.0449 7.43322M22.9647 11.1459C22.7238 12.6592 21.2739 13.8342 19.1309 14.5063C16.9878 15.1826 14.1473 15.3559 11.1208 14.8698C8.0943 14.3837 5.44824 13.3312 3.62643 12.0209C1.80039 10.7148 0.794374 9.14656 1.03531 7.63332C1.27625 6.12007 2.72609 4.94498 4.86915 4.2729C7.01221 3.60081 9.85271 3.42328 12.8792 3.90938C15.9057 4.39548 18.5518 5.44799 20.3736 6.75834C22.1996 8.06869 23.2056 9.63689 22.9647 11.1501V11.1459Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
