import React from 'react'
import classnames from 'classnames'
import styles from './drop-menu.module.scss'

interface DropMenuProps {
  className?: string
  children?: React.ReactNode
}

export const DropMenu: React.FC<DropMenuProps> = ({ className, children }) => (
  <div className={classnames(className, styles.dropMenu)}>{children}</div>
)
