import React from 'react'
import { IconProps } from './icons.types'

export const Zip: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.11612 4.11612C3.35054 3.8817 3.66848 3.75 4 3.75H8.59861L10.376 6.41603C10.5151 6.62467 10.7492 6.75 11 6.75H20C20.3315 6.75 20.6495 6.8817 20.8839 7.11612C21.1183 7.35054 21.25 7.66848 21.25 8V19C21.25 19.3315 21.1183 19.6495 20.8839 19.8839C20.6495 20.1183 20.3315 20.25 20 20.25H4C3.66848 20.25 3.35054 20.1183 3.11612 19.8839C2.8817 19.6495 2.75 19.3315 2.75 19V5C2.75 4.66848 2.8817 4.35054 3.11612 4.11612ZM4 2.25C3.27065 2.25 2.57118 2.53973 2.05546 3.05546C1.53973 3.57118 1.25 4.27065 1.25 5V19C1.25 19.7293 1.53973 20.4288 2.05546 20.9445C2.57118 21.4603 3.27065 21.75 4 21.75H20C20.7293 21.75 21.4288 21.4603 21.9445 20.9445C22.4603 20.4288 22.75 19.7293 22.75 19V8C22.75 7.27065 22.4603 6.57118 21.9445 6.05546C21.4288 5.53973 20.7293 5.25 20 5.25H11.4014L9.62404 2.58397C9.48494 2.37533 9.25076 2.25 9 2.25H4ZM9.75 9.35742C9.33579 9.35742 9 9.69321 9 10.1074C9 10.5216 9.33579 10.8574 9.75 10.8574H14.25C14.6642 10.8574 15 10.5216 15 10.1074C15 9.69321 14.6642 9.35742 14.25 9.35742H9.75ZM9.75 12.3574C9.33579 12.3574 9 12.6932 9 13.1074C9 13.5216 9.33579 13.8574 9.75 13.8574H14.25C14.6642 13.8574 15 13.5216 15 13.1074C15 12.6932 14.6642 12.3574 14.25 12.3574H9.75ZM9 16.1074C9 15.6932 9.33579 15.3574 9.75 15.3574H12.25C12.6642 15.3574 13 15.6932 13 16.1074C13 16.5216 12.6642 16.8574 12.25 16.8574H9.75C9.33579 16.8574 9 16.5216 9 16.1074Z"
      fill="currentColor"
    />
  </svg>
)
