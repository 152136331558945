import { t } from '@lingui/macro'
import React from 'react'
import { ReferralLinkList } from './referral-link-list'
import styles from './modal-referral-link.module.scss'
import { useStoreModals } from '../../../hooks/modals/use-store-modals'
import { Modal } from '../../../components/modals/modal'
import { Button } from '../../../components/button'
import { Icon } from '../../../components/icon'

export const ModalReferralLink: React.FC = () => {
  const {
    modalReferralLink: { show, onDismiss }
  } = useStoreModals()

  if (!show) return null

  return (
    <Modal
      label={t`refer_friend_title`}
      targetId="shell-app"
      isOpen={show}
      onClose={onDismiss}
      modalClassName={styles.modal}
      shouldCloseOnOverlayClick
    >
      <div className={styles.root}>
        <div className={styles.header}>
          <h3 className={styles.title}>{t`refer_friend_title`}</h3>
          <Button
            transparent
            light
            className={styles.closeButton}
            icon={<Icon name="close" className={styles.closeIcon} />}
            onClick={onDismiss}
          />
        </div>
        <hr className={styles.divider} />
        <div className={styles.content}>
          <ReferralLinkList />
        </div>
      </div>
    </Modal>
  )
}
