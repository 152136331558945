import React from 'react'
import { IconProps } from './icons.types'

export const Volume3: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 4.99994H11.75C11.75 4.71164 11.5847 4.44885 11.3249 4.32396C11.065 4.19907 10.7566 4.23419 10.5315 4.41429L11 4.99994ZM6 8.99994V9.74994C6.1703 9.74994 6.33554 9.69198 6.46852 9.58559L6 8.99994ZM2 8.99994V8.24994C1.58579 8.24994 1.25 8.58573 1.25 8.99994H2ZM2 14.9999H1.25C1.25 15.4142 1.58579 15.7499 2 15.7499V14.9999ZM6 14.9999L6.46852 14.4143C6.33554 14.3079 6.1703 14.2499 6 14.2499V14.9999ZM11 18.9999L10.5315 19.5856C10.7566 19.7657 11.065 19.8008 11.3249 19.6759C11.5847 19.551 11.75 19.2882 11.75 18.9999H11ZM19.6004 4.39968C19.3076 4.10674 18.8327 4.10667 18.5398 4.39952C18.2468 4.69237 18.2467 5.16724 18.5396 5.46018L19.6004 4.39968ZM18.5396 18.5397C18.2467 18.8326 18.2468 19.3075 18.5398 19.6003C18.8327 19.8932 19.3076 19.8931 19.6004 19.6002L18.5396 18.5397ZM16.0704 7.92968C15.7776 7.63674 15.3027 7.63667 15.0098 7.92952C14.7168 8.22237 14.7167 8.69724 15.0096 8.99018L16.0704 7.92968ZM15.0096 14.9997C14.7167 15.2926 14.7168 15.7675 15.0098 16.0603C15.3027 16.3532 15.7776 16.3531 16.0704 16.0602L15.0096 14.9997ZM10.5315 4.41429L5.53148 8.41429L6.46852 9.58559L11.4685 5.58559L10.5315 4.41429ZM6 8.24994H2V9.74994H6V8.24994ZM1.25 8.99994V14.9999H2.75V8.99994H1.25ZM2 15.7499H6V14.2499H2V15.7499ZM5.53148 15.5856L10.5315 19.5856L11.4685 18.4143L6.46852 14.4143L5.53148 15.5856ZM11.75 18.9999V4.99994H10.25V18.9999H11.75ZM18.5396 5.46018C20.2737 7.19481 21.2479 9.54716 21.2479 11.9999H22.7479C22.7479 9.14942 21.6157 6.4156 19.6004 4.39968L18.5396 5.46018ZM21.2479 11.9999C21.2479 14.4527 20.2737 16.805 18.5396 18.5397L19.6004 19.6002C21.6157 17.5843 22.7479 14.8504 22.7479 11.9999H21.2479ZM15.0096 8.99018C15.8064 9.78717 16.2539 10.868 16.2539 11.9949H17.7539C17.7539 10.4702 17.1484 9.00797 16.0704 7.92968L15.0096 8.99018ZM16.2539 11.9949C16.2539 13.1219 15.8064 14.2027 15.0096 14.9997L16.0704 16.0602C17.1484 14.9819 17.7539 13.5196 17.7539 11.9949H16.2539Z"
      fill="currentColor"
    />
  </svg>
)
