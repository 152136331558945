import { useCallback, useEffect, useState } from 'react'
import { useAsyncFn } from 'react-use'
import { request } from 'graphql-request'
import { config, getClientHeaders } from '../../../config'
import { UserContext, UserToken } from '../../../types'
import { getUserQuery } from './get-user-query'

const getUserData = async (
  apiUrl: any,
  auth: any,
  clientHeaders: any
): Promise<any> => {
  if (!auth) {
    return null
  }

  const { user } = await request(apiUrl, getUserQuery, null, {
    authorization: auth,
    ...clientHeaders
  })

  return {
    user: {
      ...user,
      subscription: {
        ...user.subscription,
        active: user.subscription.isPremium
      }
    }
  }
}

type UseGetUserDataParams = {
  userToken: UserToken
}

interface UseGetUserData {
  user: UserContext | null
  error: boolean
  loading: boolean
  loadingUserData: boolean
  retry: () => void
  silentRetry: () => void
}

export const useGetUserData = ({
  userToken
}: UseGetUserDataParams): UseGetUserData => {
  const clientHeaders = getClientHeaders()
  const [silentRetrying, setSilentRetrying] = useState(false)
  const apiUrl = config.graphql.endpoint

  const [{ loading, error, value }, retry] = useAsyncFn(async () => {
    return getUserData(apiUrl, userToken, clientHeaders)
  }, [apiUrl, userToken, clientHeaders])

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (userToken && !loaded) {
      setLoaded(true)
      retry()
    }
  }, [userToken, loaded, retry])

  const silentRetry = useCallback(() => {
    setSilentRetrying(true)
    retry()
  }, [retry])

  useEffect(() => {
    // reset "silentRetrying" state after fetching the data
    if (loading === false && userToken) {
      setSilentRetrying(false)
    }
  }, [loading, userToken])

  return {
    user: value?.user,
    error: !!error,
    retry,
    silentRetry,
    loadingUserData: loading,
    // bypass default loading state if "silentRetrying" is true
    loading: silentRetrying ? false : loading || !userToken
  }
}
