import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import { useAsyncFn } from 'react-use'
import { ContextShell } from '../../../modules/context'
import { Moises } from '../../../lib/cli'
import { config } from '../../../config'

interface UseDeletePlaylist {
  playlistDeleted: boolean
  error?: boolean
  loading: boolean
  deletePlaylist(i: {
    playlistId: string
    events?: {
      [key: string]: string
    }
  }): void
}

const MoisesCLI = Moises({ apiEndpoint: config.api.endpoint })

export const useDeletePlaylist = (): UseDeletePlaylist => {
  const { i18n } = useLingui()
  const { pathname, push } = useRouter()
  const {
    userToken,
    toast: { add: addToast },
    event: { sendEvent }
  } = useContext(ContextShell)

  const [state, deletePlaylist] = useAsyncFn(
    async ({
      playlistId,
      events = {}
    }: {
      playlistId: string
      events?: {
        [key: string]: string
      }
    }) => {
      let result
      MoisesCLI.auth(userToken)

      try {
        result = await MoisesCLI.deletePlaylist({ playlistId })

        addToast({
          icon: null,
          closable: true,
          type: 'success',
          description: i18n._(t`setlist_delete_success`)
        })

        sendEvent({
          name: 'playlist_removed',
          category: 'retention',
          customAttributes: {
            playlist_id: playlistId,
            timestamp: new Date().toISOString(),
            ...events,
            reason: 'deleted'
          }
        })

        if (pathname !== '/library') {
          push('/library/')
        }
      } catch (e: any) {
        addToast({
          icon: null,
          type: 'error',
          closable: true,
          title: i18n._(t`error.deleting.playlist.title`),
          description: i18n
            ._(t`error.create.playlist.description`)
            .replace(/\*/g, '')
        })
      }

      return result
    },
    [i18n, userToken, addToast, pathname, push, sendEvent]
  )

  const { loading, error, value } = state

  return {
    ...state,
    loading,
    error: !!error,
    playlistDeleted: value === true,
    deletePlaylist
  }
}
