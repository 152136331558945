import { useMemo } from 'react'

interface UseDesktopApp {
  isDesktopApp: boolean
}

export const useDesktopApp = (): UseDesktopApp => {
  return useMemo(
    () => ({
      isDesktopApp:
        typeof window !== 'undefined'
          ? navigator?.userAgent?.includes('MoisesDesktop')
          : false
    }),
    []
  )
}
