import React from 'react'
import { IconProps } from './icons.types'

export const Volume2: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 5H11.75C11.75 4.7117 11.5847 4.44891 11.3249 4.32402C11.065 4.19913 10.7566 4.23425 10.5315 4.41435L11 5ZM6 9V9.75C6.1703 9.75 6.33554 9.69204 6.46852 9.58565L6 9ZM2 9V8.25C1.58579 8.25 1.25 8.58579 1.25 9H2ZM2 15H1.25C1.25 15.4142 1.58579 15.75 2 15.75V15ZM6 15L6.46852 14.4143C6.33554 14.308 6.1703 14.25 6 14.25V15ZM11 19L10.5315 19.5857C10.7566 19.7658 11.065 19.8009 11.3249 19.676C11.5847 19.5511 11.75 19.2883 11.75 19H11ZM16.0704 7.92974C15.7776 7.6368 15.3027 7.63673 15.0098 7.92958C14.7168 8.22243 14.7167 8.6973 15.0096 8.99024L16.0704 7.92974ZM15.0096 14.9997C14.7167 15.2927 14.7168 15.7676 15.0098 16.0604C15.3027 16.3533 15.7776 16.3532 16.0704 16.0602L15.0096 14.9997ZM10.5315 4.41435L5.53148 8.41435L6.46852 9.58565L11.4685 5.58565L10.5315 4.41435ZM6 8.25H2V9.75H6V8.25ZM1.25 9V15H2.75V9H1.25ZM2 15.75H6V14.25H2V15.75ZM5.53148 15.5857L10.5315 19.5857L11.4685 18.4143L6.46852 14.4143L5.53148 15.5857ZM11.75 19V5H10.25V19H11.75ZM15.0096 8.99024C15.8064 9.78723 16.2539 10.868 16.2539 11.995H17.7539C17.7539 10.4703 17.1484 9.00803 16.0704 7.92974L15.0096 8.99024ZM16.2539 11.995C16.2539 13.1219 15.8064 14.2027 15.0096 14.9997L16.0704 16.0602C17.1484 14.982 17.7539 13.5197 17.7539 11.995H16.2539Z"
      fill="currentColor"
    />
  </svg>
)
