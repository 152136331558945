import axios from 'axios'
import * as Sentry from '@sentry/nextjs'
import { Dictionary } from 'ramda'
import { SeverityLevel } from '@sentry/nextjs'

export type ErrorType = 'invalid' | 'canceled' | 'aborted' | 'failed'

export type MessageMap = { [key in ErrorType]?: string }

export interface CapturedRequestErrorParams {
  message: string
  level: SeverityLevel
  stack: string
  status?: number
  body: any
}

const MESSAGE_LEVEL_MAP: Record<ErrorType, SeverityLevel> = {
  canceled: 'info',
  aborted: 'info',
  failed: 'error',
  invalid: 'error'
}

function getErrorType(error: any): ErrorType {
  if (axios.isCancel(error)) {
    return 'canceled'
  }

  if (
    (axios.isAxiosError(error) && error.code === 'ECONNABORTED') ||
    error.message === 'Network Error'
  ) {
    return 'aborted'
  }

  if (axios.isAxiosError(error) && error.response?.status === 400) {
    return 'invalid'
  }

  return 'failed'
}

export function extractRequestErrorParams(
  error: any,
  messageMap: MessageMap | string
): CapturedRequestErrorParams | null {
  const type = getErrorType(error)
  const level = MESSAGE_LEVEL_MAP[type]
  const message = typeof messageMap === 'string' ? messageMap : messageMap[type]

  // When the messageMap is a string, capture error-level messages only
  if (!message || (typeof messageMap === 'string' && level !== 'error')) {
    return null
  }

  const stack = error.stack ?? error.message ?? 'Unknown error'
  const status = axios.isAxiosError(error) ? error.response?.status : undefined
  const body = axios.isAxiosError(error) && error.response?.data

  return { message, level, stack, status, body }
}

export function captureRequestErrorFromParams(
  params: CapturedRequestErrorParams,
  feature: string,
  extra?: Dictionary<unknown>
): void {
  const { message, level, stack, status, body } = params

  Sentry.captureMessage(message, {
    level,
    tags: { feature },
    extra: { error: stack, status, body, ...extra }
  })
}

export function captureRequestError(
  error: any,
  feature: string,
  messageMap: MessageMap | string,
  extra?: Dictionary<unknown>
): void {
  const params = extractRequestErrorParams(error, messageMap)

  if (params) {
    captureRequestErrorFromParams(params, feature, extra)
  }
}
