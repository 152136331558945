import React from 'react'
import { IconProps } from './icons.types'

export const Metronome: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 17.625L10.6641 2.61615C11.2209 1.52571 12.7791 1.52571 13.3359 2.61615L21 17.625V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V17.625Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 3L3.05359 4.5277M12 17.5L5.33703 7.83869"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <circle
      cx="4.18748"
      cy="6.17185"
      r="1.97581"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path d="M3.5 17.5H20.5" stroke="currentColor" strokeWidth="1.5" />
  </svg>
)
