import React from 'react'
import { IconProps } from './icons.types'

export const Sections: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 2L14 6L10 10M13.0625 6H4C2.34315 6 1 7.34315 1 9V12C1 13.6569 2.34315 15 4 15H20C21.6569 15 23 13.6569 23 12V9C23 7.34315 21.6569 6 20 6H18M1 18V19C1 20.6569 2.34315 22 4 22H20C21.6569 22 23 20.6569 23 19V18"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
