import React from 'react'
import { IconProps } from './icons.types'

export const Tuning: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={height}
    height={width}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6668 2.91666C13.6668 2.50245 13.331 2.16666 12.9168 2.16666C12.5026 2.16666 12.1668 2.50245 12.1668 2.91666H13.6668ZM7.8335 2.91666C7.8335 2.50245 7.49771 2.16666 7.0835 2.16666C6.66928 2.16666 6.3335 2.50245 6.3335 2.91666H7.8335ZM12.1668 2.91666V8.33333H13.6668V2.91666H12.1668ZM7.8335 8.33333V2.91666H6.3335V8.33333H7.8335ZM10.0002 10.5C8.80355 10.5 7.8335 9.52995 7.8335 8.33333H6.3335C6.3335 10.3584 7.97512 12 10.0002 12V10.5ZM12.1668 8.33333C12.1668 9.52995 11.1968 10.5 10.0002 10.5V12C12.0252 12 13.6668 10.3584 13.6668 8.33333H12.1668ZM9.25016 11.4583V16.4583H10.7502V11.4583H9.25016ZM9.87516 16.875C9.87516 16.806 9.93113 16.75 10.0002 16.75V18.25C10.7596 18.25 11.3752 17.6344 11.3752 16.875H9.87516ZM10.0002 16.75C10.0692 16.75 10.1252 16.806 10.1252 16.875H8.62516C8.62516 17.6344 9.24077 18.25 10.0002 18.25V16.75ZM10.1252 16.875C10.1252 16.944 10.0692 17 10.0002 17V15.5C9.24077 15.5 8.62516 16.1156 8.62516 16.875H10.1252ZM10.0002 17C9.93113 17 9.87516 16.944 9.87516 16.875H11.3752C11.3752 16.1156 10.7596 15.5 10.0002 15.5V17ZM10.2636 16.875C10.2636 17.0205 10.1457 17.1385 10.0002 17.1385V18.6385C10.9741 18.6385 11.7636 17.8489 11.7636 16.875H10.2636ZM10.0002 17.1385C9.85466 17.1385 9.7367 17.0205 9.7367 16.875H8.2367C8.2367 17.8489 9.02623 18.6385 10.0002 18.6385V17.1385ZM9.7367 16.875C9.7367 16.7295 9.85466 16.6115 10.0002 16.6115V15.1115C9.02623 15.1115 8.2367 15.9011 8.2367 16.875H9.7367ZM10.0002 16.6115C10.1457 16.6115 10.2636 16.7295 10.2636 16.875H11.7636C11.7636 15.9011 10.9741 15.1115 10.0002 15.1115V16.6115Z"
      fill="currentColor"
    />
  </svg>
)
