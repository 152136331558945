import React from 'react'
import { IconProps } from './icons.types'

export const Settings: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.89275 5.05662L5.03101 4.94154C4.9117 4.93327 4.79196 4.95033 4.68031 4.9915C4.56867 5.03267 4.46786 5.09694 4.38507 5.17972C3.29058 6.28609 2.48442 7.62491 2.03239 9.08692C1.999 9.19706 1.99136 9.31289 2.00999 9.42619C2.02863 9.53948 2.07308 9.64743 2.1402 9.74237L3.17497 11.2307L3.17497 12.7692L2.14015 14.2575C2.07304 14.3525 2.02858 14.4604 2.00994 14.5737C1.9913 14.687 1.99894 14.8029 2.03232 14.913C2.48437 16.375 3.29054 17.7138 4.38504 18.8202C4.46783 18.903 4.56863 18.9672 4.68027 19.0084C4.79192 19.0496 4.91166 19.0666 5.03097 19.0584L6.89266 18.9433L8.28222 19.7125L9.10915 21.316C9.16134 21.4192 9.23662 21.5101 9.32963 21.5822C9.42263 21.6543 9.53108 21.7059 9.64725 21.7333C11.1938 22.0889 12.8061 22.0889 14.3527 21.7333C14.4688 21.7059 14.5773 21.6543 14.6703 21.5822C14.7633 21.5101 14.8386 21.4192 14.8908 21.316L15.7177 19.7126L17.1072 18.9434L18.969 19.0585C19.0883 19.0667 19.208 19.0497 19.3197 19.0085C19.4313 18.9673 19.5321 18.9031 19.6149 18.8203C20.7094 17.7139 21.5156 16.3751 21.9676 14.9131C22.001 14.8029 22.0086 14.6871 21.99 14.5738C21.9714 14.4605 21.9269 14.3526 21.8598 14.2576L20.825 12.7693L20.825 11.2308L21.8598 9.74244C21.927 9.64751 21.9714 9.53955 21.9901 9.42626C22.0087 9.31297 22.0011 9.19713 21.9677 9.08699C21.5156 7.62499 20.7095 6.28618 19.615 5.17982C19.5322 5.09703 19.4314 5.03276 19.3197 4.99159C19.2081 4.95042 19.0883 4.93335 18.969 4.94161L17.1073 5.05668L15.7178 4.28746L14.8908 2.68399C14.8387 2.58079 14.7634 2.48989 14.6704 2.41777C14.5774 2.34565 14.4689 2.29408 14.3527 2.26672C12.8062 1.91108 11.1939 1.91109 9.64734 2.26674C9.53117 2.29409 9.42272 2.34566 9.32971 2.41778C9.2367 2.4899 9.16142 2.58079 9.10923 2.68399L8.28232 4.28739L6.89275 5.05662Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
