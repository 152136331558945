import React from 'react'
import { IconProps } from './icons.types'

export const Pause: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 19C7.25 19.4142 7.58579 19.75 8 19.75C8.41421 19.75 8.75 19.4142 8.75 19L8.75 5C8.75 4.58579 8.41421 4.25 8 4.25C7.58579 4.25 7.25 4.58579 7.25 5V19ZM15.25 19C15.25 19.4142 15.5858 19.75 16 19.75C16.4142 19.75 16.75 19.4142 16.75 19L16.75 5C16.75 4.58579 16.4142 4.25 16 4.25C15.5858 4.25 15.25 4.58579 15.25 5L15.25 19Z"
      fill="currentColor"
    />
  </svg>
)
