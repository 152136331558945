import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React from 'react'
import { Modal } from '../../../components/modals/modal'
import { Icon } from '../../../components/icon'
import { Button } from '../../../components/button'
import { useStoreModals } from '../../../hooks/modals/use-store-modals'
import styles from './modal-get-app.module.scss'
import { useDesktopApp } from '../../../hooks/misc/use-desktop-app'

export const ModalGetApp: React.FC = () => {
  const { i18n } = useLingui()
  const { isDesktopApp } = useDesktopApp()
  const {
    modalGetApp: { show, onDismiss }
  } = useStoreModals()

  if (!show) {
    return null
  }

  return (
    <Modal
      targetId="shell-app"
      showCloseButton
      isOpen={show}
      onClose={onDismiss}
      shouldCloseOnOverlayClick
      modalClassName={styles.modal}
      label={i18n._(t`label.learnmore`)}
    >
      <p className={styles.title}>{i18n._(t`modal.getapp.title`)}</p>
      <div className={styles.content}>
        <div className={styles.list}>
          <p className={styles.itemList}>
            <Icon name="check" width={20} height={20} className={styles.icon} />
            {i18n._(t`modal.getapp.chords`)}
          </p>
          <p className={styles.itemList}>
            <Icon name="check" width={20} height={20} className={styles.icon} />
            {i18n._(t`modal.getapp.metronome`)}
          </p>
          <p className={styles.itemList}>
            <Icon name="check" width={20} height={20} className={styles.icon} />
            {i18n._(t`modal.getapp.key`)}
          </p>
          <p className={styles.itemList}>
            <Icon name="check" width={20} height={20} className={styles.icon} />
            {i18n._(t`modal.getapp.pitch`)}
          </p>
          <p className={styles.itemList}>
            <Icon name="check" width={20} height={20} className={styles.icon} />
            {i18n._(t`modal.getapp.count`)}
          </p>
          <p className={styles.itemList}>
            <Icon name="check" width={20} height={20} className={styles.icon} />
            {i18n._(t`modal.getapp.trim`)}
          </p>
        </div>
        <div className={styles.apps}>
          <a
            href="https://moises.app/webapp_library_popup_apple"
            target="_blank"
            rel="noreferrer"
            aria-label="Download Moises App on App Store"
          >
            <img
              alt=""
              width="148"
              height="44"
              src="//storage.googleapis.com/scala-web/assets/images/app-store.svg"
            />
          </a>
          <a
            href="https://moises.app/webapp_library_popup_android"
            target="_blank"
            rel="noreferrer"
            aria-label="Download Moises App on Google Play"
          >
            <img
              alt=""
              width="148"
              height="44"
              src="//storage.googleapis.com/scala-web/assets/images/app-google-play.svg"
            />
          </a>
          <div className={styles.qrCode}>
            <img
              width="96"
              height="96"
              alt="QrCode Moises App"
              src="//storage.googleapis.com/scala-web/assets/images/qr-code-moises-app.png"
            />
          </div>
        </div>
      </div>
      {!isDesktopApp && (
        <div className={styles.desktopApp}>
          <p
            className={styles.getDesktopApp}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: i18n._(t`download_desktop_app`).replace(
                /\*([^*]+)\*/g,
                `<span class="${styles.highlight}">
                  $1</span>`
              )
            }}
          />
          <Button
            small
            light
            radius={1}
            type="a"
            newTab
            href="https://desktop.moises.ai/"
            className={styles.dowloadDesktopApp}
            icon={<Icon name="download" />}
            title={i18n._(t`get_desktop_app`)}
          />
        </div>
      )}
    </Modal>
  )
}
