import React from 'react'
import { IconProps } from './icons.types'

export const Help: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7589 2.48364C6.65028 2.48364 2.50891 6.62501 2.50891 11.7336C2.50891 16.8423 6.65028 20.9836 11.7589 20.9836C16.8675 20.9836 21.0089 16.8423 21.0089 11.7336C21.0089 6.62501 16.8675 2.48364 11.7589 2.48364ZM1.00891 11.7336C1.00891 5.79658 5.82185 0.983643 11.7589 0.983643C17.696 0.983643 22.5089 5.79658 22.5089 11.7336C22.5089 17.6707 17.696 22.4836 11.7589 22.4836C5.82185 22.4836 1.00891 17.6707 1.00891 11.7336ZM12.0593 7.51156C11.5356 7.42173 10.997 7.52015 10.5389 7.78938C10.0808 8.05861 9.73277 8.48128 9.55644 8.98253C9.41898 9.37327 8.9908 9.5786 8.60006 9.44115C8.20931 9.30369 8.00398 8.87551 8.14144 8.48477C8.43532 7.64935 9.01538 6.9449 9.77888 6.49618C10.5424 6.04746 11.4401 5.88344 12.3129 6.03315C13.1858 6.18287 13.9775 6.63667 14.5478 7.31417C15.118 7.99151 15.4301 8.84874 15.4289 9.73411C15.4287 11.132 14.3902 12.0775 13.595 12.6077C13.1745 12.888 12.7594 13.095 12.4523 13.2315C12.2977 13.3002 12.1678 13.3522 12.0748 13.3876C12.0283 13.4054 11.9908 13.419 11.9639 13.4286L11.9316 13.4399L11.9217 13.4433L11.9183 13.4444L11.9171 13.4448L11.9166 13.445C11.9163 13.4451 11.9161 13.4452 11.6789 12.7336L11.9161 13.4452C11.5232 13.5761 11.0984 13.3638 10.9674 12.9708C10.8366 12.5782 11.0484 12.1539 11.4406 12.0225L11.4412 12.0223L11.4414 12.0223L11.4435 12.0215L11.4614 12.0153C11.4784 12.0092 11.5054 11.9994 11.5408 11.9859C11.6119 11.9588 11.7164 11.9171 11.8431 11.8608C12.0985 11.7473 12.4334 11.5793 12.7629 11.3596C13.4676 10.8898 13.9289 10.3355 13.9289 9.73365L13.9289 9.73253C13.9297 9.20117 13.7424 8.68668 13.4002 8.28018C13.0581 7.87367 12.583 7.60139 12.0593 7.51156ZM15.4289 9.73411V9.73365H14.6789L15.4289 9.73477L15.4289 9.73411ZM11.7589 15.9836C11.3447 15.9836 11.0089 16.3194 11.0089 16.7336C11.0089 17.1479 11.3447 17.4836 11.7589 17.4836H11.7689C12.1831 17.4836 12.5189 17.1479 12.5189 16.7336C12.5189 16.3194 12.1831 15.9836 11.7689 15.9836H11.7589Z"
      fill="currentColor"
    />
  </svg>
)
