import React from 'react'
import { IconProps } from './icons.types'

export const CountIn: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.3053 9.23438C6.3053 9.76074 6.59241 10.0752 7.07092 10.0752C7.55627 10.0752 7.83655 9.76758 7.83655 9.23438V1.59863C7.83655 1.01074 7.45374 0.607422 6.89319 0.607422C6.56506 0.607422 6.24377 0.730469 5.77893 1.05859L4.48694 1.98828C4.16565 2.20703 4.01526 2.43262 4.01526 2.67871C4.01526 3 4.26135 3.25293 4.58264 3.25293C4.75354 3.25293 4.9176 3.19141 5.1637 3.02051L6.26428 2.23438H6.3053V9.23438Z"
      fill="currentColor"
    />
    <path
      d="M14.4565 9.26172C14.4565 9.71289 14.8188 10 15.3725 10H19.9389C20.4037 10 20.6772 9.75391 20.6772 9.35742C20.6772 8.9541 20.3901 8.70801 19.9389 8.70801H16.4662V8.61914L18.7973 6.03516C19.9936 4.72949 20.3832 4.01172 20.3832 3.0957C20.3832 1.57129 19.1323 0.484375 17.3891 0.484375C15.5639 0.484375 14.3334 1.6875 14.3334 2.88379C14.3334 3.30762 14.6069 3.59473 15.0033 3.59473C15.3246 3.59473 15.5434 3.41016 15.7074 3.02051C15.9399 2.22754 16.5209 1.76953 17.3002 1.76953C18.2299 1.76953 18.852 2.35059 18.852 3.21875C18.852 3.82031 18.5512 4.36719 17.7924 5.20117L15.1059 8.18164C14.6137 8.70801 14.4565 8.96094 14.4565 9.26172Z"
      fill="currentColor"
    />
    <path
      d="M3.10608 21.0586C3.10608 22.1455 4.46643 23.1982 6.27112 23.1982C8.26038 23.1982 9.65491 22.0635 9.65491 20.4502C9.65491 19.2676 8.78674 18.2764 7.64514 18.1602V18.0645C8.5885 17.9141 9.35413 16.9639 9.35413 15.9453C9.35413 14.5029 8.07581 13.4844 6.27112 13.4844C4.53479 13.4844 3.28381 14.4961 3.28381 15.5762C3.28381 15.9727 3.55042 16.2461 3.93323 16.2461C4.23401 16.2461 4.43909 16.1025 4.61682 15.7402C4.93811 15.0908 5.49866 14.7285 6.23694 14.7285C7.18713 14.7285 7.83655 15.2959 7.83655 16.1367C7.83655 16.9707 7.17346 17.5723 6.25745 17.5723H5.54651C5.17053 17.5723 4.91077 17.8389 4.91077 18.1943C4.91077 18.5635 5.17737 18.8369 5.54651 18.8369H6.29846C7.39221 18.8369 8.12366 19.4658 8.12366 20.4023C8.12366 21.3457 7.40588 21.9473 6.29163 21.9473C5.45081 21.9473 4.79456 21.5781 4.41858 20.9082C4.19983 20.5459 4.00842 20.4092 3.73499 20.4092C3.36584 20.4092 3.10608 20.6826 3.10608 21.0586Z"
      fill="currentColor"
    />
    <path
      d="M18.5424 22.2822C18.5424 22.7812 18.8158 23.0752 19.2739 23.0752C19.7387 23.0752 20.0121 22.7812 20.0121 22.2822V21.1748H20.5795C21.0307 21.1748 21.2905 20.9355 21.2905 20.5322C21.2905 20.1289 21.0239 19.8828 20.5795 19.8828H20.0121V14.7148C20.0121 13.9766 19.5473 13.5459 18.7612 13.5459C18.1596 13.5459 17.7768 13.7988 17.2846 14.5234C15.9106 16.5605 15.2338 17.6816 14.3656 19.2744C14.1196 19.7529 14.0375 20.0127 14.0375 20.293C14.0375 20.8398 14.4272 21.1748 15.0492 21.1748H18.5424V22.2822ZM15.4799 19.8828V19.8213C16.2114 18.5225 17.4897 16.4033 18.4672 14.9404H18.5424V19.8828H15.4799Z"
      fill="currentColor"
    />
  </svg>
)
