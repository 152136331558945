import React from 'react'
import { IconProps } from './icons.types'

export const CheckboxCicle: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4997 7.50002L8.91634 12.5L6.83301 10.2273M18.4997 10C18.4997 14.6024 14.7687 18.3334 10.1663 18.3334C5.56397 18.3334 1.83301 14.6024 1.83301 10C1.83301 5.39765 5.56397 1.66669 10.1663 1.66669C14.7687 1.66669 18.4997 5.39765 18.4997 10Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
