import React from 'react'
import { IconProps } from './icons.types'

export const Download: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 14.25C21.4142 14.25 21.75 14.5858 21.75 15L21.75 19C21.75 19.7293 21.4603 20.4288 20.9445 20.9445C20.4288 21.4603 19.7293 21.75 19 21.75L5 21.75C4.27065 21.75 3.57118 21.4603 3.05546 20.9445C2.53973 20.4288 2.25 19.7293 2.25 19L2.25 15C2.25 14.5858 2.58579 14.25 3 14.25C3.41421 14.25 3.75 14.5858 3.75 15L3.75 19C3.75 19.3315 3.8817 19.6495 4.11612 19.8839C4.35054 20.1183 4.66848 20.25 5 20.25L19 20.25C19.3315 20.25 19.6495 20.1183 19.8839 19.8839C20.1183 19.6495 20.25 19.3315 20.25 19L20.25 15C20.25 14.5858 20.5858 14.25 21 14.25ZM17.5303 9.46967C17.8232 9.76256 17.8232 10.2374 17.5303 10.5303L12.5303 15.5303C12.3839 15.6768 12.1919 15.75 12 15.75C11.8983 15.75 11.8013 15.7298 11.7129 15.6931C11.6245 15.6565 11.5416 15.6022 11.4697 15.5303L6.46967 10.5303C6.17678 10.2374 6.17678 9.76256 6.46967 9.46967C6.76256 9.17678 7.23744 9.17678 7.53033 9.46967L11.25 13.1893L11.25 3C11.25 2.58579 11.5858 2.25 12 2.25C12.4142 2.25 12.75 2.58579 12.75 3L12.75 13.1893L16.4697 9.46967C16.7626 9.17678 17.2374 9.17678 17.5303 9.46967Z"
      fill="currentColor"
    />
  </svg>
)
