export const getCookies = (): {
  [key: string]: string
} =>
  document.cookie
    .split(';')
    .reduce(
      (ac, cv) =>
        Object.assign(ac, { [cv.split('=')[0].trim()]: cv.split('=')[1] }),
      {}
    )

export const setCookie = ({
  name,
  value,
  days
}: {
  name: string
  value: string
  days: number
}): void => {
  const date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  const expires = `expires=${date.toUTCString()}`

  document.cookie = `${name}=${value};${expires};path=/;SameSite=Lax;domain=moises.ai`
}

export const eraseCookie = (name: string): void => {
  const expires = `expires=${new Date(1970, 1, 1).toUTCString()}`
  document.cookie = `${name}=;${expires};path=/;SameSite=Lax;domain=moises.ai`
}
