import { useContext, useMemo } from 'react'
import { ContextShell } from '../../../modules/context'
import { Group } from '../../../types'

type UseDataManageGroup = {
  id: Group['id']
  group?: Group
  isGroupTrial: boolean
  isEnabledGroupPlan: boolean
  isEnabledManagement: boolean
}

export const useDataManageGroup = (): UseDataManageGroup => {
  const { user } = useContext(ContextShell)
  const isPremium = useMemo(() => !!user?.subscription?.isPremium, [user])
  const isFree = useMemo(() => user?.subscription?.plan === 'free', [user])

  const group = useMemo(() => {
    if (user?.subscription?.subscriptionType === 'individual') {
      return undefined
    }

    return user?.groups?.find((i: Group) => !i.isPending)
  }, [user?.groups, user?.subscription])

  const id = useMemo(() => group?.id, [group])
  const isOwner = useMemo(() => !!(group && group?.isOwner), [group])

  const isEnabledGroupPlan = useMemo(
    () => !!user?.featureFlags?.webGroupPlan,
    [user]
  )

  const isGroupTrial = useMemo(
    () => !!user?.subscription?.groupPlanFreeTrial,
    [user?.subscription]
  )

  const isIndividualSubscription = useMemo(
    () => user?.subscription?.subscriptionType === 'individual' && isPremium,
    [user, isPremium]
  )

  const isEnabledManagement = useMemo(
    () => isEnabledGroupPlan && (isOwner || isFree || isIndividualSubscription),
    [isEnabledGroupPlan, isIndividualSubscription, isOwner, isFree]
  )

  return {
    id,
    group,
    isGroupTrial,
    isEnabledManagement,
    isEnabledGroupPlan
  }
}
